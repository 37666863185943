import { App, ConfigProvider, Empty } from 'antd';
import locale from 'antd/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import React, {Suspense, useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './assets/screens.scss';
import './assets/style.scss';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthScreen from './screens/auth_scren';
import NotFondScreen from './screens/NotFondScreen';

import { QueryClient, QueryClientProvider } from 'react-query';
import { routeContragents } from './screens/contragents/routeContragents';
import { getWData } from './screens/table_extends/getWData';
import { getTableBody, TableMode } from './screens/table_extends/table_body/table_body';
import { getTableHeaders } from './screens/table_extends/table_header/table_headers';
import { Load } from './shared/Load';
import { checkTimeRefreshFull } from './shared/refreshToken';
import { sizes } from './shared/sizes';
import TableLoading from './shared/table_loading';
// import {ReactQueryDevtoolsPanel} from "react-query/devtools";
import './i18n/config.ts';
import FinController from './screens/FinController/FinController';


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './indexedb';
import {useDb} from "./actions/useDb";
import {removeValueStore} from "./shared/store";
import {useTranslation} from "react-i18next";
import PassportList from './screens/pass_list/PassportList';
import CanvasTable3 from './screens/canvas_table/CanvasTable3';
import ProfileScreen from './screens/profile_screen';
import NotificationsScreen from './screens/notifications_screen';
import NomenclaturesList from './screens/nomenclatures/NomenclaturesList';
import PricesList from './screens/prices/PricesList';
import {useUser} from "./actions/useUser";
import RegionsList from "./screens/regions/RegionsList";
import MeasureUnitList from "./screens/measure_unit/MeasureUnitList";
import DebugScreen from './screens/debug';

serviceWorkerRegistration.register({});

moment.locale('enUS', {
    week: { dow: 1 },
});
// Modal.error
const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Suspense fallback={<TableLoading baseLoading={true} animate={true} offsetTop={false} />}>
                <PassportList />
            </Suspense>
        ),
    },
    {
        path: '/passport/:id',
        element: (
            <Suspense fallback={<TableLoading baseLoading={true} animate={true} offsetTop={false} />}>
                <CanvasTable3 />
            </Suspense>
        ),
    },
    {
        path: '/auth',
        element: <AuthScreen />,
    },
    {
        path: '/profile',
        element: (
            <Suspense fallback={<Load />}>
                <ProfileScreen />
            </Suspense>
        ),
    },
    {
        path: '/notifications',
        element: (
            <Suspense fallback={<Load />}>
                <NotificationsScreen />
            </Suspense>
        ),
    },
    {
        path: '/fin-controller',
        element: (
            <Suspense fallback={<Load />}>
                <FinController />
            </Suspense>
        ),
    },
    {
        path: '/nomenclatures',
        element: (
            <Suspense fallback={<Load />}>
                <NomenclaturesList />
            </Suspense>
        ),
    },
    {
        path: '/regions',
        element: (
            <Suspense fallback={<Load />}>
                <RegionsList />
            </Suspense>
        ),
    },
    {
        path: '/measure_unit',
        element: (
            <Suspense fallback={<Load />}>
                <MeasureUnitList />
            </Suspense>
        ),
    },
    {
        path: '/prices',
        element: (
            <Suspense fallback={<Load />}>
                <PricesList />
            </Suspense>
        ),
    },
    ...routeContragents,
    {
        path: '/debug_1sdjfgGFGDGsdfs',
        element: <DebugScreen />,
    },
    {
        path: '*',
        element: <NotFondScreen />,
    },
]);
const queryClient = new QueryClient();

function getHeight() {
    return window.outerWidth < sizes.mobile ? '100vh' : window.innerHeight - 1 + 'px';
}

document.getElementById('root')!.classList.remove('loading');
// -5
document.documentElement.style.setProperty('--heightScreen', getHeight());
removeValueStore('onlyTable');

window.onload = () => {
    document.documentElement.style.setProperty('--heightScreen', getHeight());
};

window.onresize = () => {
    // -5
    document.documentElement.style.setProperty('--heightScreen', getHeight());

    const wData = getWData();
    if (wData && wData.grid) {
        wData.grid.layout = {
            header: getTableHeaders(),
            body: getTableBody(TableMode.VIEW),
        };
    }
};

setInterval(() => {
    const canvas = document.querySelector('.cheetah-grid canvas');
    if (canvas && window.innerWidth < sizes.mobile) {
        if (window.innerWidth - canvas.clientWidth > 50) {
            (canvas as HTMLElement).style.width = window.innerWidth + 'px';
            (canvas as HTMLElement).style.height = window.innerHeight - 20 + 'px';
        }
    }
}, 1000);

checkTimeRefreshFull();

// const [isOpen, setIsOpen] = React.useState(false);

const Root = () => {

    const {initDb} = useDb()
    const {t} = useTranslation();
    const {} = useUser();

    (window as any).t = t;

    useEffect(() => {
        initDb()
    }, [])

    return <RouterProvider router={router} />
}

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ConfigProvider
            locale={locale}
            renderEmpty={() => <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#004AD7',
                    borderRadius: 0,
                    fontFamily: 'ALS Wagon',
                },
            }}
        >
            <QueryClientProvider client={queryClient}>
                <App>
                    {/*<ReactQueryDevtoolsPanel setIsOpen={(val) => {}} handleDragStart={() => {}} />*/}
                    <Root />
                </App>
            </QueryClientProvider>
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
