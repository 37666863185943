

import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../shared/api_client";
import { isString } from "lodash";

interface props {

  ids: string[] | string | undefined,
  status: string | undefined,
  file_ids?: string[]
  reason?: string
}

export const useInvoicesStatus = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
) => {
  return useMutation(statusUpdate, {
    onSuccess: async (data: any) => { onSuccess(data); },
    onError: (data) => {
      onError(data);
    },
  })
  /*       onError: (data: any) => {},
      ),  */
}


const statusUpdate = async ({ ids: ids, status: status, file_ids: file_ids, reason }: props) => {
  if (typeof (ids) === 'string') ids = [ids]
  if (Array.isArray(ids) && ids.length > 0) {
    console.log('qewrqwerqwerqwer', ids, status, file_ids, status === 'approved_cpm' && Array.isArray(ids) && !file_ids)
    if (status === 'to_pay' && Array.isArray(ids) && !file_ids) return api.invoices.statusToPayCreate({ ids: [...ids] }).then((e) => e.data)
    if (status === 'rejected' && Array.isArray(ids) && !file_ids) return api.invoices.statusRejectCreate({ ids: [...ids], reason: reason }).then((e) => e.data)

    if (status === 'paid' && Array.isArray(ids) && file_ids) return api.invoices.statusPaidCreate({ id: ids[0], file_ids: file_ids }).then((e) => e.data)
  }

}

