import { ListGrid } from 'cheetah-grid';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { getOffsetX } from '../../../shared/getOffsetX';
import { getWData } from '../getWData';

export function checkPinnedTitle(
  event: MouseEvent,
  col: number,
  row: number,
  grid: ListGrid<TableRecord>,
) {
  if (col == 1 && row == 0) {
    // let left = getOffsetX(event) - getWData().grid.getCellRect(col, row).left + grid.scrollLeft;
    return true;
  }

  return false;
}
