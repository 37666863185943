import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Input, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegionList } from '../../actions/useRegionList';
import { directoryApi } from '../../shared/api_client';
import LayoutComponent from '../../shared/layout_component';
import { ColumnsType } from 'antd/es/table';
import { delay } from '../../shared/delay';
import { regionsListColumns } from './RegionsListColumns';
import tokenActions, { AccessItem } from "../../actions/tokenActions";
import { doCreateRegion } from "./RegionsListHelpers";
import { getHeaderComponentHeight } from '../../shared/header_component';

const { confirm } = Modal;


const RegionsList: FunctionComponent<{}> = ( props ) => {
    const { regions, regionsLoading, regionsError, regionClearCache } = useRegionList();
    const { t } = useTranslation();
    const [ isOpenPopover, setIsOpenPopover ] = useState<{ id: string }>( { id: '' } );
    const [ processSave, setProcessSave ] = useState<{ id: string }[]>( [] );

    const [ localValue, setLocalValue ] = useState<{ id: string, title: string, key: string }[]>( [] );

    useEffect( () => {
        setLocalValue( regions.map( ( item, index ) => ({
                key: `${ index + 1 }`,
                id: `${ item.id }`,
                title: `${ item.title }`,
            }) ),
        );
    }, [ regions ] );

    useEffect( () => {
        function onClick( event: MouseEvent ) {
            const target = event.target as HTMLElement;
            if ( target.closest( '.direction-cell-dots__dots' ) == null ) {
                setIsOpenPopover( { id: '' } );
            }
        }

        window.addEventListener( 'click', onClick );
        return () => window.removeEventListener( 'click', onClick );
    }, [] );

    (window as any).regionsList = {
        localValue,
        processSave,
        isOpenPopover,
        regions,
    };

    function handleEditRegion( id: string ) {
        if ( tokenActions.directoryRegions == AccessItem.EDIT || tokenActions.directoryRegions == AccessItem.FULL ) {
            const region = regions.find( r => r.id == id );
            let title = region?.title ?? ''

            const modal = Modal.confirm( {
                className: 'modal_direction',
                title: 'Редактирование региона',
                icon: null,
                content: <div>
                    <Input
                        placeholder={ 'Название региона' }
                        autoFocus
                        defaultValue={ region?.title }
                        onChange={ e => {
                            title = e.target.value
                        } }
                    />
                </div>,
                footer: <div className={ 'modal_btns' }>
                    <Button type={ 'link' } onClick={ () => {
                        modal.destroy();
                    } }>Отмена</Button>
                    <Button type={ 'primary' } size={ 'small' } onClick={ async () => {
                        await directoryApi.regions.regionsUpdate( id, { title } );
                        await delay( 100 );
                        regionClearCache();
                        modal.destroy();
                    } }>Ок</Button>
                </div>,
            } );
        }
    }

    const columns: ColumnsType<{ id: string, key: string, title: string }> = regionsListColumns( {
        regions,
        regionClearCache,
        onEdit: handleEditRegion,
    } );
    return <>
        <LayoutComponent
            title={ 'Справочник регионов' }
            className="RegionsList DirectionList"
            onAction={ ( action ) => {
                if ( action == 'create_region' ) {
                    doCreateRegion(regionClearCache);
                }
            } }
        >
            <div className="Regions__wrapper Direction__wrapper">
                <Table
                    className={ 'regions-table direction-table' }
                    rowKey="key"
                    columns={ columns }
                    dataSource={ localValue }
                    pagination={ false }
                    onRow={ ( record ) => ({
                        onClick: () => {
                            const id: string = record.id;
                            handleEditRegion( id )
                        },
                    }) }
                    scroll={{ y: window.innerHeight - getHeaderComponentHeight() - 60 }}
                />

            </div>
        </LayoutComponent>

    </>;
};

export default RegionsList;
