import {FunctionComponent, KeyboardEvent, useEffect, useMemo, useRef, useState} from 'react';
import { getWData } from '../table_extends/getWData';
import { Button, ConfigProvider, message, Modal, Select } from 'antd';
import { MyDatePicker, stopEvent } from '../../shared/stopEvent';
import moment from 'moment';
import { getTableColumnKey } from '../table_extends/getTableColumnKey';
import { getHeaderComponentHeight } from '../../shared/header_component';
import { sizes } from '../../shared/sizes';
import locale from 'antd/locale/ru_RU';
import { closeInput } from './actions/closeInput';
import { api, apiAuth } from '../../shared/api_client';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { uniqBy } from 'lodash';
import {checkUpdBpPrice, checkUpdBpSize} from "../table_extends/checkUpdBp";
import {TableRecord} from "./components/TableRecord";
import {getTableHeaders} from "../table_extends/table_header/table_headers";
import {getTableBody, TableMode} from "../table_extends/table_body/table_body";
import dateFormat, {DateFormat, parseDate} from "../../shared/dateFormat";
import enUS from 'antd/es/calendar/locale/en_US';
import {getValueStore} from "../../shared/store";
import allowEditCell from "../table_extends/allowEditCell";


const SuperEditor: FunctionComponent<{
    text: string;
    updateText: (text: string) => void;
    close: (value: string, forceSave: boolean) => void;
    nextCell: (value: string, direction?: 'up' | 'down' | 'left' | 'right') => void;
    col: number;
    row: number;
}> = (props) => {
    const isEditDate = ['cns_contact_date', 'cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date_start', 'cns_contact_date_end'].includes(getTableColumnKey(props.col, getWData().body));
    const isEditSelect = [''].includes(getTableColumnKey(props.col, getWData().body));
    const isEditSelectMany = ['cns_contractors', 'cns_responsibles'].includes(getTableColumnKey(props.col, getWData().body));
    const columnKey = getTableColumnKey(props.col, getWData().body);
    const record = getWData().resultRows[(getWData().cellOpen?.row ?? 1) - 3];
    const isOnlyView = columnKey == 'cns_title' ? false : !allowEditCell( record, columnKey );

    const refInput = useRef<any>();
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<DefaultOptionType[]>([]);
    const userSearch = useRef('');
    const [isLoading, setIsLoading] = useState(false);


    const [value, setValue] = useState<string>(props.text);
    const [values, setValues] = useState<string[]>([]);

    const isNumbers = ['cns_budget_mark_sum_wat', 'cns_budget_plan_size', 'cns_budget_plan_price'].includes(columnKey);
    const isInt = ['cns_budget_mark_sum_wat'].includes(columnKey); // 'cns_budget_plan_size', 'cns_budget_plan_price'
    const isText = ['cns_title'].includes(columnKey);

    async function loadUsers() {
        console.log('loadUsers')
        const selectedIds = record.cns_responsibles.map(e => e.id);
        setSelectedOptions( record.cns_responsibles.map(e => ({ value: e.id, label: e.full_name })));

        setIsLoading(true);
        const res = await apiAuth.helpers.usersList({ search: userSearch.current });
        const options2 = res.data.data?.map(e => ({ label: e.text, value: e.value, disabled: e.disabled })) ?? [];

        setOptions(options2.filter( e => !selectedIds.includes(e.value ?? '')));

        setIsLoading(false);
    }

    async function loadContragents() {
        const selectedIds = record.cns_contractors.map(e => e.value);
        setSelectedOptions(record.cns_contractors);
        if (options.length == 0) {
            // TODO получение актуальных значений
            // setOptions([{value: record.cns_contragent_id, label: record.cns_contragent_name}])
            setOptions([{ value: '', label: `Количество подрядчиков` }]);
        }
        setIsLoading(true);
        const res = await api.helpers.contractorsList({ search: userSearch.current, skip_low_rating: 1 });
        const options2 = res.data.data?.map(e => ({
            label: e.text,
            value: e.value,
            disabled: false,
            rating: e.rating
        })) ?? [];
        // if (!options2.find(e => e.value == record.cns_contragent_id) && record.cns_contragent_id) {
        //     options2.unshift({value: record.cns_contragent_id, label: record.cns_contragent_name, disabled: true})
        // }
        setOptions(options2.filter(e => !selectedIds.includes(e.value ?? '')));
        setIsLoading(false);
    }


    useEffect(() => {
        console.log('SuperEditor', columnKey, isText);
        const superEditor = document.querySelector('.SuperEditor') as HTMLElement;

        const cellRect = getWData().grid.getCellRect(props.col, props.row);

        const headerHeight = getHeaderComponentHeight() + (window.innerWidth > sizes.mobile ? 0 : getValueStore('onlyTable') == '1' ? 0 : 90) + 140;

        let top = cellRect.top - getWData().grid.scrollTop + headerHeight + Number(getValueStore('input_space_top') ?? '0');
        let left = cellRect.left - getWData().grid.scrollLeft + 17 - (window.innerWidth > sizes.mobile ? 0 : 18);
        const height = cellRect.height;
        let width = cellRect.width + 2;

        if (props.col === 1 && record?.cns_row_type == 'nomenclature') {
            left += 50 + (20 * (record?.cns_level - 3)) + 20;
            width += (getWData().hasAllowMoveOfApprove ? 25 : -5) - (20 * (record?.cns_level - 3)) - 20;
            if (window.innerWidth < 500) {
                left = 40;
            }
        }

        if (props.col === 1 && record?.cns_row_type == 'work_type') {
            left += 10 + (20 * (record?.cns_level));
            width += 35 + (-20 * (record?.cns_level)) + (getWData().hasAllowMoveOfApprove ? 30 : 0);
        }

        if (props.col === 1 && record?.cns_row_type == 'section') {
            left += 30;
            width += 15;
        }

        if (props.col === 1 && window.innerWidth < 500) {
            getWData().grid.scrollLeft = 80;
            width = window.innerWidth - 60;

            if (record?.cns_row_type == 'section') {
                left = 30;
            }
        }

        if (window.innerWidth < 500) {
            // top += window.scrollY;
        }

        if (superEditor) {
            superEditor.style.display = 'block';
            superEditor.style.position = 'absolute';
            superEditor.style.top = top + 'px';
            superEditor.style.left = left + 'px';
            superEditor.style.width = width + 'px';
            superEditor.style.height = height + 'px';
        }

        setTimeout(() => {
            (document.querySelector('#SuperEdit') as any)?.focus();
            if (isEditDate) {
                refInput?.current?.focus();
                (document.querySelector('#SuperEdit') as any)?.click();
            }
        }, 200);

        setValue(props.text);

        if (isEditSelect) {
        }
        if (isEditSelectMany) {
            if (getTableColumnKey(props.col, getWData().body) == 'cns_contractors') {
                setValues(record.cns_contractors.map(e => `${e.value}`));
                loadContragents();
            }
            if (getTableColumnKey(props.col, getWData().body) == 'cns_responsibles') {
                setValues(record.cns_responsibles.map(e => `${e.id}`));
                loadUsers();
            }
        }
    }, [props.col, record?.cns_id ?? '']);

    function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Escape') {
            props.close(value, false);
            stopEvent(event as any);
            return false;
        }

        if (event.key === 'Enter') {
            props.nextCell(value, 'down');
            stopEvent(event as any);
            return false;
        }

        if (event.key === 'Tab' && event.shiftKey) {
            props.nextCell(value, 'left');
            stopEvent(event as any);
            return false;
        }
        if (event.key === 'Tab' && !event.shiftKey) {
            props.nextCell(value, 'right');
            stopEvent(event as any);
            return false;
        }
        if (event.key === 'ArrowRight' && (document.querySelector('#SuperEdit') as any).selectionStart === `${value}`.length) {
            props.nextCell(value, 'right');
            stopEvent(event as any);
            return false;
        }
        if (event.key === 'ArrowLeft' && (document.querySelector('#SuperEdit') as any).selectionStart === 0) {
            props.nextCell(value, 'left');
            stopEvent(event as any);
            return false;
        }
        if (event.key === 'ArrowUp') {
            props.nextCell(value, 'up');
            stopEvent(event as any);
            return false;
        }
        if (event.key === 'ArrowDown') {
            props.nextCell(value, 'down');
            stopEvent(event as any);
            return false;
        }

    }

    function onSelectValue(val: string) {
        setValue(val);
        props.updateText(val);
    }

    function onSelectValueMany(val: string[]) {
        console.log('onSelectValueMany')
        if (!getWData().cellOpen?.openEdit) {
            return;
        }
        const itemsSelected = [...selectedOptions, ...options].filter((item) => val.includes(`${item.value}`));

        if (itemsSelected.length && getTableColumnKey(props.col, getWData().body) == 'cns_contractors') {
            const record2 = getWData().rows3.find(e => e.cns_id == record?.cns_id);
            if (record2) {
                record2.cns_contractors = itemsSelected.map( e => ({ value: `${e.value}`, label: `${e.label}` }) );
            }

            loadContragents();
        }

        if (itemsSelected.length && getTableColumnKey(props.col, getWData().body) == 'cns_responsibles') {
            const record2 = getWData().rows3.find(e => e.cns_id == record?.cns_id);
            if (record2) {
                record2.cns_responsibles = itemsSelected.map( e => ({ id: `${e.value}`, full_name: `${e.label}` }) );
            }
            loadUsers();
        }

        setValues(val);
        const op2 = options.filter(e => !val.includes(`${e.value}`));
        setOptions(op2);
        setSelectedOptions(uniqBy(itemsSelected, 'value'));
        props.updateText(JSON.stringify(itemsSelected));

        userSearch.current = '';
    }

    const validateError = useMemo(() => {
        if (columnKey == 'cns_budget_plan_size') {
            return !checkUpdBpSize( record.cns_nomenclature_id ?? '', Number.parseFloat(value) )
        }

        if (columnKey == 'cns_budget_plan_price') {
            return !checkUpdBpPrice( record.cns_nomenclature_id ?? '', Number.parseFloat(value) )
        }

        return false;
    }, [ value, record ]);

    function checkOnUpdateMany(val: string[]) {
        if (columnKey == 'cns_contractors') {
            const removedOption = selectedOptions.find(e => !val.includes(`${e.value}`));

            if (removedOption) {
                if (record.cns_contracts.find(contract => contract.contragent_id == removedOption.value)) {
                    message.info(`Нельзя удалить поставщика, пока у него есть контракты.`);
                    return false;
                }
            }
        }

        return true;
    }

    (window as  any).SuperEditor = {
        options, columnKey, isText, isEditDate, isEditSelect, isEditSelectMany, validateError,
        userSearch, record, isLoading, selectedOptions,
    };

    return (
        <div className={`SuperEditor ${validateError ? 'SuperEditor__error' : ''}`}>
            {(isEditSelect) && <>
                <Select
                    open={true}
                    showSearch
                    ref={refInput}
                    id="SuperEdit"
                    className={`SuperEditInput select`}
                    value={value}
                    loading={isLoading}
                    options={options.map(e => ({...e, disabled: isOnlyView ? true : e.disabled}))}
                    filterOption={_ => true}
                    onSelect={onSelectValue}
                    onSearch={val => {
                        userSearch.current = val;
                    }}
                />
            </>}
            {(isEditSelectMany) && <>
                <Select
                    open={true}
                    mode={'multiple'}
                    showSearch
                    ref={refInput}
                    id="SuperEdit"
                    className={`SuperEditInput multiSelect`}
                    value={values}
                    loading={isLoading}
                    options={[...selectedOptions, ...isOnlyView ? [] : options].map(e => ({...e, disabled: isOnlyView ? true : e.disabled}))}
                    filterOption={_ => true}
                    onChange={(val) => {
                        if (checkOnUpdateMany(val)) {
                            onSelectValueMany(val);
                        }
                    }}
                    onInputKeyDown={ (event) => {
                        if (event.code == 'Enter') {
                            console.log({selectedOptions})
                            props.close(JSON.stringify(selectedOptions), true);
                        }
                    }}
                    onSearch={val => {
                        userSearch.current = val;
                        if (columnKey == 'cns_contractors') {
                            loadContragents();
                        }
                        if (columnKey == 'cns_responsibles') {
                            loadUsers();
                        }
                    }}
                    optionRender={ columnKey == 'cns_contractors' ? item => {
                        if ((item.data as any).rating == 3) {
                            return <span className={'green'}>{item.label}</span>;
                        }
                        if ((item.data as any).rating == 2) {
                            return <span className={'yellow'}>{item.label}</span>;
                        }
                        if ((item.data as any).rating == 0) {
                            return <span>{item.label}</span>;
                        }
                        return <>{item.label}</>;
                    } : undefined}
                    maxTagCount={values.length > 1 ? 0 : 1}
                    maxTagPlaceholder={_ => <>Всего: {values.length}</>}
                />
            </>}
            {!isEditDate && !isEditSelect && !isEditSelectMany && (
                <input
                    disabled={isOnlyView}
                    ref={refInput}
                    id="SuperEdit"
                    className={`SuperEditInput ${(isInt || isNumbers) ? 'number' : ''}`}
                    value={value}
                    onChange={(e) => {
                        let val: any = e.target.value || '';
                        const delimeter = val.includes(',') ? ',' : '.';
                        // Для текста не заменям
                        if (!isText) val = val.replace(',', '.');

                        let valRes = val;
                        console.log('text___!', { val });

                        if (isInt) {
                            valRes = `${Number.parseInt(val)}`;
                            if (valRes == 'NaN') {
                                valRes = '';
                            }
                            setValue(valRes);
                            props.updateText(valRes);
                        } else if (isNumbers && val == '.') {
                            setValue(`0${delimeter}`);
                            props.updateText(`0${delimeter}`);
                        } else if (isNumbers && val !== '') {
                            valRes = `${Number.parseFloat(val)}`.replace('.', delimeter);
                            const val2 = e.target.value.replace(',', '.');

                            if (val2[val2.length - 1] === '.') {
                                valRes = val.replace('.', '') + delimeter;
                                setValue(valRes.replace(delimeter, '.'));
                                props.updateText(valRes.replace(delimeter, '.'));
                            }

                            if (!Number.isNaN(val)) {
                                setValue(valRes.replace(delimeter, '.'));
                                props.updateText(valRes.replace(delimeter, '.'));
                            }

                        } else {
                            if (isText) {
                                setValue(valRes);
                                props.updateText(valRes);
                            } else {
                                setValue(valRes.replace(delimeter, '.'));
                                props.updateText(valRes.replace(delimeter, '.'));
                            }

                        }
                    }}
                    onKeyDown={onKeyDown}
                />
            )}
            {isEditDate && (
                <ConfigProvider
                    locale={{
                        ...locale,
                        DatePicker: {
                            ...locale.DatePicker,
                            ok: 'ddd' as any,
                        } as any,
                    }}
                >
                    <MyDatePicker
                        showToday={false}
                        locale={enUS}
                        id="SuperEdit"
                        className="SuperEdit SuperEditDate SuperEditInput"
                        autoFocus
                        allowClear={false}
                        suffixIcon={<></>}
                        ref={refInput}
                        format={DateFormat}
                        onKeyDown={onKeyDown as any}
                        value={value ? moment(value, 'YYYY-MM-DD') : moment()}
                        needConfirm
                        onOk={() => {
                            console.log('onOk', { value });
                            const d = (document.getElementById('SuperEdit') as any)!.value as any;
                            if (d) {
                                const d2 = parseDate(d);
                                const d3  =dateFormat(parseDate(d2), {format: 'YYYY-MM-DD', placeholder: ''});
                                props.close(d3, true);
                            } else {
                                props.close('', true);
                            }
                        }}
                        onChange={(e) => {
                            setValue(e?.format('YYYY-MM-DD') ?? '');
                            props.updateText(e?.format('YYYY-MM-DD') ?? '');
                            setTimeout(
                                () => props.close(e?.format('YYYY-MM-DD') ?? '', false),
                                100,
                            );
                        }}
                    />
                </ConfigProvider>
            )}
        </div>
    );
};

export default SuperEditor;

export const SuperEditorBind: FunctionComponent = () => {
    return <SuperEditor
        key={`${getWData().cellOpen?.col}-${getWData().cellOpen?.row}`}
        col={getWData().cellOpen!.col}
        row={getWData().cellOpen!.row}
        text={getWData().textInput ?? ''}
        updateText={text => {
            getWData().textInput = text;
            getWData().setRefresh(Math.random());
        }}
        close={async (value, forceSave) => {
            closeInput(
                getWData().cellOpen?.col ?? -1,
                getWData()?.cellOpen?.row ?? -1,
                undefined,
                undefined,
                { forceSave: false },
            );

            function save(params?: { single?: boolean }) {
                if (getWData().cellOpen?.record.cns_level != 3 && !value) {
                    closeInput(
                        getWData().cellOpen?.col ?? -1,
                        getWData().cellOpen?.row ?? -1,
                        undefined,
                        undefined,
                        { forceSave, singleDate: params?.single },
                    );
                } else {
                    closeInput(
                        getWData().cellOpen?.col ?? -1,
                        getWData().cellOpen?.row ?? -1,
                        value,
                        undefined,
                        { forceSave, singleDate: params?.single },
                    );
                }
            }

            const columnKey: keyof TableRecord = getWData().cellOpen?.columnKey ?? '' as any;

            if (['cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date', 'cns_contact_date_start', 'cns_contact_date_end'].includes(columnKey)) {
                if (forceSave) {
                    const level = (getWData().cellOpen?.record?.cns_level ?? 0);
                    if (level > -1) {
                        const parent = getWData().cellOpen?.record.cns_id;
                        const hasChildrens1 = parent ? getWData().resultRows.filter(e => e.cns_parent_id == getWData().cellOpen?.record?.cns_id) : getWData().resultRows.filter(e => e.cns_level > 0);
                        const hasChildrens2 = hasChildrens1.find(e => columnKey === 'cns_plane_date_start' ? e.cns_plane_date_start : e.cns_plane_date_end);

                        if (hasChildrens2) {
                            Modal.confirm({
                                width: '500px',
                                className: 'modal_confirm_dates',
                                title: 'Сохранить изменения?',
                                icon: <></>,
                                content: <div>Выберите вариант сохранения:<br /> - «Да, все» - будут пересчитаны
                                    все даты нижних уровней.<br /> - «Да, только эту» - будет изменена только
                                    одна
                                    дата, без пересчета дат на нижних уровнях.</div>,
                                footer: <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingTop: '20px',
                                    gridGap: '1rem',
                                }}>
                                    <Button size={'small'} data-test={'cancel_date'} onClick={() => {
                                        closeInput(getWData().cellOpen?.col ?? -1, getWData().cellOpen?.row ?? -1);

                                        Modal.destroyAll();
                                    }} style={{ marginRight: 'auto' }}>Отмена</Button>
                                    <Button size={'small'} data-test={'all_date'} onClick={() => {
                                        save({ single: false });
                                        getWData().setRefresh(Math.random());

                                        Modal.destroyAll();
                                    }}>Да, все</Button>
                                    <Button size={'small'} type={'primary'} data-test={'single_date'} onClick={() => {
                                        save({ single: true });
                                        getWData().setRefresh(Math.random());

                                        Modal.destroyAll();
                                    }}>Да, только эту</Button>

                                </div>,
                            });
                        } else {
                            save();
                        }
                    }
                }
            } else {
                save();
            }

            setTimeout(() => {
                getWData().grid.layout = {
                    header: getTableHeaders(),
                    body: getTableBody(TableMode.VIEW),
                };
                getWData().setRefresh(Math.random());
            },100)
        }}
        nextCell={(value, direction) => {
            closeInput(
                getWData().cellOpen?.col ?? -1,
                getWData().cellOpen?.row ?? -1,
                value,
                direction,
            );
            getWData().grid.layout = {
                header: getTableHeaders(),
                body: getTableBody(TableMode.VIEW),
            };
            getWData().setRefresh(Math.random());
        }}
    />;
};
