import { useQuery, useQueryClient } from 'react-query';
import {directoryApi} from '../shared/api_client';
import {NomenclaturesTableRow} from '../ApiDirectory';
import tokenActions from "./tokenActions";

export function useNomenclaturesTable() {
  const {
    data: nomenclatures,
    error: nomenclaturesError,
    isLoading: nomenclaturesLoading,
  } = useQuery({
    queryKey: [`nomenclatures`],
    queryFn: async () => {
      if (tokenActions.directoryNomenclatures == 'NONE') {
        return {
          plain: { data: [] },
          rows: [],
        };
      }

      const res = await directoryApi.workSections.tableList();

      const res2: NomenclaturesTableRow[] = [];

      for (const line of res.data.body as any[]) {
        const lineRes: NomenclaturesTableRow = {};

        for (const columnIndex in res.data.headers) {
          const columnKey = res.data.headers[columnIndex as any] as string;
          switch (columnKey) {
            case 'section_id':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'type_id':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'nomenclature_id':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'row_type':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'level':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'nomenclature_type':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'row_number':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'title':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'unit':
              lineRes[columnKey] = line[columnIndex];
              break;
            case 'unit_id':
              lineRes[columnKey] = line[columnIndex];
              break;
          }
        }

        res2.push(lineRes);
      }

      const resSections = res2.filter(e => e.row_type == 'section');
      const resWorkTypes = res2.filter(e => e.row_type == 'work_type');
      const resNomenclatures = res2.filter(e => e.row_type == 'nomenclature');

      const res3: NomenclaturesTableRow[] = [];

      for (const section of resSections) {
        res3.push(section);

        for (const workType of resWorkTypes) {
          if (workType.section_id == section.section_id) {
            res3.push(workType);

            for (const nomenclature of resNomenclatures) {
              if (nomenclature.section_id == section.section_id && nomenclature.type_id == workType.type_id) {
                res3.push(nomenclature);
              }
            }
          }
        }
      }

      return {
        plain: res,
        rows: [...res3],
      }
    },
  });

  const queryClient = useQueryClient();

  const clearCache = () => {
    queryClient.invalidateQueries(['nomenclatures']);
  };


  return {
    nomenclatures,
    nomenclaturesError,
    nomenclaturesLoading,
    nomenclaturesClearCache: clearCache,
  };
}
