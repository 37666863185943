import { getEmptyTableRecord, RecordStatus, TableRecord } from '../screens/canvas_table/components/TableRecord';

export interface IRowAdded {
  project_id: string;
  section_id: string;
  type_id: string;
  nomenclature_id: string;
  parent_id: string;
  row_type: 'nomenclature';
  level: 3 | 2 | 1 | 0;
  nomenclature_type: 1 | 2;
  row_number: string;
  title: null | string;
  unit: null | string;
  bp_is_approved: boolean;
  bp_volume: null | number;
  guideline_amount: null | number;
  plan_budget: (any | number)[];
  fact_budget: (null | number)[];
  contract_data: [
    null | number | string,
    null | number | string,
    null | number | string,
    null | number | string,
    (null | number | string)[],
  ];
  members: any;
}

export function mapRowAdded2TableRecord(item: IRowAdded): TableRecord {
  const line: TableRecord = {
    ...getEmptyTableRecord(),
    cns_row_type: item.row_type,
    cns_id:
      item.section_id +
      (item.type_id ? `_${item.type_id}` : '') +
      (item.nomenclature_id ? `_${item.nomenclature_id}` : ''),
    cns_section_id: item.section_id,
    cns_group_id: item.type_id,
    cns_nomenclature_id: item.nomenclature_id,
    cns_parent_id: item.parent_id,
    cns_parent_id2: item.parent_id,
    cns_level: item.level,
    cns_number: item.row_number,
    cns_title: item.title,
    cns_ed_izm: item.unit ?? '',
    cns_has_sended_approve: !item.bp_is_approved,
    cns_allow_send_approve: !item.bp_is_approved,
    cns_status: item.bp_is_approved ? RecordStatus.BUDGET_PLAN_APPROVED : RecordStatus.BUDGET_ORIENTED_APPROVED,
    cns_budget_plan_size:
      item.plan_budget !== undefined ? (item.plan_budget[0] != null ? item.plan_budget[0] : '') : '',
    cns_budget_plan_price:
      item.plan_budget !== undefined ? (item.plan_budget[1] != null ? item.plan_budget[1] : '') : '',
    cns_budget_plan_sum_wat: item.plan_budget !== undefined ? item.plan_budget[2] : '',
    cns_budget_plan_far: item.plan_budget !== undefined ? item.plan_budget[3] : '',
    cns_budget_plan_far2: item.plan_budget !== undefined ? item.plan_budget[4] : '',
    // cns_status: item.plan_budget[5],
    cns_budget_fakt_size: item.fact_budget !== undefined ? `${item.fact_budget[0] ?? ''}` : '',
    cns_budget_fakt_price: item.fact_budget !== undefined ? `${item.fact_budget[1] ?? ''}` : '',
    cns_budget_fakt_sum_wat: item.fact_budget !== undefined ? `${item.fact_budget[2] ?? ''}` : '',
    cns_budget_fakt_far: item.fact_budget !== undefined ? `${item.fact_budget[3] ?? ''}` : '',
    cns_budget_fakt_last_change: item.fact_budget !== undefined ? `${item.fact_budget[4] ?? ''}` : '',
    cns_contact_date: item.contract_data !== undefined ? (item.contract_data[1] as any) : '',
    // cns_contact_contragent: item.contract_data[4]?.map(line => {
    //     return {
    //         ...getCon
    //     }
    // }),
  };

  switch (line.cns_row_type) {
    case 'section':
      line.cns_id = line.cns_section_id ?? '';
      break;
    case 'work_type':
      line.cns_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}_${line.cns_parent_id}`;
      line.cns_parent_id = `${line.cns_section_id}_${line.cns_parent_id}`;
      break;
    case 'nomenclature':
      line.cns_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}_${line.cns_nomenclature_id ?? ''}`;
      line.cns_parent_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}`;
      break;
    default:
      break;
  }

  return line;
}
