import { useQuery } from 'react-query';
import { api } from '../shared/api_client';
import tokenActions, { AccessItem } from './tokenActions';

export function useContractors(search = '') {
  const {
    data: contracts,
    error: contractsError,
    isLoading: contractsLoading,
  } = useQuery({
    queryKey: ['contractors', `contractors_${search}`],
    queryFn: async () => {
      if (tokenActions.contragents == AccessItem.NONE) return Promise.resolve({
        data: [],
        meta: { total: 0, current_page: 1, per_page: 10, last_page: 1 },
      });

      return await api.helpers.contractorsList({ search }).then((e) => e.data ?? []);
    },
  });

  return {
    contracts,
    contractsError,
    contractsLoading,
  };
}
