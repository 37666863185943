import {getWData} from "../../screens/table_extends/getWData";
import {TableRecordContract} from "../../screens/canvas_table/components/TableRecord";

export function modalFilterContracts(nomenclatureIds: string[], contractor_id?: string, filteredIds: string[] = []) {
  const listByContragents: {[x:string]: TableRecordContract} = {  };
  const listByNomenclature: {[x: string]: TableRecordContract} = {  };

  const nomenclatures = nomenclatureIds.length ? getWData().rows3.filter(e => nomenclatureIds.includes(e.cns_nomenclature_id ?? '')) : getWData().rows3;

  for (let item of nomenclatures) {
    let contracts = item.cns_contracts.filter(e => filteredIds.includes( e.contragent_id) && !e.id.includes('.') && e.status === 'signed');
    let contractsByNomenclature = [...contracts];

    if (contractor_id) {
      contracts = item.cns_contracts.filter(e => e.contragent_id == contractor_id && !e.id.includes('.') && e.status === 'signed');
    }

    for (let e of contracts) {
      listByContragents[e.document_id ?? ''] = e;
    }

    for (let e of contractsByNomenclature) {
      listByNomenclature[e.document_id ?? ''] = e;
    }
  }

  // удалить те, которых нет в любой строке
  for (let row of nomenclatures) {
    for (let contract of Object.values(listByContragents)) {
      if (!row.cns_contracts.find(e => e.document_id == contract.document_id ?? '')) {
        delete listByContragents[contract.document_id ?? ''];
      }
    }
  }

  return {
    listByContragents,
    listByNomenclature,
  }
}

export function modalFilterContracts2(nomenclatureIds: string[], contractor_id?: string) {
  // console.log(`modalFilterContracts2`, nomenclatureIds, contractor_id);
  const listByContragents: {[x:string]: TableRecordContract} = {  };

  const nomenclatures = nomenclatureIds.length ? getWData().rows3.filter(e => nomenclatureIds.includes(e.cns_nomenclature_id ?? '')) : getWData().rows3;

  for (let item of nomenclatures) {
    let contracts = item.cns_contracts.filter(e => e.contragent_id == contractor_id && !e.id?.includes('.') && e.status === 'signed');

    if (contractor_id) {
      contracts = item.cns_contracts.filter(e => e.contragent_id == contractor_id && e.status === 'signed');
    }

    for (let e of contracts) {
      if (!e.id?.includes('.')) {
        listByContragents[e.document_id ?? ''] = e;
      }
    }
  }

  // удалить те, которых нет в любой строке
  for (let row of nomenclatures) {
    for (let contract of Object.values(listByContragents)) {
      if (!row.cns_contracts.find(e => e.document_id == contract.document_id ?? '')) {
        delete listByContragents[contract.document_id ?? ''];
      }
    }
  }

  return {
    listByContragents,
  }
}
