import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { FunctionComponent, useState } from 'react';
import tokenActions, { AccessItem } from '../../../../actions/tokenActions';
import { getHeaderComponentHeight } from '../../../../shared/header_component';
import { getExcludesColumns, getWData } from '../../../table_extends/getWData';
import { TableMode, getTableBody } from '../../../table_extends/table_body/table_body';
import { getTableHeaders } from '../../../table_extends/table_header/table_headers';
import {getValueStore, removeValueStore, setValueStore} from "../../../../shared/store";

export function ModalSettings(props: {
    keyModal: string;
    onConfirm: (props: {
        viewDates: boolean;
        onlyTable: boolean;
        viewCompletedObj: boolean;
        viewInvoices: boolean;
    }) => void;
    onCancel: () => void;
    col: number;
}) {
    const cell = getWData().grid.getCellRect(props.col, getWData().cellOpen!.row);
    const grid = getWData().grid;

    let x = cell?.left - grid?.scrollLeft - 244;
    let y = cell?.top + cell?.height - window.scrollY - grid.scrollTop + getHeaderComponentHeight();

    // Видимость плановых дат
    const [viewDates, setViewDatest] = useState(tokenActions.planeDates != AccessItem.NONE);

    // Видимость Поставлено и выполнено на объекте
    const [viewCompletedObj, setViewCompletedObj] = useState(tokenActions.wellBill != AccessItem.NONE);

    // Видимость Счет-фактур
    const [viewInvoices, setViewInvoices] = useState(tokenActions.invoices != AccessItem.NONE);

    // Убрать шапку страницы
    const [onlyTable, setOnlyTable] = useState(getValueStore('onlyTable') == '1');

    return (
        <div
            key={props.keyModal}
            className={'ModalSettings'}
            style={{
                position: 'absolute',
                top: y,
                left: x,
            }}
        >
            <div className={'title'}>
                {' '}
                Настроить столбцы <CloseOutlined onClick={props.onCancel} />{' '}
            </div>
            <div className={'line'}>
                <Checkbox checked={viewDates} onChange={(val) => setViewDatest(!viewDates)} />
                <span onClick={() => setViewDatest(!viewDates)}> График</span>
            </div>
            <div className={'line'}>
                <Checkbox checked={viewCompletedObj} onChange={(val) => setViewCompletedObj(!viewCompletedObj)} />
                <span onClick={() => setViewCompletedObj(!viewCompletedObj)}> Поставлено и выполнено на объекте</span>
            </div>
            <div className={'line'}>
                <Checkbox checked={viewInvoices} onChange={(val) => setViewInvoices(!viewInvoices)} />
                <span onClick={() => setViewInvoices(!viewInvoices)}> Счет-фактуры</span>
            </div>
            <div className={'line'}>
                <Checkbox checked={onlyTable} onChange={(val) => setOnlyTable(!onlyTable)} />
                <span onClick={() => setOnlyTable(!onlyTable)}> Только таблица</span>
            </div>
            <div className={'buttons'}>
                <Button
                    type={'primary'}
                    onClick={() => props.onConfirm({ viewDates, onlyTable, viewCompletedObj, viewInvoices })}
                >
                    Применить
                </Button>
                <Button type={'default'} onClick={props.onCancel}>
                    Отмена
                </Button>
            </div>
        </div>
    );
}

export const ModalSettingsBind: FunctionComponent<{ renderTable: () => void }> = ({ renderTable }) => {
    return (
        <ModalSettings
            keyModal={`modal_settings_${getWData().refresh}`}
            onConfirm={({ viewDates, onlyTable, viewCompletedObj, viewInvoices }) => {
                if (viewDates) {
                    tokenActions.planeDates =
                        tokenActions.contractsDates == AccessItem.ALLOW ? AccessItem.EDIT : AccessItem.VIEW;
                } else {
                    tokenActions.planeDates = AccessItem.NONE;
                }

                tokenActions.wellBill = viewCompletedObj ? AccessItem.EDIT : AccessItem.NONE;
                tokenActions.invoices = viewInvoices ? AccessItem.EDIT : AccessItem.NONE;

                if (onlyTable) {
                    document.documentElement.style.setProperty('--visibleHeader', 'none');
                    document.documentElement.style.setProperty('--visibleHeaderHeight', '10px');
                    setValueStore('onlyTable', '1');
                } else {
                    removeValueStore('onlyTable');
                    document.documentElement.style.setProperty('--visibleHeader', 'flex');
                    document.documentElement.style.setProperty('--visibleHeaderHeight', '80px');
                }

                setValueStore('planeDates', tokenActions.planeDates);
                setValueStore('completedObj', tokenActions.wellBill);
                setValueStore('invoices', tokenActions.invoices);

                getWData().excludedColumns = getExcludesColumns();

                // document.getElementsByTagName('canvas')[0]
                getWData().grid.updateSize();

                setTimeout(() => {
                    renderTable();
                    getWData().grid.layout = {
                        header: getTableHeaders(),
                        body: getTableBody(TableMode.VIEW),
                    };
                    getWData().grid.updateSize();
                }, 50);

                closeModalSettings();
            }}
            onCancel={() => {
                closeModalSettings();
            }}
            col={getWData().cellOpen?.col ?? 1}
        />
    );
};

export function closeModalSettings() {
    if (!getWData().cellOpen?.isModalSettings) return;
    console.log('closeModalSendApprove');

    if (getWData().cellOpen?.isModalSettings) {
        getWData().cellOpen!.isModalSettings = false;
    }
    getWData().setRefresh(Math.random());
}
