import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {getWData} from "../../../table_extends/getWData";
import {IModalAddGlobal} from "./modal_add_contract";

export const ModalWellBill: FunctionComponent<IModalAddGlobal> = (props) => {

    return <>
        {props.open && <div className={`ModalWellBill`}>
            ModalWellBill
        </div>}
    </>
}


export function closeModalWellBill() {
    if (!getWData().cellOpen?.isModalWayBill) return;
    console.log('closeModalWellBill');

    if (getWData().cellOpen?.isModalWayBill) {
        getWData().cellOpen!.isModalWayBill = false;
    }
    getWData().setRefresh(Math.random());
}
