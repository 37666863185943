export const groupsColumnsBP = [
  'cns_budget_plan_size',
  'cns_budget_plan_price',
  'cns_budget_plan_sum_wat',
  'cns_budget_plan_far',
  'cns_budget_plan_far2',
];

export const groupsColumnsBF = [
  'cns_budget_fakt_size',
  'cns_budget_fakt_price',
  'cns_budget_fakt_sum_wat',
  'cns_budget_fakt_far',
  'cns_budget_fakt_far2',
];

export const groupsColumnsContracts = ['cns_plane_size', 'cns_contact_date', 'cns_contact_contragent'];
