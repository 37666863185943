import { Error } from "../ApiEpo";
import { api } from "../shared/api_client";
import { message } from "antd";

export const downloadInvoicesDocuments = async (id: string, name: string) => {
  try {
    const res = await api.invoices.documentsFilesArchiveDetail(id);

    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.href = url;
    element.download = name;
    element.click();
    URL.revokeObjectURL(url);
  } catch (error: any) {
    if (error.status === 500) {
      message.error(`Ошибка: файлы не найдены`)
    } else {
      message.error(`Ошибка: ${error}`)

    }
  }

}



