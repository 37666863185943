import {TableRecord} from "../TableRecord";
import {getWData} from "../../../table_extends/getWData";
import {getPassportCache} from "../../../table_extends/loadData";
import {getResultRows} from "../../../table_extends/getResultRows";
import {getTableHeaders} from "../../../table_extends/table_header/table_headers";
import {getTableBody, TableMode} from "../../../table_extends/table_body/table_body";

export function updateDates(record: TableRecord, columnKey: keyof TableRecord, value?: string, singleDate?: boolean) {
  console.log('updateDates', record, columnKey, value, singleDate);
  const value2 = value ?? record[columnKey];

  if (record.cns_row_type == 'work_type') {
    (record[columnKey] as any) = value2;

    if (!singleDate) {
      const rows = getWData().rows3.filter(e => e.cns_group_id == record.cns_group_id);

      for (const row of rows) {
        (row as any)[columnKey] = value2;
      }
    }
  }

  if (record.cns_row_type == 'section') {
    (record[columnKey] as any) = value2;


    if (!singleDate) {
      const rows3 = getWData().rows3.filter(e => e.cns_section_id == record.cns_section_id);
      const rows2 = getWData().rows2.filter(e => e.cns_section_id == record.cns_section_id);

      for (const row of [...rows3, ...rows2]) {
        (row as any)[columnKey] = value2;
      }
    }
  }

  if (record.cns_row_type == 'project') {
    if (!singleDate) {
      for (const row of [...getWData().rows, ...getWData().rows2, ...getWData().rows3]) {
        (row as any)[columnKey] = value2;
      }
    }

    if (columnKey == 'cns_plane_date_start') {
      getPassportCache()!.start_date = value2 as any;
    }

    if (columnKey == 'cns_plane_date_end') {
      getPassportCache()!.end_date = value2 as any;
    }
  }

  getWData().resultRowValues[columnKey] = value2 as any;
  getWData().resultRows = getResultRows();

  getWData().grid.layout = {
    header: getTableHeaders(),
    body: getTableBody(TableMode.VIEW),
  };

  getWData().grid.invalidate();
  getWData().setRefresh(Math.random());
}
