export type ErrorType = "contract_planning_date_error"
  | "contract_work_start_date_error"
  | "contract_not_completed_error"
  | "contract_work_end_date_error"
  | "contract_over_error"
  | "invoice_over_error"
  | "waybill_over_error"
  | "bill_over_error"

export enum ErrorTypeEnum {
    CONTRACT_PLANNING_DATE_ERROR = "contract_planning_date_error",
    CONTRACT_NOT_COMPLETED_ERROR = "contract_not_completed_error",
    CONTRACT_WORK_START_DATE_ERROR = "contract_work_start_date_error",
    CONTRACT_WORK_END_DATE_ERROR = "contract_work_end_date_error",
    CONTRACT_OVER_ERROR = "contract_over_error",
    INVOICE_OVER_ERROR = "invoice_over_error",
    WAYBILL_OVER_ERROR = "waybill_over_error",
    BILL_OVER_ERROR = "bill_over_error",
}
