import { useQuery } from 'react-query';
import { apiAuth } from '../shared/api_client';

export function useUsers(search = '') {
  const {
    data: users,
    error: usersError,
    isLoading: usersLoading,
  } = useQuery({
    queryKey: [`users_${search}`],
    queryFn: () => apiAuth.helpers.usersList({ search: search }).then((e) => e.data ?? []),
  });

  return {
    users,
    usersError,
    usersLoading,
  };
}
