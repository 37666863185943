import { useQuery } from 'react-query';
import { api } from '../shared/api_client';

export function useWorkSectionList() {
  const {
    data: workSections,
    error: workSectionsError,
    isLoading: workSectionsLoading,
  } = useQuery({
    queryKey: [`work-sections`],
    queryFn: () => api.helpers.workSectionsList().then((e) => e.data ?? []),
  });

  return {
    workSections,
    workSectionsError,
    workSectionsLoading,
  };
}
