import {useMemo} from "react";
import {getPassportCache} from "../screens/table_extends/loadData";

export const useMembersRps = () => {

  const res = getPassportCache()?.members?.filter(e => e.position == 'pm' || e.position == 'senior_pm') ?? [];

  res.sort((e1, e2) => e1.position == 'pm' ? -1 : 1);

  return res.map(e => `${e.full_name}`).join(' / ');

}
