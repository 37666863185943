import { useQuery } from 'react-query';
import { directoryApi } from '../shared/api_client';
import {getContractorsLocationsDb, syncContractorsLocations} from "../indexedb";

export function useContragentsLocations(params: { search: string }) {
  const {
    data: contragentsLocation,
    error: contragentsLocationError,
    isLoading: contragentsLocationLoading,
  } = useQuery({
    queryKey: ['contragents_locations', `contragents_locations_${params.search}`],
    queryFn: async () => {
      try {
        const res = await directoryApi.helpers.contractorLocationsList({ search: params.search }).then((r) => r.data);
        if (!params.search) {
          await syncContractorsLocations(res.data ?? [])
        }
        return res;
      } catch (e) {
        console.log(e);
        return Promise.resolve({data: (await getContractorsLocationsDb()).filter(e => e.toLowerCase().includes(params.search.toLowerCase()))})
      }
    },
  });

  return {
    contragentsLocation,
    contragentsLocationLoading,
    contragentsLocationError,
  };
}
