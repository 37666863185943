import { Button, Flex,  Modal, Tag } from 'antd';
import React, { FunctionComponent } from 'react';

const MobileContragentListFilter: FunctionComponent<{
    isOpen: boolean;
    close: () => void;
    filtersLocation: string[],
    filtersRating: { value?: string | undefined; label?: string | undefined; }[],
    filtersTags: string[],
    setFilter: (res: { [x: string]: string[] })=>void,
    setDefaultFilter: (filter: boolean)=>void,
    defaultFilter: boolean,
}> = (props) => {
    const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
    const [selectedRaiting, setSelectedRaiting] = React.useState<string[]>(['Желтый', 'Зеленый', 'Без рейтинга']);
    const [selectedLocation, setSelectedLocation] = React.useState<string[]>([]);

    const handleChangeTag = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };
    const handleChangeRaiting = (tag: string, checked: boolean) => {
        const nextSelectedRaiting = checked
            ? [...selectedRaiting, tag]
            : selectedRaiting.filter((t) => t !== tag);
        setSelectedRaiting(nextSelectedRaiting);
    };
    const handleChangeLocation = (tag: string, checked: boolean) => {
        const nextSelectedLocation = checked
            ? [...selectedLocation, tag]
            : selectedLocation.filter((t) => t !== tag);
        setSelectedLocation(nextSelectedLocation);
    };

    return (
        <Modal
            width={1050}
            title="Фильтры"
            /*             open={props.isOpen}
                        onOk={handleOk} */
            open={props.isOpen}
            onCancel={(e) => {
                props.close()
            }}
            footer={[
                <Button className='btn-reset' key="back" onClick={(e) => {

                    setSelectedTags([])
                    setSelectedRaiting([])
                    setSelectedLocation([])


                props.close()
                props.setFilter({})
            }}>
                Сбросить
                </Button>,
                <Button key="submit" type="primary"  onClick={()=>{
                props.setDefaultFilter(false)
                props.setFilter({
                    location: [...selectedLocation],
                    rating: [...selectedRaiting.map((item : string)=>{
                        if(item === 'Без рейтинга') return String(0) 
                        if(item === 'Черный') return String(1)
                        if(item === 'Желтый') return String(2)
                        if(item === 'Зеленый') return String(3)
                        return String(0)
                    })],
                    tags: [...selectedTags]
                })
                props.close()
            }}>
                Применить
                </Button>
            ]}
            wrapClassName={`wrappe-modal-mobile-filter`}
            className={`modal-mobile-filter`}

        >
            <div className='mobile-filter-tags'>

                <Flex style={{ flexWrap: 'wrap' }} gap={8} align="center">
                    <span>Работы:</span>
                    {props.filtersTags.map<React.ReactNode>((tag) => (
                        <Tag.CheckableTag
                            className='filter-tag'
                            key={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={(checked) => handleChangeTag(tag, checked)}
                        >
                            {tag}
                        </Tag.CheckableTag>
                    ))}
                </Flex>
            </div>

            <div className='mobile-filter-tags'>

                <Flex style={{ flexWrap: 'wrap' }} gap={8} align="center">
                    <span>Рейтинг:</span>
                    {props.filtersRating.map<React.ReactNode>((tag) => (
                        <Tag.CheckableTag
                            className='filter-tag'
                            key={tag.label ?? ''}
                            checked={selectedRaiting.includes(tag.label ?? '')}
                            onChange={(checked) => handleChangeRaiting(tag.label ?? '', checked)}
                        >
                            {tag.label ?? ''}
                        </Tag.CheckableTag>
                    ))}
                </Flex>
            </div>
            <div className='mobile-filter-tags'>

                <Flex style={{ flexWrap: 'wrap' }} gap={8} align="center">
                    <span>Локации:</span>
                    {props.filtersLocation.map<React.ReactNode>((tag) => (
                        <Tag.CheckableTag
                            className='filter-tag'
                            key={tag}
                            checked={selectedLocation.includes(tag)}
                            onChange={(checked) => handleChangeLocation(tag, checked)}
                        >
                            {tag}
                        </Tag.CheckableTag>
                    ))}
                </Flex>
            </div>
        </Modal>
    );
};

export default MobileContragentListFilter;
