import { getTableColumnKey } from '../../table_extends/getTableColumnKey';
import { getWData } from '../../table_extends/getWData';
import { getTableBody, TableMode } from '../../table_extends/table_body/table_body';
import { TableRecord } from '../components/TableRecord';

/**
 * Открыть поле ввода
 *
 * @param {number} col - The column number.
 * @param {number} row - The row number.
 * @param {TableRecord} currentRow - The current row.
 */
export function openEditor(col: number, row: number, currentRow: TableRecord) {
  if (getWData().cellOpen) {
    getWData().cellOpen!.openEdit = true;

    getWData().cellOpen!.col = col;
    getWData().cellOpen!.row = row;

    const key = getTableColumnKey(getWData().cellOpen!.col, getTableBody(TableMode.SCROLL));

    getWData().textInput = (currentRow[key as keyof TableRecord] as any) ?? '';

    getWData().setRefresh(Math.random());
  }
}
