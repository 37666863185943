import { Button, Layout } from 'antd';
import { getValueStore } from '../shared/store';

const NotFondScreen = () => {
    if (!getValueStore('token')) {
        window.location.href = '/auth';
    }

    return (
        <Layout title={'Страница не существует'} className={'not_found'}>
            <div className={'not_found_number'}>404</div>
            <div className={'not_found_text'}>
                Страница не найдена. Возможно, вы <br />
                ошиблись адресом или страница была удалена{' '}
            </div>
            <br />
            <Button type={'primary'} onClick={() => (window.location.href = '/')}>
                {' '}
                Список проектов{' '}
            </Button>
        </Layout>
    );
};

export default NotFondScreen;
