import { getValueStore, removeValueStore, setValueStore } from './store';
import { getWData } from '../screens/table_extends/getWData';
import { getTableHeaders } from '../screens/table_extends/table_header/table_headers';
import { getTableBody, TableMode } from '../screens/table_extends/table_body/table_body';

export function updStorePinned() {
  setValueStore('pinned_number', getWData().pinned.numberPinned ? 'true' : 'false');
  setValueStore('pinned_title', getWData().pinned.titlePinned ? 'true' : 'false');
  setValueStore('pinned_unit', getWData().pinned.unitPinned ? 'true' : 'false');
}

export function getStorePinned() {
  return {
    numberPinned: getValueStore('pinned_number') != 'false',
    titlePinned: getValueStore('pinned_title') != 'false',
    unitPinned: getValueStore('pinned_unit') != 'false',
  }
}

