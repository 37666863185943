import { api } from '../../../shared/api_client';
import { getPassportCache, loadData } from '../../table_extends/loadData';
import { getWData } from '../../table_extends/getWData';
import { delay } from '../../../shared/delay';
import { getResultRows } from '../../table_extends/getResultRows';

export async function doConnectSections(section_ids: string[]) {
  if (section_ids.length === 0) {
    return
  }

  getWData().setRefresh(Math.random())

  await api.projects.entityCreate({
    project_id: getPassportCache().id,
    entity_ids: section_ids,
    entity_type: 'section',
    replacement: {  }
  })

  await delay(1000)

  await getWData().loadDataPassport({showLoaded: true})
}

export async function doConnectWorkTypes(section_id: string, work_type_ids: string[]) {
  if (work_type_ids.length === 0) {
    return
  }
  getWData().setRefresh(Math.random())

  await api.projects.entityCreate({
    project_id: getPassportCache().id,
    entity_ids: work_type_ids,
    entity_type: 'work_type',
    replacement: {
      section_id,
    }
  })

  await getWData().loadDataPassport({showLoaded: true})
}

export async function doConnectNomenclatures(section_id: string, work_type_id: string, nomenclature_ids: string[]) {
  if (nomenclature_ids.length === 0) {
    return
  }
  getWData().setRefresh(Math.random())

  await api.projects.entityCreate({
    project_id: getPassportCache().id,
    entity_ids: nomenclature_ids,
    entity_type: 'nomenclature',
    replacement: {
      section_id,
      work_type_id,
    }
  })

  await getWData().loadDataPassport({showLoaded: true})
}
