import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { AutoComplete, Form, Input, Modal, TreeSelect } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { uniq } from 'lodash';
import { useNomenclaturesTable } from '../../actions/useNomenclaturesTable';
import {
    getEmptyNomenclatureListModalPositionState,
    NomenclatureListModalPositionState, NomenclatureListModalPositionStatePlace,
    TreeNode,
} from './NomenclatureListHelpers';
import { delay } from '../../shared/delay';
import { NomenclaturesTableRow } from '../../ApiDirectory';

const NomenclaturesListModalPosition: FunctionComponent<{
    isOpen: boolean | string,
    onClose: () => void,
    onDone: ( state: NomenclatureListModalPositionState, section_id?: string, work_type_id?: string, nomenclature_id?: string ) => void,
    select_section_id?: string,
    select_work_type_id?: string,
    select_nomenclature_id?: string,
    nomenclatures: NomenclaturesTableRow[],
}> = ( props ) => {
    const { nomenclatures, nomenclaturesLoading, nomenclaturesError } = useNomenclaturesTable();
    const [ state, setState ] = useState<NomenclatureListModalPositionState>( getEmptyNomenclatureListModalPositionState() );

    const selectItem = useMemo( () => {
        const item = nomenclatures?.rows?.find( row => {
            if ( !row.title ) return false;

            if ( props.isOpen === true ) {
                return row.type_id == props.select_work_type_id;
            } else {
                return row.nomenclature_id == props.select_nomenclature_id;
            }
        } );

        return item ?? { title: '', unit: '', section_id: '', type_id: '', nomenclature_id: '' };
    }, [ props.isOpen, nomenclatures ] );

    useEffect( () => {
        if ( props.isOpen === false || props.isOpen === undefined ) {
            setState( getEmptyNomenclatureListModalPositionState() );
        } else {
            const items = props.nomenclatures.filter(row => row.nomenclature_id == selectItem.nomenclature_id);
            const title = props.isOpen === true ? '' : selectItem?.title ?? '';
            const unit = props.isOpen === true ? '' : selectItem?.unit ?? '';
            let place: { value: string[], section_id: string[], work_type_id: string[], nomenclature_id: string } = {
                value: items.map(e => `${e.section_id}_${e.type_id}`),
                section_id: items.map(e => e.section_id ?? ''),
                work_type_id: items.map(e => e.type_id ?? ''),
                nomenclature_id: selectItem.nomenclature_id ?? '',
            };

            const state: NomenclatureListModalPositionState = {
                entity_id: selectItem.nomenclature_id ?? '',
                title: title,
                unit: unit,
                place: place,
            };

            setState( state );
            delay( 100 ).then( _ => setState( state ) );

        }
    }, [ props.isOpen, nomenclatures ] );

    const treeData = useMemo( () => {
        const sections = nomenclatures?.rows.filter( row => row.row_type == 'section' ) ?? [];
        const workTypes = nomenclatures?.rows.filter( row => row.row_type == 'work_type' ) ?? [];

        const res: TreeNode[] = [];

        for ( const section of sections ) {
            res.push( {
                title: section.title ?? '',
                value: section.section_id ?? '',
                disabled: true,
                children: workTypes
                    .filter( row => row.section_id == section.section_id )
                    .map( row => ({
                        title: row.title ?? '',
                        value: `${row.section_id}_${row.type_id ?? ''}`,
                        disabled: false,
                    }) ),
            } );
        }

        return res;
    }, [ nomenclatures, props ] );

    useEffect( () => {
        setState( {
            ...state,
            place: {
                value: [ props.select_nomenclature_id ?? (props.select_work_type_id ? `${props.select_section_id}_${props.select_work_type_id}` : undefined) ?? props.select_section_id ?? '' ],
                section_id: [ props.select_section_id ?? '' ],
                work_type_id: [ props.select_work_type_id ? `${props.select_section_id}_${props.select_work_type_id}` : `` ],
                nomenclature_id: props.select_nomenclature_id ?? '',
            },
        } );
    }, [ props.isOpen ] );

    (window as any).modalNomencalature = {
        props,
        state,
        selectItem,
    };

    return (
        <Modal
            open={ !!props.isOpen } onClose={ props.onClose } onCancel={ props.onClose }
            onOk={ () => {
                props.onDone(state);
            }}
            okButtonProps={ { disabled: !state.place.value.length } }
            title={ 'Продублировать' }
        >
            <br/>
            { (props.isOpen === true || state.title != '') && <>
                <Form labelCol={ { span: 8 } } key={ `posotion-${ props.isOpen }` }>
                    <FormItem label={ 'Расположение' }>
                        <TreeSelect
                            multiple={ true }
                            key={ `position-${ props.isOpen }` }
                            value={ state.place.value.length ? state.place.value : [] }
                            placeholder={ 'Выберите виды работы для вставки номенклатуры' }
                            allowClear
                            treeDefaultExpandAll
                            treeData={ treeData }
                            onChange={ ( value: string[] ) => {
                                let res: NomenclatureListModalPositionStatePlace = {
                                    value,
                                    section_id: uniq(value.map(value => value.split('_')[0] ?? '')),
                                    work_type_id: value,
                                    nomenclature_id: selectItem.nomenclature_id ?? '',
                                };

                                setState( { ...state, place: res! } );
                            } }
                        />
                    </FormItem>
                </Form>
            </> }
        </Modal>
    );
};

export { NomenclaturesListModalPosition };
