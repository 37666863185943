import { useQuery, useQueryClient } from 'react-query';
import { Prices } from '../ApiDirectory';
import { PriceNomenclatureRecord, PriceRegions, PriceRowInline } from '../screens/prices/PricesListHelper';
import { uniqBy } from 'lodash';
import { directoryApi } from '../shared/api_client';
import tokenActions from './tokenActions';

export interface NomenclaturePriceLite {
  /** @example "0d57fe59-9445-40ee-80bd-9fbb140d96be" */
  nomenclature_id?: string;
  /** @example null */
  price?: number | null;
  /** @example "03381f7d-c02a-4213-bf52-2c47b0337f77" */
  region_id?: string;
  /** @example "Новосибирск" */
  region_name?: string;
  /** @example 1 */
  region_order?: number;
  /** @example "active" */
  region_status?: string;
}

export interface NomenclaturePriceLiteRegion {
  /** @example "03381f7d-c02a-4213-bf52-2c47b0337f77" */
  id?: string;
  /** @example "Новосибирск" */
  name?: string;
  /** @example 1 */
  order?: number;
  /** @example "active" */
  status?: string;
  /** @example "Новосибирск" */
  title?: string;
}

export function usePricesLiteTable() {
  const {
    data: prices,
    error: pricesError,
    isLoading: pricesLoading,
  } = useQuery({
    queryKey: [`prices_lite`],
    queryFn: async (): Promise<{ data: NomenclaturePriceLite[]; filters: { regions: NomenclaturePriceLiteRegion[] } }> => {
      if (tokenActions.directoryPrices == 'NONE') {
        return {
          data: [],
          filters: {
            regions: [],
          },
        };
      }

      const res2 = await directoryApi.nomenclaturePrices.liteList();

      const response = res2.data;
      // const response = {
      //   "data": [
      //     {
      //       "nomenclature_id": "0d57fe59-9445-40ee-80bd-9fbb140d96be",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "17e05299-4423-4de9-9aad-9ea9ee7f831f",
      //       "price": 100,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "226183e1-8043-45df-8f09-107e53b1b26a",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "2e4803ac-1392-4efe-8e3b-07d786bb4b06",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "32dc91b4-c96a-46fb-beac-a9a80daafda5",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "36a36cd9-c11c-4554-8c08-16acea9d098f",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "41fb4856-6279-4fa8-a551-ecb7a97518b9",
      //       "price": 15,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "4817a99b-570d-4a58-8fa3-12d52a4a7f99",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "6d5d5047-1f49-4f3f-aa7c-b3af57b01b32",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "9bc33881-5355-4539-93ff-71bf8b23f702",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "edaf4ec9-47f5-40f5-8b80-a0f0e14dcfc7",
      //       "price": null,
      //       "region_id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //       "region_name": "Новосибирск",
      //       "region_order": 1,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "0d57fe59-9445-40ee-80bd-9fbb140d96be",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "17e05299-4423-4de9-9aad-9ea9ee7f831f",
      //       "price": 500,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "226183e1-8043-45df-8f09-107e53b1b26a",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "2e4803ac-1392-4efe-8e3b-07d786bb4b06",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "32dc91b4-c96a-46fb-beac-a9a80daafda5",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "36a36cd9-c11c-4554-8c08-16acea9d098f",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "41fb4856-6279-4fa8-a551-ecb7a97518b9",
      //       "price": 150,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "4817a99b-570d-4a58-8fa3-12d52a4a7f99",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "6d5d5047-1f49-4f3f-aa7c-b3af57b01b32",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "9bc33881-5355-4539-93ff-71bf8b23f702",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     },
      //     {
      //       "nomenclature_id": "edaf4ec9-47f5-40f5-8b80-a0f0e14dcfc7",
      //       "price": null,
      //       "region_id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //       "region_name": "Томск",
      //       "region_order": 2,
      //       "region_status": "active"
      //     }
      //   ],
      //   "filters": {
      //     "regions": [
      //       {
      //         "id": "03381f7d-c02a-4213-bf52-2c47b0337f77",
      //         "name": "Новосибирск",
      //         "order": 1,
      //         "status": "active",
      //         "title": "Новосибирск"
      //       },
      //       {
      //         "id": "e4d1d0f2-b41d-438d-a3bd-80cdded5f083",
      //         "name": "Томск",
      //         "order": 2,
      //         "status": "active",
      //         "title": "Томск"
      //       }
      //     ]
      //   }
      // };

      return {
        data: response.data ?? [],
        filters: {
          regions: response.filters?.regions ?? [],
        },
      };
    },
  });

  const queryClient = useQueryClient();

  const clearCache = () => {
    queryClient.invalidateQueries(['prices_lite']);
  };


  return {
    prices: prices?.data ?? [],
    pricesRegions: prices?.filters?.regions ?? [],
    pricesError,
    pricesLoading,
    pricesClearCache: clearCache,
  };
}
