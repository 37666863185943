import { useQuery } from 'react-query';
import { api } from '../shared/api_client';

export function useSettingsView() {
  const {
    data: settings,
    isLoading: settingsLoading,
    error: settingsError,
  } = useQuery({
    queryKey: [`settings`],
    queryFn: () => api.settings.settingsList(),
  });

  return {
    settings,
    settingsLoading,
    settingsError,
  };
}
