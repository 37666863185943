import React, {FunctionComponent, useEffect, useState} from 'react';
import {Input, notification} from 'antd';
import {sizes} from '../../../../shared/sizes';
import {CloseOutlined, UploadOutlined} from '@ant-design/icons';
import {documentsApi} from "../../../../shared/api_client";
import {isOnline} from "../../../../indexedb";
import {getBase64} from "../../../contragents/uploadFile";
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../../i18n/localeKeys";


const ModalAddContractUpload: FunctionComponent<{
    width?: string,
    files: { name: string, id: string }[],
    onUploaded: (items: { id: string, name: string }[]) => void,
    disabled: boolean
}> = (props) => {
    const [uploadingFiles, setUploadingFiles] = useState(false)
    const [files, setFiles] = useState<{ name: string, id: string }[]>([])
    const {t} = useTranslation();

    useEffect(() => {
        setFiles(props.files)
    }, []);

    async function uploadFile(event: any) {
        try {
            setUploadingFiles(true);
            const filesUpd = [...files];
            for (let file of event.target.files) {
                console.log(file, process.env.REACT_APP_DEBUG_SYNC_IS_ONLINE);
                if (isOnline) {
                    try {
                        const res = await documentsApi.files.uploadCreate({file: file});
                        console.log(res);
                        if ((res.data as any).error_epo_be) {
                            notification.error({message: t(localeKeys.files.uploadError)});
                        } else {
                            const itemUploaded = {id: res.data.id ?? '', name: res.data.name ?? '-'}
                            filesUpd.push(itemUploaded)
                            props.onUploaded(filesUpd)

                            await new Promise((resolve) => setTimeout(resolve, 100));
                        }
                    } catch (e) {
                        console.log(e);
                        notification.error({message: t(localeKeys.files.uploadError)});
                    }
                } else {
                    const itemUploaded = {id: await getBase64(file), name: file.name}
                    filesUpd.push(itemUploaded);
                    props.onUploaded(filesUpd);


                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
            }

            setFiles(filesUpd)
        } catch (e) {
        } finally {
            setUploadingFiles(false);
        }
    }

    function removeFile(id: string) {
        const filesUpd = files.filter(e => e.id != id);
        setFiles(filesUpd);
        props.onUploaded(filesUpd);
    }


    return <span className={'upload_file'}>
        <div
            className={`wrap_file ${props.disabled ? 'disabled' : ''}`}
            style={{width: props.width}}
        >
            <span className={'wrap_file__title'}>
                <UploadOutlined/> &nbsp;
                {t(localeKeys.files.loadFileBtn)}
            </span>
            <Input
                type="file"
                className={'file contragent_file_many'}
                onChange={uploadFile}
                multiple
            />
        </div>

        <div
            className={'wrap_list_files'}
            style={window.innerWidth > sizes.mobile ? {} : {}}
        >
            {/*{JSON.stringify(files)}*/}
            {files
                ?.filter((e) => e)
                ?.map((doc, index) => (
                    <div key={`doc_${index}`} className={`doc_line`}>
                        <div title={doc.name}>{doc.name}</div>
                        <CloseOutlined onClick={() => removeFile(doc.id)}/>
                    </div>
                ))}
            {uploadingFiles && <>{t(localeKeys.files.loadingFiles)}</>}
        </div>

    </span>
};

export default ModalAddContractUpload;
