import {getWData} from "./getWData";

export function checkUpdBpSize(nomenclatureId: string, size: number): boolean {
  console.log('checkUpdBpSize', size)
  const record = getWData().rows3.find(e => e.cns_nomenclature_id === nomenclatureId);
  if (!record) {
    return false;
  }

  if (!record.cns_contracts.length) {
    return true;
  }

  const contractsSize = record.cns_contracts.reduce( (acc, e) => acc + (Number(e.size || '0') ?? 0), 0 )

  return size >= contractsSize;
}

export function checkUpdBpPrice(nomenclatureId: string, price: number): boolean {
  const record = getWData().rows3.find(e => e.cns_nomenclature_id === nomenclatureId);
  if (!record) {
    return false;
  }

  if (!record.cns_contracts.length) {
    return true;
  }

  const contractsPriceAvg = record.cns_contracts.reduce( (acc, e) => acc + (Number(e.price || '0') ?? 0), 0 ) / record.cns_contracts.length;

  return price >= contractsPriceAvg;
}
