import { useQuery, useQueryClient } from 'react-query';
import { directoryApi } from '../shared/api_client';
import tokenActions from './tokenActions';

export function useMeasureUnits(props: { page: number, per_page?: number}) {
  const client = useQueryClient();

  const {
    data: measureUnits,
    isLoading: isLoadingMeasureUnits,
    isError: isErrorMeasureUnits,
    error: errorMeasureUnits,
  } = useQuery({
    queryKey: [`measure_units_list`, `measure_units_list-${props.page}`],
    queryFn: async () => {
      if (tokenActions.directoryMeasureUnits == 'NONE') {
        return  {
          items: [],
          meta: {},
        }
      }

      const res = await directoryApi.measureUnit.measureUnitList({sort_by: 'title', sort_desc: 0, per_page: props.per_page ?? 15, page: props.page } as any)

      return {
        items: res.data.data ?? [],
        meta: res.data.meta ?? {},
      }
    }
  });

  function clearCache() {
    client.invalidateQueries([`measure_units_list`]);
  }

  return {
    measureUnits: measureUnits?.items ?? [],
    measureUnitsPaginate: measureUnits?.meta ?? {},
    isLoadingMeasureUnits,
    isErrorMeasureUnits,
    errorMeasureUnits,
    measureUnitClearCache: clearCache,
  };
}
