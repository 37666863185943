import {HeaderCellDefine} from "cheetah-grid/list-grid/layout-map/api";
import {TableRecord} from "../../canvas_table/components/TableRecord";
import {themeTable} from "../table_theme";
import tokenActions, {AccessItem} from "../../../actions/tokenActions";
import {headersMain, headersMain2} from "./table_headers_main";
import {getColumnHeaderStyle, getColumnHeaderStyle2} from "./column_header_style";
import {localeKeys} from "../../../i18n/localeKeys";
import isMobile from '../../../shared/isMobile';
import { getWData } from '../getWData';

const t = () => (window as any).t;

// Бюджет план
const headersBudgetPlan: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.budgetPlan),
        headerStyle: getColumnHeaderStyle("cns_budget_plan_size"),
        colSpan: 6,
        width: 620,
    },
]

const headersBudgetPlan2: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanSize),
        headerStyle: getColumnHeaderStyle2("cns_budget_plan_size"),
        width: 110,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanPrice),
        headerStyle: getColumnHeaderStyle2("cns_budget_plan_price"),
        width: 150,
      rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanSum),
        headerStyle: getColumnHeaderStyle2("cns_budget_plan_sum_wat"),
        colSpan: 2,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanFar),
        headerStyle: ({
            ...getColumnHeaderStyle2("cns_budget_plan_far"),
            textOverflow: "ellipsis",
        }),
        width: 110,
      rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanPercent),
        headerStyle: getColumnHeaderStyle2("cns_budget_plan_far2"),
        width: 120,
      rowSpan: 2,
    },
]

// Контракты
const headersContracts: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.contracts),
        headerStyle: {...getColumnHeaderStyle("cns_contracts"), padding: [8, 8, 8, 8]},
        colSpan: 10,
        width: getWData().calc.hasContractsAgreement ? 1390 : 1390 - 30 + 1,
    },
]
const headersContracts2: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.contractsPlaneDate),
        headerStyle: {...getColumnHeaderStyle2("cns_contact_date")},
        width: 140,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsDateWorkStart),
        headerStyle: {...getColumnHeaderStyle2("cns_contact_date")},
        width: 140,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsDateWorkEnd),
        headerStyle: {...getColumnHeaderStyle2("cns_contact_date")},
        width: 140,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsResponsible),
        headerStyle: {...getColumnHeaderStyle2("cns_responsibles")},
        width: 250,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsRecommender),
        headerStyle: {...getColumnHeaderStyle2("cns_contractors")},
        width: 200,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsSize),
        headerStyle: getColumnHeaderStyle2("cns_budget_fakt_size"),
        width: 180,
        rowSpan: 2,
        colSpan: 3,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsPrice),
        headerStyle: getColumnHeaderStyle2("cns_budget_fakt_price"),
        width: 150,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.contractsSum),
        headerStyle: getColumnHeaderStyle2("cns_budget_fakt_sum_wat"),
        width: 170,
      rowSpan: 2,
    },
]

// График
const headersPlanedDates: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: "",
        headerStyle: ({
          ...getColumnHeaderStyle("cns_plane_date"),
        }),
        colSpan: 2,
        width: 300,
    },
]
const headersPlanedDates2: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanDateStart),
        headerStyle: getColumnHeaderStyle2("cns_plane_date_start"),
        width: 150,
        rowSpan: 2,
    },
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanDateEnd),
        headerStyle: {
            ...getColumnHeaderStyle2("cns_plane_date_end"),
            multiline: true,
        },
        width: 150,
        rowSpan: 2,
    },
]

// Бюджет план размер
const headersBpSize: () => HeaderCellDefine<TableRecord>[] = () => [
    {
      caption: t()?.(localeKeys.table.columns.budgetPlan),
      headerStyle: getColumnHeaderStyle("cns_plane_date"),
      width: 140,
    },
]
const headersBpSize2: () => HeaderCellDefine<TableRecord>[] = () => [
  {
    caption: t()?.(localeKeys.table.columns.budgetPlanSize),
    headerStyle: getColumnHeaderStyle2("cns_plane_date"),
    width: 140,
    rowSpan: 2,
  },
]

// Занесение счетов
const headersInvoices: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.invoices),
        headerStyle: getColumnHeaderStyle("cns_invoice_sum"),
        colSpan: 7,
        width: 930,
    },
]
const headersInvoices2: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: t()?.(localeKeys.table.columns.invoicesNumber), headerStyle: getColumnHeaderStyle2("cns_invoice_count"), width: 130, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.invoicesDate), headerStyle: getColumnHeaderStyle2("cns_invoice_date"), width: 130, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.invoicesSize), headerStyle: getColumnHeaderStyle2("cns_invoice_size"), width: 150, rowSpan: 2, colSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.invoicesPrice), headerStyle: getColumnHeaderStyle2("cns_invoice_price"), width: 180, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.invoicesSum), headerStyle: getColumnHeaderStyle2("cns_invoice_sum"), width: 130, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.invoicesStatus), headerStyle: getColumnHeaderStyle2("cns_invoice_status"), width: 180, rowSpan: 2,},
]

// Факт оплаты
const headersOtklBp: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanDiff),
        headerStyle: getColumnHeaderStyle("cns_otkl_sum"),
        colSpan: 2,
        width: 250,
    },
]

const headersOtklBp2: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: t()?.(localeKeys.table.columns.budgetPlanDiffSum), headerStyle: getColumnHeaderStyle2("cns_otkl_sum"), width: 150, rowSpan: 2,},
    {caption: "\n\n%", headerStyle: getColumnHeaderStyle2("cns_otkl_percent"), width: 100, rowSpan: 2,},
]

const headersPercentSum: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.budgetPlanDiffPercent),
        headerStyle: getColumnHeaderStyle("cns_percent_payment"),
        colSpan: 2,
        width: 250,
    },
]

const headersPercentSum2: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: t()?.(localeKeys.table.columns.budgetPlanDiffPaid), headerStyle: getColumnHeaderStyle2("cns_percent_payment"), width: 100, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.budgetPlanDiffToPay), headerStyle: getColumnHeaderStyle2("cns_payment_future"), width: 150, rowSpan: 2,},
]

// Поставлено и выполнено на объекте
const headersCompletedObj: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.wayBill),
        headerStyle: getColumnHeaderStyle("cns_well_bill_percent"),
        colSpan: 6,
        width: 720,
    },
]

const headersCompletedObj2: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: t()?.(localeKeys.table.columns.waybillInstalled), headerStyle: getColumnHeaderStyle2("cns_well_bill_size"), colSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.waybillSum), headerStyle: getColumnHeaderStyle2("cns_well_bill_delivery_sum"), width: 150, rowSpan: 2, colSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.waybillDate), headerStyle: getColumnHeaderStyle2("cns_well_bill_delivery_date"), width: 140, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.waybillDocument), headerStyle: getColumnHeaderStyle2("cns_well_bill_doc_number"), width: 130, rowSpan: 2,},
]

const headersCompletedObj3: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: "%", headerStyle: getColumnHeaderStyle2("cns_well_bill_percent"), width: 130},
    {caption: t()?.(localeKeys.table.columns.waybillSize), headerStyle: getColumnHeaderStyle2("cns_well_bill_size"), width: 170},
]

// Cчет-фактуры
const headersBill: () => HeaderCellDefine<TableRecord>[] = () => [
    {
        caption: t()?.(localeKeys.table.columns.bills),
        headerStyle: getColumnHeaderStyle("cns_bill_number"),
        colSpan: 6,
        width: 380,
    },
]

const headersBill2: () => HeaderCellDefine<TableRecord>[] = () => [
    {caption: t()?.(localeKeys.table.columns.billNumber), headerStyle: getColumnHeaderStyle2("cns_bill_number"), width: 130, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.billDate), headerStyle: getColumnHeaderStyle2("cns_bill_date"), width: 130, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.billSize), headerStyle: getColumnHeaderStyle2("cns_bill_size"), width: 150, rowSpan: 2, colSpan: 2},
    {caption: t()?.(localeKeys.table.columns.billPrice), headerStyle: getColumnHeaderStyle2("cns_bill_price"), width: 120, rowSpan: 2,},
    {caption: t()?.(localeKeys.table.columns.billSum), headerStyle: getColumnHeaderStyle2("cns_bill_sum"), width: 150, rowSpan: 2,},
]

export const getTableHeaders = (): HeaderCellDefine<TableRecord>[][] => {
    const res = [
        [
            ...headersMain(),
            ...(tokenActions.budgetPlan != AccessItem.NONE ? headersBudgetPlan() : []),
            ...(tokenActions.contractsDates != AccessItem.NONE && tokenActions.budgetPlan == AccessItem.NONE ? headersBpSize() : []),
            ...(tokenActions.planeDates != AccessItem.NONE ? headersPlanedDates() : []),
            ...((tokenActions.contracts != AccessItem.NONE || tokenActions.contractsDates != AccessItem.NONE) ? headersContracts() : []),
            ...(tokenActions.invoices != AccessItem.NONE ? headersInvoices() : []),
            ...(tokenActions.otklBp != AccessItem.NONE ? headersOtklBp() : []),
            ...(tokenActions.budgetFact != AccessItem.NONE ? headersPercentSum() : []),
            ...(tokenActions.wellBill != AccessItem.NONE ? headersCompletedObj() : []),
            ...(tokenActions.bill != AccessItem.NONE ? headersBill() : []),
        ],
        [
            ...headersMain2(),
            ...(tokenActions.budgetPlan != AccessItem.NONE ? headersBudgetPlan2() : []),
            ...(tokenActions.contractsDates != AccessItem.NONE && tokenActions.budgetPlan == AccessItem.NONE ? headersBpSize2() : []),
            ...(tokenActions.planeDates != AccessItem.NONE ? headersPlanedDates2() : []),
            ...((tokenActions.contracts != AccessItem.NONE || tokenActions.contractsDates != AccessItem.NONE) ? headersContracts2() : []),
            ...(tokenActions.invoices != AccessItem.NONE ? headersInvoices2() : []),
            ...(tokenActions.otklBp != AccessItem.NONE ? headersOtklBp2() : []),
            ...(tokenActions.budgetFact != AccessItem.NONE ? headersPercentSum2() : []),
            ...(tokenActions.wellBill != AccessItem.NONE ? headersCompletedObj2() : []),
            ...(tokenActions.bill != AccessItem.NONE ? headersBill2() : []),
        ],
        [
          ...(tokenActions.wellBill != AccessItem.NONE ? headersCompletedObj3() : []),
        ],
    ];

    const widthSum = res[0].reduce((a, b) => a + Number(b.width ?? '0'), 0);

    res[0].push({
        width: Math.max(window.innerWidth - widthSum - 80, 10),
        caption: '',
        headerField: 'EMPTY',
        headerStyle: {
            borderColor: themeTable.transparent,
        },
    })
    res[1].push({
        width: Math.max(window.innerWidth - widthSum - 80, 10),
        caption: '',
        headerField: 'EMPTY',
        headerStyle: {
            borderColor: themeTable.transparent,
        },
    })
    res[2].push({
        width: Math.max(window.innerWidth - widthSum - 80, 10),
        caption: '',
        headerField: 'EMPTY',
        headerStyle: {
            borderColor: themeTable.transparent,
        },
    })

    return res;
};


export const tableHeaders = getTableHeaders();
