import { FormInstance } from "antd";
import moment, { Moment } from "moment";

export function validateFormNumber(form: FormInstance, nameField: string) {
  if (form.getFieldValue(nameField) == '') {
    return Promise.reject(new Error('Поле обязательно для заполнения'));
  }
  if (
    Number.isNaN(Number(form.getFieldValue(nameField))) ||
    Number(form.getFieldValue(nameField)) <= 0
  ) {
    return Promise.reject(new Error('Значение должно быть больше нуля'));
  }
  return Promise.resolve();
}

export function validateFormMaxMinNumber(form: FormInstance, nameField: string, { max, min, placeholderErrorMax, placeholderErrorMin }: { max: number, min: number, placeholderErrorMax: string, placeholderErrorMin?: string }) {
  if (placeholderErrorMin && form.getFieldValue(nameField) == '') {
    return Promise.reject(new Error('Поле обязательно для заполнения'));
  }
  if (placeholderErrorMin && Number.isNaN(Number(form.getFieldValue(nameField))) || Number(form.getFieldValue(nameField)) <= min) {
    return Promise.reject(new Error(placeholderErrorMin));
  }
  if (Number(form.getFieldValue(nameField)) > max) {
    return Promise.reject(new Error(placeholderErrorMax));
  }
  return Promise.resolve();
}

export function validateNumber(form: any, field: string) {
  if (Number(form.getFieldValue(field)) > 0) {
    return Promise.resolve();
  }
  return Promise.reject('Поле обязательно для заполнения');
}


export function validateStartDate(form: FormInstance) {
  const startDate: Moment | null = form.getFieldValue('start_date');
  const endDate: Moment | null = form.getFieldValue('end_date');

  if (!startDate) {
    return Promise.reject(new Error('Поле обязательно для заполнения'));
  }
  if (moment(startDate).isSameOrAfter(moment(endDate), 'day')) {
    return Promise.reject(new Error('Дата начала не может быть больше или равна дате окончания'));
  }
  return Promise.resolve();
}

export function validateEndDate(form: FormInstance) {
  const startDate: Moment | null = form.getFieldValue('start_date');
  const endDate: Moment | null = form.getFieldValue('end_date');

  if (!endDate) {
    return Promise.reject(new Error('Поле обязательно для заполнения'));
  }
  if (moment(endDate).isSameOrBefore(moment(startDate), 'day')) {
    return Promise.reject(new Error('Дата окончания не может быть меньше или равна дате начала'));
  }
  return Promise.resolve();
}
