import { getWData } from '../../table_extends/getWData';

export function closeModalDots() {
  if (getWData().cellOpen?.isModalAdd) {
    console.log('closeModalDots');
    getWData()!.cellOpen!.isModalAdd = false;
    getWData().setRefresh(Math.random());
  }

  if (getWData().cellOpen?.isModalContragent) {
    console.log('closeModalDots');
    getWData()!.cellOpen!.isModalContragent = false;
    getWData().setRefresh(Math.random());
  }

  if (getWData().cellOpen?.isModalApproveBudget) {
    console.log('closeModalDots');
    getWData()!.cellOpen!.isModalApproveBudget = false;
    getWData().setRefresh(Math.random());
  }

  if (getWData().cellOpen?.isModalSendApprove) {
    console.log('closeModalDots');
    getWData()!.cellOpen!.isModalSendApprove = false;
    getWData().setRefresh(Math.random());
  }
}
