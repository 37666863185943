import { useQuery } from "react-query";
import { api } from "../shared/api_client";

export type ProjectListStatus = | "on_work"
  | "approved"
  | "rejected"
  | "approved_cpm"
  | "to_pay"
  | "sent_cb"
  | "transferred_sgc"
  | "paid";

export const useProjectList = (status: ProjectListStatus) => {
  const {
    data: projectList,
    error: projectListError,
    isLoading: projectListLoading,
  } = useQuery({
    queryKey: ['projectList', status],
    queryFn: () => api.invoices.projectsList({ status: status }).then((e) => e.data),
  })

  return {
    projectList,
    projectListError,
    projectListLoading,
  };
}
