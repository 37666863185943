import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Input, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { directoryApi } from '../../shared/api_client';
import LayoutComponent from '../../shared/layout_component';
import { ColumnsType } from 'antd/es/table';
import { delay } from '../../shared/delay';
import { measureUnitListColumns } from './MeasureUnitListColumns';
import { useMeasureUnits } from "../../actions/useMeasureUnitList";
import tokenActions, { AccessItem } from "../../actions/tokenActions";
import { getHeaderComponentHeight } from '../../shared/header_component';

const { confirm } = Modal;


const MeasureUnitList: FunctionComponent<{}> = ( props ) => {
    const { measureUnits, isLoadingMeasureUnits, errorMeasureUnits, measureUnitClearCache, measureUnitsPaginate } = useMeasureUnits({page: 1, per_page: 100000});
    const { t } = useTranslation();
    const [ isOpenPopover, setIsOpenPopover ] = useState<{ id: string }>( { id: '' } );
    const [ processSave, setProcessSave ] = useState<{ id: string }[]>( [] );

    const [ localValue, setLocalValue ] = useState<{ id: string, title: string, key: string }[]>( [] );

    useEffect( () => {
        setLocalValue( measureUnits.map( ( item, index ) => ({
                key: `${ index + 1 }`,
                id: `${ item.id }`,
                title: `${ item.title }`,
            }) ),
        );
    }, [ measureUnits ] );

    useEffect( () => {
        function onClick( event: MouseEvent ) {
            const target = event.target as HTMLElement;
            if ( target.closest( '.direction-cell-dots__dots' ) == null ) {
                setIsOpenPopover( { id: '' } );
            }
        }

        window.addEventListener( 'click', onClick );
        return () => window.removeEventListener( 'click', onClick );
    }, [] );

    (window as any).regionsList = {
        localValue,
        processSave,
        isOpenPopover,
        measureUnits,
    };

    function handleEditMeasureUnit( id: string ) {
        if ( tokenActions.directoryMeasureUnits == AccessItem.EDIT || tokenActions.directoryMeasureUnits == AccessItem.FULL ) {
            const region = measureUnits.find( r => r.id == id );
            let title = region?.title ?? ''

            const modal = Modal.confirm( {
                className: 'modal_direction',
                title: 'Редактирование единицы измерения',
                icon: null,
                content: <div>
                    <Input
                        placeholder={ 'Название единицы измерения' }
                        autoFocus
                        defaultValue={ region?.title }
                        onChange={ e => {
                            title = e.target.value
                        } }
                    />
                </div>,
                footer: <div className={ 'modal_btns' }>
                    <Button type={ 'link' } onClick={ () => {
                        modal.destroy();
                    } }>Отмена</Button>
                    <Button type={ 'primary' } size={ 'small' } onClick={ async () => {
                        await directoryApi.measureUnit.measureUnitUpdate( id, { title } );
                        await delay( 100 );
                        measureUnitClearCache();
                        modal.destroy();
                    } }>Ок</Button>
                </div>,
            } );
        }
    }

    const columns: ColumnsType<{ id: string, key: string, title: string }> = measureUnitListColumns( {
        measureUnits,
        measureUnitClearCache,
        onEdit: handleEditMeasureUnit,
    } );
    return <>
        <LayoutComponent
            title={ 'Справочник единиц измерения' }
            className="RegionsList DirectionList"
            onAction={ ( action ) => {
                if ( action == 'create_measure_unit' ) {
                    let title = '';
                    const modal = Modal.confirm( {
                        className: 'modal_direction',
                        title: 'Создание новой единицы измерения',
                        icon: null,
                        content: <>
                            <Input placeholder={ 'Название единицы измерения' } autoFocus
                                   onChange={ e => title = e.target.value }
                            />
                        </>,
                        footer: <div className={ 'modal_btns' }>
                            <Button type={ 'link' } onClick={ () => {
                                modal.destroy();
                            } }>Отмена</Button>
                            <Button type={ 'primary' } size={ 'small' } onClick={ async () => {
                                await directoryApi.measureUnit.measureUnitCreate( { title } );
                                await delay( 100 );
                                measureUnitClearCache();
                                modal.destroy();
                            } }>Ок</Button>
                        </div>,
                    } );

                }
            } }
        >
            <div className="MeasureUnit__wrapper Direction__wrapper">
                <Table
                    className={ 'measure_unit-table direction-table' }
                    rowKey="key"
                    columns={ columns }
                    dataSource={ localValue }
                    pagination={false}
                    onRow={ ( record ) => ({
                        onClick: () => {
                            const id: string = record.id;
                            handleEditMeasureUnit( id )
                        },
                    }) }
                    scroll={{ y: window.innerHeight - getHeaderComponentHeight() - 60 }}
                />

            </div>
        </LayoutComponent>

    </>;
};

export default MeasureUnitList;
