import {FunctionComponent, useEffect, useState} from 'react';
import {ContragentModalManyForm, IAddContracts} from '../../../../Models/ContragentForm';
import {Col, Form, Input, Row} from 'antd';
import {TableRecord} from '../TableRecord';
import {sizes} from '../../../../shared/sizes';
import {DeleteOutlined} from '@ant-design/icons';
import {validateFormMaxMinNumber, validateNumber} from "../utils/validateFormNumber";
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../../i18n/localeKeys";
import { numberFormatter } from '../../../../shared/dateFormat';


const ModalAddContractForm: FunctionComponent<{
    contractId: string,
    item: IAddContracts,
    record: TableRecord,
    onDone: (item: IAddContracts) => void,
    onRemove: () => void
}> = (props) => {
    const [form] = Form.useForm<ContragentModalManyForm>();
    const { t } = useTranslation();

    const [baseValues, setBaseValues] = useState<ContragentModalManyForm>({
        title: props.record.cns_title ?? '',
        size: props.contractId ? `${props.item.size}` : `${props.record.cns_budget_plan_size}`,
        price: props.contractId ? `${props.item.price}` : `${props.record.cns_budget_plan_price}`,
        sum_wat: props.contractId ? `${props.item.sum_wat}` : `${Number(props.record.cns_budget_plan_size) * Number(props.record.cns_budget_plan_price)}`,
    });

    const sizeNotContracted = Number(props.record.cns_budget_plan_size) - props.record.cns_contracts.reduce((acc, item) => acc + Number(item.size ?? '0'), 0);
    const sumNotContracted = Number(props.record.cns_budget_plan_sum_wat) - props.record.cns_contracts.reduce((acc, item) => acc + Number(item.sum_wat ?? '0'), 0);

    useEffect(() => {
        setTimeout(() => update('init'), 100);
    }, [])


    async function update(type:  'init' | 'upd') {
        const res = {
            ...props.item,
            size: Number(form.getFieldValue('size') ?? '0'),
            price: Number(form.getFieldValue('price') ?? '0'),
            sum_wat: Number(form.getFieldValue('sum_wat') ?? '0')
        };
        if (type === 'upd') {
            props.onDone(res)
        }
    }
    const isMobile = window.outerWidth < sizes.mobile;

    (window as any).modalContragentManyForm = {
        props,
        isMobile,
    }

    return <div className={'modal-add-contract-form'}>
        <div className={'modal-add-contract-form__title'}>
            <div>
                {props.record.cns_title}
            </div>

            <DeleteOutlined onClick={props.onRemove}/>
        </div>

        {/*{JSON.stringify(props.item.title)}*/}
        <Form<ContragentModalManyForm>
            form={form}
            name="basic"
            style={window.innerWidth > sizes.mobile ? {maxWidth: sizes.mobile} : {}}
            initialValues={baseValues}
            onFinish={() => update}
            autoComplete="off"
            labelCol={{span: 12}}
            labelAlign={'right'}
            colon={true}
        >
            <div className={'modal-add-contract-form__info'}>
                <div><span>Бюджет-план:</span> {numberFormatter(props.record.cns_budget_plan_size, {isTwoDigit: true})} {props.record.cns_ed_izm} × {numberFormatter(props.record.cns_budget_plan_price, {isTwoDigit: true})} руб = {numberFormatter(props.record.cns_budget_plan_sum_wat, {isTwoDigit: true})} руб</div>
                <div><span>Осталось:</span> {numberFormatter(sizeNotContracted, {isTwoDigit: true})} {props.record.cns_ed_izm} × {numberFormatter(props.record.cns_budget_plan_price, {isTwoDigit: true})} руб = {numberFormatter(sumNotContracted, {isTwoDigit: true})} руб</div>
            </div>

            <Row gutter={ isMobile ? {sm: 20} : undefined}>
                <Col span={isMobile ? 11 : 12}>
                    <Form.Item<ContragentModalManyForm>
                        label={t(localeKeys.table.add.contract.volume)}
                        name="size"
                        rules={[
                            {
                                message: t(localeKeys.shared.requiredField2),
                                validator: () => validateNumber(form, 'size'),
                            },
                            {
                                validator: () => validateFormMaxMinNumber(form, 'size', {
                                    max: Number.parseFloat(props.record.cns_budget_plan_size),
                                    min: 0,
                                    placeholderErrorMax: t(localeKeys.table.add.contract.limitExceeded),
                                }),
                            },
                        ]}
                    >
                        <Input
                            data-test={'INPUT_CONTRACT_SIZE'}
                            onChange={() => {
                                setBaseValues({...baseValues, size: form.getFieldValue('size')});
                                form.setFieldValue('sum_wat', `${Number(form.getFieldValue('size') ?? '0') * Number(form.getFieldValue('price') ?? '0')}`);
                                update('upd')
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 12} offset={ isMobile ? 2 : 0}>
                    <Form.Item<ContragentModalManyForm>
                        label={t(localeKeys.table.add.contract.price)}
                        name="price"
                        rules={[
                            {
                                message: t(localeKeys.shared.requiredField2),
                                validator: () => validateNumber(form, 'price'),
                            },
                            {
                                validator: () => validateFormMaxMinNumber(form, 'price', {
                                    max: Number.parseFloat(props.record.cns_budget_plan_price),
                                    min: 0,
                                    placeholderErrorMax: t(localeKeys.table.add.contract.limitExceeded),
                                }),
                            },
                        ]}
                    >
                        <Input
                            data-test={'INPUT_CONTRACT_PRICE'}
                            onChange={() => {
                                setBaseValues({...baseValues, price: form.getFieldValue('price')});
                                form.setFieldValue('sum_wat', `${Number(form.getFieldValue('size') ?? '0') * Number(form.getFieldValue('price') ?? '0')}`);
                                update('upd')
                            }
                        }/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item<ContragentModalManyForm>
                label={t(localeKeys.table.add.contract.sum)}
                labelCol={{span: 6}}
                name="sum_wat"
                rules={[
                    {
                        message: t(localeKeys.shared.requiredField2),
                        validator: () => validateNumber(form, 'sum_wat'),
                    },
                    {
                        validator: () => validateFormMaxMinNumber(form, 'sum_wat', {
                            max: Number.parseFloat(props.record.cns_budget_plan_sum_wat),
                            min: 0,
                            placeholderErrorMax: t(localeKeys.table.add.contract.limitExceeded),
                        }),
                    }
                ]}
            >
                <Input
                    data-test={'INPUT_CONTRACT_SUM'}
                    onChange={() => {
                    setBaseValues({...baseValues, sum_wat: form.getFieldValue('sum_wat')});
                    update('upd')
                }}/>
            </Form.Item>
        </Form>
    </div>;
};

export default ModalAddContractForm;
