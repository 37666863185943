import { Button, Input, Modal, notification } from 'antd';
import { directoryApi } from '../../shared/api_client';
import { delay } from '../../shared/delay';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Directory } from '../../ApiDirectory';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { stopEvent } from "../../shared/stopEvent";
import tokenActions, { AccessItem } from "../../actions/tokenActions";

export function regionsListColumns(props: {
    regions: Directory[],
    regionClearCache: () => void,
    onEdit: (id: string) => void
}): ColumnsType<{ id: string, key: string, title: string }> {
    return [
        {
            title: <div className={'direction-title'}>
                <span>Наименование</span>
            </div>,
            dataIndex: 'title',
            width: 500,
        },
        {
            render: (text, record) => {
                if (tokenActions.directoryRegions == AccessItem.EDIT || tokenActions.directoryRegions == AccessItem.FULL) {
                    return <div className={ 'wrap_btns' }>
                        <EditOutlined />
                        <DeleteOutlined onClick={ ( e ) => {
                            stopEvent( e )
                            const modal = Modal.confirm( {
                                title: 'Удаление региона',
                                className: 'modal_direction',
                                icon: null,
                                content: <>
                                    <span>Вы действительно хотите удалить регион?</span>
                                </>,
                                footer: <div className={ 'modal_btns' }>
                                    <Button type={ 'link' } onClick={ () => {
                                        modal.destroy();
                                    } }>Отмена</Button>
                                    <Button type={ 'primary' } size={ 'small' } onClick={ async ( e ) => {
                                        try {
                                            await directoryApi.regions.regionsDelete( record.id );
                                        } catch ( e ) {
                                            console.error( { e } )
                                            notification.error( {
                                                message: (e as any)?.error?.message?.toString() ?? ''
                                            } )
                                        }
                                        await delay( 100 );
                                        props.regionClearCache();
                                        modal.destroy();
                                    } }>Ок</Button>
                                </div>,
                            } );
                        } }/>
                    </div>;
                }

                return <></>
            },
        },
    ];
}
