import Cookies from 'js-cookie';

export function getValueStore(name: string) {
  if (!window.location.host.includes(process.env.REACT_APP_BASE_DOMAIN ?? '')) {
    return localStorage.getItem(name);
  }

  return Cookies.withAttributes({ domain: process.env.REACT_APP_BASE_DOMAIN }).get(name);
}

export function setValueStore(name: string, value: string) {
  if (!window.location.host.includes(process.env.REACT_APP_BASE_DOMAIN ?? '')) {
    return localStorage.setItem(name, value);
  }

  Cookies.set(name, value, { domain: process.env.REACT_APP_BASE_DOMAIN });
}

export function removeValueStore(name: string) {
  if (!window.location.host.includes(process.env.REACT_APP_BASE_DOMAIN ?? '')) {
    return localStorage.removeItem(name);
  }

  Cookies.remove(name, { domain: process.env.REACT_APP_BASE_DOMAIN });
}
