import moment from "moment/moment";
import {TableRecord, TableRecordContract} from "../TableRecord";

export enum TypeModalEnum {
  contracts = 'contracts',
  bills = 'bills',
  way_bills = 'way_bills',
  invoices = 'invoices',
}

export function getTypeModalEnum(text: string) {
  switch (text) {
    case 'contracts':
      return TypeModalEnum.contracts
    case 'bills':
      return TypeModalEnum.bills
    case 'way_bills':
      return TypeModalEnum.way_bills
    case 'way':
      return TypeModalEnum.way_bills
    case 'invoices':
      return TypeModalEnum.invoices
    default:
      return TypeModalEnum.contracts
  }
}

export function getErrorFieldPrefics(type: TypeModalEnum) {
  let preficsErrorField = 'fact_budget-'
  if (type == 'invoices') {
    preficsErrorField = 'payment_invoices-'
  } else if (type == 'way_bills') {
    preficsErrorField = 'delivered_completed-'
  } else if (type == 'bills') {
    preficsErrorField = 'bills-'
  }

  return preficsErrorField
}

export function getTypeError(errorOver: 'contract_over_error' | 'bill_over_error' | 'waybill_over_error' | 'invoice_over_error' | ''): TypeModalEnum {
  if (errorOver == 'contract_over_error') {
    return TypeModalEnum.contracts
  } else if (errorOver == 'bill_over_error') {
    return TypeModalEnum.bills
  } else if (errorOver == 'waybill_over_error') {
    return TypeModalEnum.way_bills
  } else if (errorOver == 'invoice_over_error') {
    return TypeModalEnum.invoices
  }

  return TypeModalEnum.contracts
}

export function getTimeClosed(createdAt: string, closedAt: string) {
  const timeClosed = moment(closedAt, 'YYYY-MM-DDTHH:mm:ss');
  const timeCreated = moment(createdAt, 'YYYY-MM-DDTHH:mm:ss');

  // console.log('timeClosed', timeClosed.toISOString(), timeCreated.toISOString());


  const timeDiffHours = timeClosed.diff(timeCreated, 'hour');

  if (timeDiffHours <= 0) {
    const timeDiffMinutes = timeClosed.diff(timeCreated, 'minute');

    if (timeDiffMinutes <= 0) {
      return 'менее минуты';
    }
    return `${timeDiffMinutes} минут`;
  }
  if (timeDiffHours > 24) {
    const timeDiffDays = timeClosed.diff(timeCreated, 'day');
    return `${timeDiffDays} дней`;
  }

  return `${timeDiffHours} часа`;

}

export function getDateDiff(date: string ) {
  const timeClosed = moment();
  const timeCreated = moment(date, 'YYYY-MM-DDTHH:mm:ss');

  return timeCreated.diff(timeClosed, 'days');
}


export function mapContragentLine(record: TableRecord, items: TableRecordContract[]) {
  return items.map(e => ({...e, line: record}))
}

export function getNamePropsWithData(typeModal: TypeModalEnum): 'cns_contracts' | 'cns_invoices' | 'cns_way_bills' | 'cns_bills' {
  let namePropsWithData: 'cns_contracts' | 'cns_invoices' | 'cns_way_bills' | 'cns_bills' = 'cns_contracts';
  if (typeModal == 'invoices') {
    namePropsWithData = 'cns_invoices';
  } else if (typeModal == 'way_bills') {
    namePropsWithData = 'cns_way_bills';
  } else if (typeModal == 'bills') {
    namePropsWithData = 'cns_bills';
  }

  return namePropsWithData;
}

export function getTitleEntity(typeModal: TypeModalEnum, titleFor: boolean): string {
  let titleEntity = 'Контракт';
  if (titleFor) {
    titleEntity = 'контракта';
    if (typeModal == 'invoices') {
      titleEntity = 'счета';
    } else if (typeModal == 'way_bills') {
      titleEntity = 'ТТН';
    } else if (typeModal == 'bills') {
      titleEntity = 'счет - фактуры';
    }
  } else {
    if (typeModal == 'invoices') {
      titleEntity = 'Счет';
    } else if (typeModal == 'way_bills') {
      titleEntity = 'ТТН';
    } else if (typeModal == 'bills') {
      titleEntity = 'Счет - фактура';
    }
  }

  return titleEntity;
}
