import { useQuery, useQueryClient } from 'react-query';
import { directoryApi } from '../shared/api_client';

export function useRegionList() {
  const client = useQueryClient();

  const {
    data: regions,
    error: regionsError,
    isLoading: regionsLoading,
  } = useQuery({
    queryKey: [`regions_list`],
    queryFn: async () => {
      const res = await directoryApi.regions.regionsList({per_page: 100000, sort_by: 'title', sort_desc: 0} as any);

      // items.sort((a, b) => {
      //   if ((a.order ?? '') > (b.order ?? '')) {
      //     return -1;
      //   } else {
      //     return 1;
      //   }
      // })

      return res.data.data ?? []
    }
  });

  function clearCache() {
    client.invalidateQueries([`regions_list`]);
  }

  return {
    regions: regions ?? [],
    regionsError,
    regionsLoading,
    regionClearCache: clearCache,
  };
}
