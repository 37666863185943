import { useQuery } from 'react-query';
import { directoryApi } from '../shared/api_client';

export function useContragentsTags(params: { search: string }) {
  const {
    data: contragentsTags,
    error: contragentsTagsError,
    isLoading: contragentsTagsLoading,
  } = useQuery({
    queryKey: ['contragents_tags', `contragents_tags_${params.search}`],
    queryFn: () => {
      return directoryApi.helpers.contractorTagsList({ search: params.search }).then((e) => e.data);
    },
  });

  return {
    contragentsTags,
    contragentsTagsLoading,
    contragentsTagsError,
  };
}
