import { FunctionComponent } from 'react';

const TableLoading: FunctionComponent<{ baseLoading?: boolean; animate?: boolean; offsetTop: boolean }> = (props) => {
    return (
        <div
            className={`table_loading ${props.baseLoading ? 'base' : ''} ${props.animate ? 'animate' : ''} ${props.offsetTop ? 'offsetTop' : ''}`}
        >
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
            <div className="line">
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
                <div className="loading-item"></div>
            </div>
        </div>
    );
};

export default TableLoading;
