import {useState} from "react";
import {db, doRunContragent, doRunDocuments, requestDatabase} from "../indexedb";

export function useDb() {
  const [isInit, setIsInit] = useState<'' | 'process' | 'opened'>('');
  const id = window.location.pathname.split('/passport/').pop();

  async function initDb() {
    if (db !== undefined) {
      return;
    }

    setIsInit('process');
    await requestDatabase(id ?? '');
    await doRunContragent()
    await doRunDocuments()
    setIsInit('opened');
  }

  return {
    initDb,
    isInit: isInit || db ? 'opened' : '',
  }
}
