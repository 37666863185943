import { RecordStatus } from '../canvas_table/components/TableRecord';
import { updateBudgetFact } from './calcLocalValueRecord';
import { getResultRows } from './getResultRows';
import { getWData } from './getWData';
import { getPassportCache } from './loadData';

export function firstCalc() {
  for (let row of getWData().rows3) {
    updateBudgetFact(row);
  }

  for (let index in getWData().rows2) {
    const id = getWData().rows2[index].cns_group_id;
    const group = getWData().rows2[index];
    const budgetPlanSumWat = getWData()
      .rows3.filter((e) => e.cns_parent_id2 === id)
      .reduce((a, b) => a + Number(b.cns_budget_plan_sum_wat || '0'), 0);
    const budgetFaktSumWat = getWData()
      .rows3.filter((e) => e.cns_parent_id2 === id)
      .reduce((a, b) => a + Number(b.cns_budget_fakt_sum_wat || '0'), 0);

    getWData().rows2[index].cns_budget_plan_sum_wat = `${budgetPlanSumWat}`;
    getWData().rows2[index].cns_budget_fakt_sum_wat = `${budgetFaktSumWat}`;
    getWData().rows2[index].cns_budget_plan_price = '';
    getWData().rows2[index].cns_budget_plan_size = '';

    const items = getWData().rows3.filter((e) => e.cns_group_id === group.cns_group_id);

    const canActionApprove = !!items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW);
    const hasApproved = items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);

    group.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;

    if (hasApproved) {
      group.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
    }

    if (canActionApprove) {
      group.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
    }
  }

  for (let index in getWData().rows) {
    const section = getWData().rows[index];
    const id = getWData().rows[index].cns_section_id;
    const budgetPlanSumWat = getWData()
      .rows2.filter((e) => e.cns_parent_id2 === id)
      .reduce((a, b) => a + Number(b.cns_budget_plan_sum_wat || '0'), 0);
    const budgetFaktSumWat = getWData()
      .rows2.filter((e) => e.cns_parent_id2 === id)
      .reduce((a, b) => a + Number(b.cns_budget_fakt_sum_wat || '0'), 0);

    getWData().rows[index].cns_budget_plan_sum_wat = `${budgetPlanSumWat}`;
    getWData().rows[index].cns_budget_fakt_sum_wat = `${budgetFaktSumWat}`;
    getWData().rows[index].cns_budget_plan_price = '';
    getWData().rows[index].cns_budget_plan_size = '';

    const items = getWData().rows2.filter((e) => e.cns_section_id === section.cns_section_id);

    const canActionApprove = !!items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW);
    const hasApproved = items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);

    section.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;

    if (hasApproved) {
      section.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
    }

    if (canActionApprove) {
      section.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
    }
  }

  getWData().resultRowValues['cns_budget_plan_sum_wat'] =
    `${getWData().rows.reduce((a, b) => a + Number(b.cns_budget_plan_sum_wat || '0'), 0)}`;
  getWData().resultRowValues['cns_budget_fakt_sum_wat'] =
    `${getWData().rows.reduce((a, b) => a + Number(b.cns_budget_fakt_sum_wat || '0'), 0)}`;

  const totalFar = getWData().rows.reduce((a, b) => a + Number(b.cns_budget_plan_far2 || '0'), 0);
  getWData().resultRowValues['cns_budget_plan_far'] =
    `${getWData().rows.reduce((a, b) => a + Number(b.cns_budget_plan_far || '0'), 0)}`;
  getWData().resultRowValues['cns_budget_plan_far2'] = `${totalFar > 99 ? 100 : totalFar}`;

  getWData().resultRowValues['cns_budget_fakt_sum_wat'] =
    `${getWData().rows.reduce((a, b) => a + Number(b.cns_budget_fakt_sum_wat || '0'), 0)}`;
  getWData().resultRowValues['cns_budget_fakt_far'] =
    `${getWData().rows.reduce((a, b) => a + Number(b.cns_budget_fakt_far || '0'), 0)}`;

  getWData().resultRows = getResultRows();

  setTimeout(() => {
    if (getWData().grid) {
      getWData().grid.records = getWData().resultRows;
    }
  }, 100);
}
