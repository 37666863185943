import moment from "moment";
import {IApiContragent, IFormFile} from "./ContragentForm";
import {DocumentType} from "../indexedb";
import { TableRecord, TableRecordContract } from '../screens/canvas_table/components/TableRecord';
import { getPassportCache } from '../screens/table_extends/loadData';
import { localeKeys } from '../i18n/localeKeys';
import dateFormatter from '../shared/dateFormat';


export interface InvoiceFormNomenclatures {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  section_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  type_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  nomenclature_id: string;
  /**
   * Цена за ед.
   * @format float
   */
  price: number;
  /**
   * Объем
   * @format float
   */
  volume: number;
  /**
   * Сумма
   * @format float
   */
  amount: number;
  title?: string;
}
export interface IAddInvoice {
    /**
     * Идентификатор подрядчика
     * @format uuid
     */
    contractor_id?: string;
    /**
     * Идентификатор документа контракта
     * @format uuid
     */
    contract_id?: string;
    /**
     * Дата счета
     * @format date
     * @example "02.02.2024"
     */
    invoice_date: string;
    /**
     * Номер счета
     * @example "К10019аа/222"
     */
    invoice_number: string;
    /**
     * Общий ID контракта для нескольких номенклатур.
     */
    document_id: string;
    nomenclatures: InvoiceFormNomenclatures[];
    files: IFormFile[]
}

export function getEmptyIAddInvoice(): IAddInvoice {
    return {
        document_id: '',
        contractor_id: undefined,
        contract_id: undefined,
        invoice_date: moment().format('YYYY-MM-DD'),
        invoice_number: '',
        nomenclatures: [],
        files: [],
    }
}

export interface IAddInvoiceLine {
  key: number | string;
  title: string;
  id: string;
  date: string;
  price: number,
  size: number,
  sum_wat: number,
}

export const emptyIAddInvoiceLine = (): IAddInvoiceLine => ({
  id: '',
  date: moment().format('YYYY-MM-DD'),
  price: 0,
  size: 0,
  sum_wat: 0,
  title: '',
  key: Date.now(),
});

export interface IApiInvoice {
  id?: string,
  invoice_date: string;
  invoice_number: string;
  contractor_id: string;
  contract_id: string;
  nomenclatures: {
    project_id: string;
    section_id: string;
    type_id: string;
    nomenclature_id: string;
    price: number;
    volume: number;
    amount: number;
  }[];
  file_ids: string[];
  files?: { id: string, name: string }[]
}

export interface IApiInvoice2 extends IApiInvoice {
  contractor: string;
  status?: string;
}

export function mapNomenclature2InvoiceNomenclature(item: TableRecord, contract: TableRecordContract, t: (val: string, params: any) => string): InvoiceFormNomenclatures {
  return {
    project_id: getPassportCache().id ?? '',
    section_id: item.cns_section_id,
    type_id: item.cns_group_id,
    nomenclature_id: item.cns_nomenclature_id ?? '',
    price: Number(contract.price ?? 0),
    volume: Number(contract.size ?? 0),
    amount: Number(contract.sum_wat ?? 0),
    title: t(localeKeys.table.add.invoice.titleDoc, {
      doc_number: contract.doc_number,
      doc_date: dateFormatter(contract.doc_date),
      sum_wat: contract.sum_wat,
    })
  }
}
