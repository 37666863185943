import React, {FunctionComponent, useState} from "react";
import {Button, Popover} from "antd";

const ModalWarning: FunctionComponent<{
    btn: JSX.Element,
    text: string,
    onOk: () => void,
    onCancel: () => void
}> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return <Popover
        open={isOpen}
        onOpenChange={setIsOpen}
        content={<div className={'modal-add-contract__warning'}>
        <div>
            <img src={'/canvas_table/icon_warning2.svg'} alt={'warning'}/>
        </div>
        <div>
            <div className={'modal-add-contract__warning__title'}>Ошибка</div>
            <div className={'modal-add-contract__warning__text'}>{props.text}</div>
            <div className={'modal-add-contract__warning__btns'}>
                <Button size={'small'} type={'link'} onClick={() => {
                    setIsOpen(false);
                    props.onCancel();
                }}> Отмена </Button>
                <Button size={'small'} type={'primary'} onClick={props.onOk}> Подтвердить </Button>
            </div>
        </div>
    </div>} trigger="hover">{props.btn}</Popover>
}

export default ModalWarning
