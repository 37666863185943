import { Button } from 'antd';
import { FunctionComponent, useState } from 'react';
import { ProjectDetail } from '../../../../ApiEpo';
import { api } from '../../../../shared/api_client';
import { getWData } from '../../../table_extends/getWData';

const ModalImportFile: FunctionComponent<{ passport?: ProjectDetail }> = (props) => {
    const [fileName, setFileName] = useState('Выбрать файл');
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');

    function onImportXlsx() {
        setError('');
        (document.querySelector('#btn_select_file') as any)?.click();
    }

    async function doImport() {
        setSending(true);
        try {
            if (getWData().cellOpen?.record.cns_row_type == 'section') {
                await api.excel.sectionsImportCreate({
                    project_id: props.passport?.id ?? '',
                    section_id: getWData().cellOpen?.record?.cns_section_id ?? '',
                    file: (window as any).selectFile,
                });
            }
            if (getWData().cellOpen?.record.cns_row_type == 'work_type') {
                await api.excel.workTypesImportCreate({
                    project_id: props.passport?.id ?? '',
                    section_id: getWData().cellOpen?.record?.cns_section_id ?? '',
                    type_id: getWData().cellOpen?.record?.cns_group_id ?? '',
                    file: (window as any).selectFile,
                });
            }
            (document.querySelector('.ant-modal-close-x') as any)?.click();
            // await getWData().loadDataPassport()
        } catch (e: any) {
            console.log({ e });
            setError(e?.error?.message ?? 'Импорт не выполнен');
            // notification.error({message: e?.error?.message ?? 'Импорт не выполнен'})
        }
        setSending(false);
    }

    function onSelectFile(event: any) {
        setError('');
        setFileName(event.target.files[0].name);
        (window as any).selectFile = event.target.files[0];
    }

    return (
        <div className={'ModalImportFile'}>
            <input id={'btn_select_file'} hidden={true} type={'file'} onChange={onSelectFile}></input>

            <div className={'selectFile'} onClick={onImportXlsx}>
                <span className={'label'}>Файл XLSX</span>

                <span className={'fileName'}>{fileName}</span>
            </div>

            {!fileName && <div className={'line_1'}>Внимание! Имеющиеся данные будут перезаписаны</div>}

            <Button disabled={error != ''} loading={sending} type={'primary'} onClick={doImport}>
                Импортировать
            </Button>

            {error != '' && <div className="line_error">{error}</div>}
        </div>
    );
};

export default ModalImportFile;
