import { ListGrid } from 'cheetah-grid';
import { getOffsetX } from '../../../shared/getOffsetX';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { allowSendApproveBudget } from '../allowEditCell';
import { getWData } from '../getWData';
import { IconsSizes } from '../table_body/icons_sizes';

export function checkBtnSendApprove(
  event: MouseEvent,
  col: number,
  row: number,
  grid: ListGrid<TableRecord>,
  record: TableRecord,
) {
  if (col == 2 && record.cns_row_type != '' && allowSendApproveBudget(record)) {
    let left = getOffsetX(event) - getWData().grid.getCellRect(col, row).left + grid.scrollLeft;
    return left > 30 - IconsSizes.btn_send_approve && left < 40;
  }

  return false;
}
