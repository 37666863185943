import { ListGrid } from 'cheetah-grid';
import { getOffsetX } from '../../../shared/getOffsetX';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { getWData } from '../getWData';
import { IconsSizes } from '../table_body/icons_sizes';

export function checkOpenModalAdd(
  event: MouseEvent,
  grid: ListGrid<TableRecord>,
  row: number,
  col: number,
  record: TableRecord,
) {
  if (record.cns_row_type == '' || col != 2) return false;

  let left = getOffsetX(event) - getWData().grid.getCellRect(col, row).left + grid.scrollLeft;

  if (getWData().hasAllowMoveOfApprove) {
    return left > 60 - IconsSizes.btn_send_approve && left < 80;
  }

  return left > 30 - IconsSizes.btn_send_approve && left < 60;
}
