import {useQuery, useQueryClient} from 'react-query';
import { api } from '../shared/api_client';
import {getLoadDataInfo, getPassportCache} from "../screens/table_extends/loadData";

export function useContractsAgreement() {
  const queryClient = useQueryClient()

  const {
    data: contractsAgreement,
    isLoading: isLoadingContractsAgreement,
    isError: isErrorContractsAgreement,
    error: errorContractsAgreement,
  } = useQuery({
    queryKey: [`contracts-agreement`],
    queryFn: async () => {
      const id = getLoadDataInfo()?.id ?? getPassportCache().id ?? '';
      const res = await api.contracts.contractsList({
        project_id: id,
        per_page: 10000,
        // status: 'signing'
      });

      return {
        data: res.data.data?.filter(record => id),
        // data: res.data.data ?? [],
        meta: res.data.meta,
      }
    },
  });

  function clearCache() {
    queryClient.invalidateQueries([`contracts-agreement`]);
  }

  return {
    contractsAgreement,
    isLoadingContractsAgreement,
    isErrorContractsAgreement,
    errorContractsAgreement,
    clearCacheContractsAgreement: clearCache,
  };
}
