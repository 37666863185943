import moment from "moment";
import {IFormFile} from "./ContragentForm";


export interface BillsFormNomenclatures {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  section_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  type_id: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  nomenclature_id: string;
  /**
   * Цена за ед.
   * @format float
   */
  price: number;
  /**
   * Объем
   * @format float
   */
  volume: number;
  /**
   * Сумма
   * @format float
   */
  amount: number;
}
export interface IBills {
    /**
     * Идентификатор подрядчика
     * @format uuid
     */
    contractor_id?: string;
    /**
     * Контракт
     * @format uuid
     */
    contract_id?: string;
    /**
     * Дата счета
     * @format date
     * @example "02.02.2024"
     */
    bill_date: string;
    /**
     * Номер счета
     * @example "К10019аа/222"
     */
    bill_number: string;
    /**
     * Общий ID контракта для нескольких номенклатур.
     */
    document_id: string;
    wayBillIds: string[];
    wayBillNomenclatures: BillsFormNomenclatures[];
    files: IFormFile[]
}

export function getEmptyIABills(): IBills {
    return {
        document_id: '',
        contractor_id: undefined,
        bill_date: moment().format('YYYY-MM-DD'),
        bill_number: '',
        wayBillIds: [],
        wayBillNomenclatures: [],
        files: [],
    }
}

export interface IABillsLine {
  key: number | string;
  title: string;
  id: string;
  date: string;
  price: number,
  size: number,
  sum_wat: number,
}

export const emptyIABillsLine = (): IABillsLine => ({
  id: '',
  date: moment().format('YYYY-MM-DD'),
  price: 0,
  size: 0,
  sum_wat: 0,
  title: '',
  key: Date.now(),
});

export interface IApiBills {
  id?: string,
  bill_date: string;
  bill_number: string;
  contractor_id: string;
  contract_id: string;
  nomenclatures: {
    project_id: string;
    section_id: string;
    type_id: string;
    nomenclature_id: string;
    price: number;
    volume: number;
    amount: number;
  }[];
  file_ids: string[];
  files?: { id: string, name: string }[]
  waybill_document_ids: string[]
}

export interface IApiBills2 extends IApiBills {
  contractor: string;
}

