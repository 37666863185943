import { CloseOutlined } from '@ant-design/icons';
import confirm from 'antd/es/modal/confirm';
import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { api } from '../../../../shared/api_client';
import { sizes } from '../../../../shared/sizes';
import { refreshRowStatusDown, refreshStatusRecords } from '../../../table_extends/approveRow';
import { refreshFar } from '../../../table_extends/calcLocalValueRecord';
import { firstCalc } from '../../../table_extends/firstCalc';
import { getModalDotsItems, getResultRows } from '../../../table_extends/getResultRows';
import { CellOpen, getWData } from '../../../table_extends/getWData';
import { getPassportCache } from '../../../table_extends/loadData';
import { pasteRows } from '../../../table_extends/paste_rows';
import { syncData } from '../../../table_extends/syncData';
import { getTableBody, TableMode } from '../../../table_extends/table_body/table_body';
import { getTableHeaders, tableHeaders } from '../../../table_extends/table_header/table_headers';
import { closeModalDots } from '../../actions/closeModalDots';
import { getEmptyTableRecord, RecordStatus, TableRecord } from '../TableRecord';
import { disAppproveBudget } from './modal_approve_budget';
import ModalImportFile from './modal_import_file';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../../i18n/localeKeys';
import { Button, Checkbox, Input, Modal, notification, Tree, TreeProps } from 'antd';
import { doConnectNomenclatures, doConnectSections, doConnectWorkTypes } from '../../actions/doConnectSection';
import { uniq } from 'lodash';
import ModalConnectNomenclaturesContent from './ModalConnectNomenclaturesContent';

export enum ModalAddRowOptions {
    SHOW_HISTORY = 'SHOW_HISTORY',
    IMPORT_XLSX = 'IMPORT_XLSX',
    DOWNLOAD_XLSX = 'DOWNLOAD_XLSX',
    ADD_SECTION = 'ADD_SECTION',
    ADD_GROUP = 'ADD_GROUP',
    ADD_NOMENCLATURA = 'ADD_NOMENCLATURA',
    ADD_NOMENCLATURA2 = 'ADD_NOMENCLATURA2',
    ADD_NOMENCLATURA3 = 'ADD_NOMENCLATURA3',
    ADD_NOMENCLATURA3_NEXT = 'ADD_NOMENCLATURA3_NEXT',
    ADD_NOMENCLATURA4_NEXT = 'ADD_NOMENCLATURA4_NEXT',
    ADD_REMO_ROW3 = 'ADD_REMO_ROW3',
    REMOVE_SECTION = 'REMOVE_SECTION',
    REMOVE_GROUP = 'REMOVE_GROUP',
    REMOVE_NOMENCLATURA = 'REMOVE_NOMENCLATURA',
    HISTORY = 'HISTORY',
    RENAME_GROUP = 'RENAME_GROUP',
    RENAME_SECTION = 'RENAME_SECTION',
    UNLOCK_ROW = 'UNLOCK_ROW',
}

const ModalAddRow: FunctionComponent<{
    keyModal: any;
    cell?: any;
    onAddNomecklatura: () => void;
    onAddNomecklaturaMany: () => void;
    onAddNomecklaturaPaste: () => void;
    onAddNomecklaturaPasteNext: () => void;
    onAddNomecklaturaNext: () => void;
    onAddSection: () => void;
    onAddGroup: () => void;
    onAddDemoRow3: () => void;
    onRemoveRow: () => void;
    onRename: () => void;
    onHistory: () => void;
    options: ModalAddRowOptions[];
    onDownloadXlsx: () => void;
    onImportXlsx: () => void;
    unlockNomenclatura: () => void;
}> = (props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const canvasItem = document.querySelector('canvas')?.getBoundingClientRect();

    const modalW = document.querySelector('.ModalAddRow')?.clientWidth;

    const spaceRight =
        window.innerWidth - ((getWData().cellOpen?.cell?.right ?? 0) - getWData().grid.scrollLeft) > (modalW ?? 200);

    let y =
        getWData().cellOpen?.cell?.top + getWData().cellOpen?.cell?.height - window.scrollY - getWData().grid.scrollTop;

    let x = getWData().cellOpen?.cell?.left + getWData().cellOpen?.cell?.width - getWData().grid?.scrollLeft;

    if (y > window.innerHeight - 50 * props.options.length - 140) {
        y = y - 50 * props.options.length - 30;
    }

    if (y < -75) {
        y = -75;
    }

    if (!spaceRight) {
        x = x - (modalW ? modalW + 40 : 200);
    }

    if (x < 0) {
        x = 0;
    }

    if (window.innerWidth < sizes.mobile) {
        y += window.scrollY * window.devicePixelRatio;
        console.log('y', y);
    }

    useEffect(() => {
        setTimeout(() => {
            setRefresh(1);
        }, 10);
    }, []);

    if (!props.options.length) return <></>;

    return (
        <div
            className="ModalAddRow"
            key={props.keyModal}
            style={{
                top: `${y + (canvasItem?.top ?? 0)}px`,
                left: `${x + (canvasItem?.left ?? 0)}px`,
                opacity: refresh ? 1 : 0,
            }}
        >
            {props.options.includes(ModalAddRowOptions.SHOW_HISTORY) && (
                <span data-test="HISTORY" onClick={props.onHistory}>
                    {t(localeKeys.table.menu.show_history)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.DOWNLOAD_XLSX) && (
                <span data-test="DOWNLOAD_XLSX" onClick={() => props.onDownloadXlsx()}>
                    {t(localeKeys.table.menu.download_xlsx)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.IMPORT_XLSX) && (
                <span data-test="IMPORT_XLSX" onClick={() => props.onImportXlsx()}>
                    {t(localeKeys.table.menu.import_xlsx)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_SECTION) && (
                <span data-test="ADD_SECTION" onClick={() => props.onAddSection()}>
                    {t(localeKeys.table.menu.add_section)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_GROUP) && (
                <span data-test="ADD_GROUP" onClick={() => props.onAddGroup()}>
                    {t(localeKeys.table.menu.add_group)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_NOMENCLATURA) && (
                <span data-test="ADD_NOMENCLATURA" onClick={() => props.onAddNomecklatura()}>
                    {t(localeKeys.table.menu.add_nomenclature)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_NOMENCLATURA2) && (
                <span data-test="ADD_NOMENCLATURA2" onClick={() => props.onAddNomecklaturaMany()}>
                    {t(localeKeys.table.menu.add_nomenclatures)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_NOMENCLATURA3) && (
                <span data-test="ADD_NOMENCLATURA3" onClick={() => props.onAddNomecklaturaPaste()}>
                    {t(localeKeys.table.menu.paste_from_clipboard)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_NOMENCLATURA3_NEXT) && (
                <span data-test="ADD_NOMENCLATURA3_NEXT" onClick={() => props.onAddNomecklaturaPasteNext()}>
                    {t(localeKeys.table.menu.paste_from_clipboard_below)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_NOMENCLATURA4_NEXT) && (
                <span data-test="ADD_NOMENCLATURA4_NEXT" onClick={() => props.onAddNomecklaturaNext()}>
                    {t(localeKeys.table.menu.add_nomenclature_below)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.ADD_REMO_ROW3) && (
                <span data-test="ADD_REMO_ROW3" onClick={() => props.onAddDemoRow3()}>
                    {t(localeKeys.table.menu.add_demo_data)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.RENAME_SECTION) && (
                <span data-test="RENAME_SECTION" onClick={() => props.onRename()}>
                    {t(localeKeys.table.menu.rename_row)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.RENAME_GROUP) && (
                <span data-test="RENAME_GROUP" onClick={() => props.onRename()}>
                    {t(localeKeys.table.menu.rename_row)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.REMOVE_SECTION) && (
                <span data-test="REMOVE_SECTION" onClick={() => props.onRemoveRow()}>
                    {t(localeKeys.table.menu.remove_row)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.REMOVE_GROUP) && (
                <span data-test="REMOVE_GROUP" onClick={() => props.onRemoveRow()}>
                    {t(localeKeys.table.menu.remove_row)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.REMOVE_NOMENCLATURA) && (
                <span data-test="REMOVE_NOMENCLATURA" onClick={() => props.onRemoveRow()}>
                    {t(localeKeys.table.menu.remove_row)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.UNLOCK_ROW) && (
                <span data-test="UNLOCK_ROW" onClick={() => props.unlockNomenclatura()}>
                    {t(localeKeys.table.menu.allow_editing)}
                </span>
            )}
            {props.options.includes(ModalAddRowOptions.HISTORY) && (
                <span data-test="HISTORY" onClick={props.onHistory}>
                    {t(localeKeys.table.menu.history_cell)}
                </span>
            )}
        </div>
    );
};

export const ModalAddRowBind: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <ModalAddRow
            keyModal={`ModalAddRow_${getWData().refresh}`}
            options={getModalDotsItems(getWData().cellOpen?.record).filter((e) => !!e) as ModalAddRowOptions[]}
            cell={getWData().cellOpen?.cell}
            onAddNomecklatura={() => connectNomenclatures(getWData().cellOpen?.record?.cns_section_id ?? '', getWData().cellOpen?.record?.cns_group_id ?? '')}
            onAddGroup={() => connectNomenclatures(getWData().cellOpen?.record?.cns_section_id ?? '')}
            onAddSection={() => connectNomenclatures()}
            onAddDemoRow3={() => {
                const res = prompt(t(localeKeys.table.menu.modal_many.how_count_rows));
                if (res) {
                    addNomecklatura({ demoRow3: Number(res), manyRow3: 0 });
                }
            }}
            onAddNomecklaturaMany={() => {
                // confirm( {
                //     className: 'modal-many-lines',
                //     title: t( localeKeys.table.menu.modal_many.select_count_rows ),
                //     width: 500,
                //     icon: <></>,
                //     content: <ModalManyLines closeModalDots={ closeModalDots }/>,
                //     closeIcon: <CloseOutlined/>,
                //     closable: true,
                //     okType: 'primary',
                //     okText: '',
                //     onOk: async () => {
                //     },
                //     onCancel( params ) {
                //         console.log( 'Cancel', params );
                //     },
                // } );
            }}
            onRemoveRow={() => {
                confirm({
                    title: getWData().cellOpen?.record.cns_title ?? t(localeKeys.table.menu.remove_row2),
                    icon: <></>,
                    content:
                        getWData().cellOpen?.record.cns_row_type == 'section'
                            ? t(localeKeys.table.menu.remove_section)
                            : getWData().cellOpen?.record.cns_row_type == 'work_type'
                                ? t(localeKeys.table.menu.remove_group)
                                : t(localeKeys.table.menu.remove_nomenclature),
                    closeIcon: <CloseOutlined />,
                    closable: true,
                    okText: t(localeKeys.table.menu.remove_row),
                    okType: 'danger',
                    onOk: async () => {
                        switch (getWData().cellOpen?.record?.cns_row_type) {
                            case 'section':
                                removeSection(closeModalDots);
                                await api.projects.entityDelete({
                                    project_id: getPassportCache().id ?? '',
                                    entity_ids: [getWData().cellOpen?.record.cns_section_id!],
                                    entity_type: 'section',
                                });
                                break;
                            case 'work_type':
                                removeGroup(closeModalDots);
                                await api.projects.entityDelete({
                                    project_id: getPassportCache().id ?? '',
                                    entity_ids: [getWData().cellOpen?.record.cns_group_id!],
                                    entity_type: 'work_type',
                                    replacement: {
                                        section_id: getWData().cellOpen?.record.cns_section_id,
                                    },
                                });
                                break;
                            case 'nomenclature':
                                removeNomenklature(closeModalDots);
                                await api.projects.entityDelete({
                                    project_id: getPassportCache().id ?? '',
                                    entity_ids: [getWData().cellOpen?.record.cns_nomenclature_id!],
                                    entity_type: 'nomenclature',
                                    replacement: {
                                        section_id: getWData().cellOpen?.record.cns_section_id,
                                        work_type_id: getWData().cellOpen?.record.cns_group_id,
                                    },
                                });
                                break;
                        }
                        // syncData.deleteRow(getWData().cellOpen?.record!);
                    },
                    onCancel(params) {
                        console.log('Cancel', params);
                    },
                });
            }}
            onRename={() => renameRow()}
            onAddNomecklaturaPaste={async () => {
                if (getWData().cellOpen?.record.cns_level != 2) return;


                const res = await pasteRows();

                console.log(res);

                try {
                    if (res.length) {
                        closeModalDots();

                        const section_id = getWData().cellOpen?.record.cns_section_id ?? '';
                        const group_id = getWData().cellOpen?.record.cns_group_id ?? '';

                        const nomenclatures = getWData().db.nomenclaturesStruct
                            .filter(e => e.section_id == section_id && e.type_id == group_id)
                            .filter(e =>
                                e.row_type == 'nomenclature' && res.find(newLine => {
                                    return newLine[0] == e.title;
                                }));

                        const isAppendedNomenclatures = getWData().rows3.filter(nomenclature => nomenclatures.find(e => e.nomenclature_id == nomenclature.cns_nomenclature_id));
                        const notAppendedNomenclatures = nomenclatures.filter(nomenclature => !isAppendedNomenclatures.find(e => e.cns_nomenclature_id == nomenclature.nomenclature_id));

                        const notFoundNomenclatures = res.filter(newLine => !nomenclatures.find(nomenclature => nomenclature.title == newLine[0]));

                        if (notFoundNomenclatures.length) {
                            notification.error({
                                message: `Не найдены номенклатуры`,
                                description: `${notFoundNomenclatures.join(', ')}`,
                            });
                        }

                        if (isAppendedNomenclatures.length) {
                            notification.error({
                                message: `Номенклатуры уже добавлены`,
                                description: `${isAppendedNomenclatures.map(e => e.cns_title).join(', ')}`,
                            });
                        }

                        if (notAppendedNomenclatures.length) {
                            await doConnectNomenclatures(
                                section_id,
                                group_id,
                                notAppendedNomenclatures.map(e => e.nomenclature_id ?? ''),
                            );

                            notification.success({
                                message: `Добавлены номенклатуры`,
                                description: `${notAppendedNomenclatures.map(e => e.title).join(', ')}`,
                            });
                        }
                    }
                } catch (e) {
                    notification.error({
                        message: `Ошибка при добавлении номенклатуры`,
                        description: `${e}`,
                    });
                }

                // addNomecklatura( {
                //     demoRow3: 0,
                //     manyRow3: 0,
                //     fromClipboard: res,
                // } );
            }}
            onAddNomecklaturaPasteNext={async () => {
                if (getWData().cellOpen?.record.cns_level != 3) return;

                const res = await pasteRows();

                console.log({ res });

                console.error(`Метод не реализован`);

                // addNomecklatura( {
                //     demoRow3: 0,
                //     manyRow3: 0,
                //     fromClipboard: res,
                //     insertAfter: true,
                // } );
            }}
            onAddNomecklaturaNext={() => {
                // addNomecklatura( {
                //     demoRow3: 0,
                //     manyRow3: 0,
                //     insertAfter: true,
                // } );
            }}
            onDownloadXlsx={async () => {
                closeModalDots();
                let blob: any;
                if (getWData().cellOpen?.record.cns_row_type == 'section') {
                    const res = await api.excel.sectionsExportList({
                        project_id: getPassportCache().id ?? '',
                        section_id: getWData().cellOpen?.record?.cns_section_id ?? '',
                    });
                    blob = await res.blob();
                }
                if (getWData().cellOpen?.record.cns_row_type == 'work_type') {
                    const res = await api.excel.workTypesExportList({
                        project_id: getPassportCache().id ?? '',
                        section_id: getWData().cellOpen?.record?.cns_section_id ?? '',
                        type_id: getWData().cellOpen?.record?.cns_group_id ?? '',
                    });
                    blob = await res.blob();
                }

                if (!blob) return;

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `${getWData().cellOpen?.record?.cns_title}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                // or you know, something with better UX...
            }}
            onImportXlsx={async () => {
                console.log('onImportXlsx');
                closeModalDots();
                confirm({
                    className: 'modal-import-file',
                    title: t(localeKeys.table.menu.import.import_xlsx),
                    width: 500,
                    icon: <></>,
                    content: <ModalImportFile passport={getPassportCache()} />,
                    closeIcon: <CloseOutlined />,
                    closable: true,
                    okType: 'primary',
                    okText: t(localeKeys.table.menu.import.import),
                    onOk: async () => {
                    },
                    onCancel(params) {
                        console.log('Cancel', params);
                    },
                });
            }}
            unlockNomenclatura={() => {
                closeModalDots();
                refreshRowStatusDown(getWData().cellOpen?.record!, 'unlock');
                refreshStatusRecords();
                syncData.unlockBudgetPlan(getWData().cellOpen?.record!);

                setTimeout(() => {
                    getWData().grid.layout = {
                        header: getTableHeaders(),
                        body: getTableBody(TableMode.VIEW),
                    };

                    getWData().grid?.invalidate();
                }, 500);
            }}
            onHistory={() => {
                closeModalDots();
                getWData().cellOpen!.isModalHistory = true;
            }}
        />
    );
};

export async function addNomecklatura({
                                          demoRow3,
                                          manyRow3,
                                          fromClipboard,
                                          insertAfter,
                                      }: {
    demoRow3: number;
    manyRow3: number;
    fromClipboard?: string[][];
    insertAfter?: boolean;
} = {
    demoRow3: 0,
    manyRow3: 0,
}) {
    closeModalDots();
    let selected: string[] = [];
    const modal = Modal.confirm({
        title: 'Прикрепить номенклатуры',
        icon: <></>,
        closable: true,
        closeIcon: <CloseOutlined />,
        content: <>
            {getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'nomenclature' && e.type_id == getWData().cellOpen?.record.cns_group_id).map(item =>
                <div style={{ marginBottom: '10px' }}>
                    <Checkbox
                        value={selected.includes(item.nomenclature_id!)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                selected.push(item.nomenclature_id!);
                            } else {
                                selected = selected.filter(e => e != item.nomenclature_id);
                            }
                        }}
                        disabled={getWData().rows3.map(e => e.cns_nomenclature_id).includes(item.nomenclature_id!)}
                    /> &nbsp; {item.row_number}. &nbsp; {item.title}
                </div>)}
        </>,
        footer: <>
            <Button
                size={'small'}
                type={'primary'}
                onClick={async () => {
                    await doConnectNomenclatures(
                        getWData().cellOpen?.record.cns_section_id ?? '',
                        getWData().cellOpen?.record.cns_group_id ?? '',
                        selected,
                    );
                    modal.destroy();
                }}
            >
                Прикрепить выбранные
            </Button>
        </>,
    });

}

export function addNomecklatura2(
    {
        demoRow3,
        manyRow3,
        fromClipboard,
        insertAfter,
    }: {
        demoRow3: number;
        manyRow3: number;
        fromClipboard?: string[][];
        insertAfter?: boolean;
    } = {
        demoRow3: 0,
        manyRow3: 0,
    },
) {
    console.log(`addNomecklatura`, demoRow3, manyRow3, fromClipboard, insertAfter);
    const record = getWData().cellOpen?.record;
    let lastRow: TableRecord = record ?? getEmptyTableRecord();
    if (record?.cns_row_type == 'work_type') {
        if (!getWData().expandedLevel2.includes(`${record.cns_section_id}_${record.cns_group_id}`)) {
            getWData().expandedLevel2.push(`${record.cns_section_id}_${record.cns_group_id}`);
        }

        const newRow: TableRecord = {
            ...getEmptyTableRecord(),
            cns_id: `new_${Math.random()}`,
            cns_level: record.cns_level + 1,
            cns_row_type: 'nomenclature',
            cns_parent_id: `${record.cns_section_id}_${record.cns_group_id}`,
            cns_parent_id2: record.cns_group_id,
            cns_section_id: record.cns_section_id,
            cns_group_id: record.cns_group_id,
            cns_nomenclature_id: record.cns_nomenclature_id,
            cns_number: `${record.cns_number}.${getWData().rows3.filter((e) => e.cns_group_id === record.cns_group_id).length + 1}`,
            cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
            cns_title: null as any,
            cns_ed_izm: null as any,
        };

        if (demoRow3 || manyRow3 || fromClipboard?.length) {
            const newItems: TableRecord[] = [];
            for (let i = 0; i < (demoRow3 || manyRow3 || (fromClipboard?.length ?? 0)); i++) {
                newRow.cns_id = `new_${Math.random()}`;
                newRow.cns_number = `${record.cns_number}.${
                    getWData().rows3.filter((e) => e.cns_group_id === record.cns_group_id).length + 1
                }`;
                newRow.cns_title = null as any;

                if (demoRow3) {
                    // not supported
                }

                if (fromClipboard?.length) {
                    const row = fromClipboard?.[i];
                    if (row) {
                        newRow.cns_title = row[0];
                        if (row[1]) newRow.cns_ed_izm = row[1];
                        if (row[2]) newRow.cns_budget_plan_size = (row[2] ?? '').replace(',', '.');
                        if (row[3]) newRow.cns_budget_plan_price = (row[3] ?? '').replace(',', '.');
                    }
                }

                lastRow = newRow;

                getWData().rows3.push({ ...newRow });
                newItems.push({ ...newRow });
            }
            console.log({ newItems });
            syncData.createNomenclatures2(newItems, lastRow.cns_section_id ?? '', lastRow.cns_group_id ?? '');
        } else {
            lastRow = newRow;
            if (insertAfter) {
                const indexInsert = getWData()
                    .rows3.filter((e) => e.cns_group_id == record.cns_group_id)
                    .findIndex((e) => e.cns_id === record.cns_id);
                getWData().rows3.splice(indexInsert, 0, newRow);
                syncData.createNomeclatura(
                    newRow,
                    getWData().rows3.filter((e) => e.cns_group_id == record.cns_group_id)[indexInsert]
                        ?.cns_nomenclature_id ?? '',
                );
            } else {
                getWData().rows3.push(newRow);
                syncData.createNomeclatura(newRow);
            }
        }

        disAppproveBudget(record);
    }

    if (record?.cns_row_type == 'nomenclature' && insertAfter && !fromClipboard?.length) {
        const sectionIndex = getWData().rows.findIndex((e) => e.cns_section_id == record.cns_section_id);
        const groupIndex = getWData().rows2.filter((e) => e.cns_section_id == record.cns_section_id).findIndex((e) => e.cns_group_id == record.cns_group_id);
        const nomIndex = getWData().rows3.filter((e) => e.cns_group_id == record.cns_group_id).findIndex((e) => e.cns_nomenclature_id == record.cns_nomenclature_id);
        const number = `${sectionIndex + 1}.${groupIndex + 1}.${nomIndex + 2}`;

        const newRow: TableRecord = {
            ...getEmptyTableRecord(),
            cns_id: `new_${Math.random()}`,
            cns_level: 3,
            cns_row_type: 'nomenclature',
            cns_parent_id: record.cns_parent_id,
            cns_parent_id2: record.cns_parent_id2,
            cns_section_id: record.cns_section_id,
            cns_group_id: record.cns_group_id,
            cns_nomenclature_id: record.cns_nomenclature_id,
            cns_number: number,
            cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
            cns_title: null as any,
            cns_ed_izm: '',
        };

        const indexInsert = getWData().rows3.findIndex((e) => e.cns_id === record.cns_id) + 1;
        getWData().rows3.splice(indexInsert, 0, newRow);
        syncData.createNomeclatura(newRow, getWData().rows3[indexInsert]?.cns_nomenclature_id ?? '');
        lastRow = newRow;

        for (let i = indexInsert + 1; i < getWData().rows3.length; i++) {
            if (newRow.cns_group_id == getWData().rows3[i].cns_group_id) {
                getWData().rows3[i].cns_number = `${sectionIndex + 1}.${groupIndex + 1}.${nomIndex + (i - indexInsert) + 2}`;
            }
        }
    }

    if (record?.cns_row_type == 'nomenclature' && insertAfter && fromClipboard?.length) {
        const newItems: TableRecord[] = [];

        for (let i = 0; i < fromClipboard?.length; i++) {
            const newRow: TableRecord = {
                ...getEmptyTableRecord(),
                cns_id: `new_${Math.random()}`,
                cns_level: 3,
                cns_row_type: 'nomenclature',
                cns_parent_id: record.cns_parent_id,
                cns_section_id: record.cns_section_id,
                cns_group_id: record.cns_group_id,
                cns_nomenclature_id: record.cns_nomenclature_id,
                cns_number: `${record.cns_number}`,
                cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
                cns_title: null as any,
                cns_ed_izm: '',
            };

            const row = fromClipboard?.[i];
            if (row) {
                newRow.cns_title = row[0];
                if (row[1]) newRow.cns_ed_izm = row[1];
                if (row[2]) newRow.cns_budget_plan_size = (row[2] ?? '').replace(',', '.');
                if (row[3]) newRow.cns_budget_plan_price = (row[3] ?? '').replace(',', '.');
            }

            newItems.push({ ...newRow });
        }

        const indexInsert = getWData().rows3.findIndex((e) => e.cns_id === record.cns_id) + 1;
        getWData().rows3.splice(indexInsert, 0, ...newItems);
        syncData.createNomenclatures2(
            newItems,
            lastRow.cns_section_id ?? '',
            lastRow.cns_group_id ?? '',
            getWData().rows3[indexInsert]?.cns_nomenclature_id ?? '',
        );
        lastRow = newItems[newItems.length - 1];
    }

    getWData().resultRows = getResultRows();

    if (fromClipboard?.length) {
        getWData().grid.records = getWData().resultRows;
        closeModalDots();
        return;
    }

    let indexRow = getWData().resultRows.findIndex((row) => row.cns_id == lastRow.cns_id) + tableHeaders.length;
    indexRow = indexRow - ((demoRow3 ? demoRow3 - 1 : 0) || (manyRow3 ? manyRow3 - 1 : 0) || 0);

    console.log('openEditNewCell');

    getWData().grid.records = getWData().resultRows;
    getWData().resultRows = getResultRows();

    setTimeout(() => {
        const cell = getWData().grid.getCellRect(1, indexRow);
        console.log({
            cell,
            cellTop: getWData().grid.getCellRect(2, indexRow).top,
            scrollTop: getWData().grid.scrollTop,
        });

        if (
            getWData().grid.getCellRect(2, indexRow).top - getWData().grid.scrollTop < 100 ||
            getWData().grid.getCellRect(2, indexRow).top - getWData().grid.scrollTop >
            document.querySelector('.tableCanvas')!.clientHeight
        ) {
            getWData().grid.scrollTop = (cell?.top ?? 0) - 300;
        }
    }, 100);

    setTimeout(() => {
        getWData().cellOpen = {
            isModalAdd: false,
            isModalApproveBudget: false,
            isModalContragent: false,
            isModalSendApprove: false,
            isModalInvoice: false,
            isModalWayBill: false,
            isModalBill: false,
            isModalSettings: false,
            isModalHistory: false,
            isModalAddContract: false,
            ...(getWData().cellOpen ?? {}),
            record: lastRow,
            row: indexRow,
            col: 1,
            cell: getWData().grid.getCellRect(1, indexRow),
            openEdit: true,
            timeOpen: moment(),
            columnKey: '',
        };
        getWData().setRefresh(Math.random());
    }, 200);

    refreshFar();
    refreshStatusRecords();
    closeModalDots();
}

export async function addGroup() {
    closeModalDots();
    const modal = Modal.confirm({
        title: 'Прикрепить вид работ',
        icon: <></>,
        closable: true,
        closeIcon: <CloseOutlined />,
        footer: <></>,
        content: <>
            {getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'work_type' && e.section_id == getWData().cellOpen?.record.cns_section_id).map(item =>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    {item.row_number}. &nbsp; {item.title} <Button
                    size={'small'}
                    onClick={async () => {
                        await doConnectWorkTypes(item.section_id!, [item.type_id!]);
                        modal.destroy();
                    }}
                    disabled={getWData().rows2.map(e => e.cns_group_id).includes(item.type_id!)}
                >Прикрепить</Button>
                </div>)}
        </>,
    });
}

export function addGroup2() {
    console.log('addGroup');
    const record = getWData().cellOpen?.record ?? getEmptyTableRecord();

    if (!getWData().expandedLevel2.includes(`${record.cns_section_id}_${record.cns_group_id}`)) {
        getWData().expandedLevel2.push(`${record.cns_section_id}_${record.cns_group_id}`);
    }

    let groups = getWData().rows2.filter((e) => e.cns_section_id == record.cns_section_id);

    groups = groups.filter(e => e.cns_level == record.cns_level + 1);

    if (record.cns_row_type == 'work_type') {
        groups = groups.filter(e => e.cns_parent_id2 == record.cns_group_id);
    }

    let number = `${record.cns_number}.${groups.length + 1}`;

    console.log({ ...record });
    console.log({ number });

    const cns_id = `new_${Math.random()}`;

    const line: TableRecord = {
        ...getEmptyTableRecord(),
        cns_id: cns_id,
        cns_level: record.cns_level + 1,
        cns_row_type: 'work_type',
        cns_parent_id: `${record!.cns_section_id}_${record.cns_parent_id}`,
        cns_parent_id2: record.cns_section_id,
        cns_section_id: record!.cns_section_id,
        cns_group_id: record!.cns_group_id,
        cns_number: number,
        cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
    };

    if (!getWData().expandedLevel1.includes(record.cns_id)) {
        getWData().expandedLevel1.push(record.cns_id);
    }

    getWData().rows2.push(line);

    disAppproveBudget(record);

    closeModalDots();

    getWData().resultRows = getResultRows();
    getWData().grid.records = getWData().resultRows;

    const indexRow = getWData().resultRows.findIndex((e) => e.cns_number === number);
    console.log({ indexRow });

    setTimeout(() => {
        const cell = getWData().grid.getCellRect(1, indexRow);
        console.log({
            cell,
            cellTop: getWData().grid.getCellRect(2, indexRow).top,
            scrollTop: getWData().grid.scrollTop,
        });

        const s11 = getWData().grid.getCellRect(2, indexRow).top;
        const s12 = getWData().grid.scrollTop;
        const s21 = getWData().grid.getCellRect(2, indexRow + 1).bottom;
        const s22 = getWData().grid.scrollTop;
        const s23 = document.querySelector('.tableCanvas')!.clientHeight;

        if (s11 - s12 < 100 || s21 - s22 + 30 > s23) {
            console.log('do scroll');
            getWData().grid.scrollTop = (cell?.top ?? 0) - 300;
        }

        console.log(s11, s12, s21, s22, s23);
    }, 100);

    setTimeout(() => {
        if (number) {
            getWData().cellOpen!.record = getWData().resultRows[indexRow] ?? getEmptyTableRecord();
            getWData().cellOpen!.col = 1;
            getWData().cellOpen!.row = indexRow + tableHeaders.length;
            getWData().cellOpen!.cell = getWData().grid.getCellRect(1, indexRow + tableHeaders.length);

            setTimeout(() => {
                if (window.innerWidth < sizes.mobile) {
                    getWData().grid.scrollLeft = 90;
                    getWData().setRefresh(Math.random());
                }

                getWData().cellOpen!.onCancelEdit = (value) => {
                    syncData.createGroup(
                        { ...line, cns_title: value ? value : null },
                        record.cns_row_type == 'work_type',
                    );
                    getWData().cellOpen!.onCancelEdit = undefined;
                };

                getWData().cellOpen!.openEdit = true;
                getWData().textInput = '';
                getWData().setRefresh(Math.random());

                setTimeout(() => {
                    getWData().cellOpen!.openEdit = true;
                    getWData().textInput = '';
                    getWData().setRefresh(Math.random());
                }, 100);

                setTimeout(() => {
                    getWData().cellOpen!.openEdit = true;
                    getWData().textInput = '';
                    getWData().setRefresh(Math.random());
                }, 500);
            }, 100);
        }
    }, 100);
}

export function addSection() {
    closeModalDots();
    const modal = Modal.confirm({
        title: 'Прикрепить раздел',
        icon: <></>,
        closable: true,
        closeIcon: <CloseOutlined />,
        footer: <></>,
        content: <>
            {getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'section').map(item => <div
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                {item.row_number}. &nbsp; {item.title} <Button
                size={'small'}
                disabled={getWData().rows.map(e => e.cns_section_id).includes(item.section_id!)}
                onClick={async () => {
                    await doConnectSections([item.section_id!]);
                    modal.destroy();
                }}
            >
                Прикрепить
            </Button>
            </div>)}
        </>,
    });
}

export function addSection2() {
    console.log('addSection');
    const number = `${getWData().rows.length + 1}`;

    const line: TableRecord = {
        ...getEmptyTableRecord(),
        cns_id: `new_${Math.random()}`,
        cns_level: 1,
        cns_row_type: 'section',
        cns_parent_id: '',
        cns_number: number,
        cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
    };
    getWData().rows.push(line);

    closeModalDots();

    getWData().resultRows = getResultRows();
    getWData().grid.records = getWData().resultRows;

    const index = getWData().resultRows.findIndex((e) => e.cns_number === number);
    getWData().cellOpen!.record = getWData().resultRows[index] ?? getEmptyTableRecord();
    getWData().cellOpen!.col = 1;
    getWData().cellOpen!.row = index + tableHeaders.length;
    getWData().cellOpen!.cell = getWData().grid.getCellRect(1, index + tableHeaders.length);
    getWData().cellOpen!.timeOpen = moment();

    getWData().grid.scrollLeft = 0;

    getWData().cellOpen!.onCancelEdit = (value) => {
        if (value) {
            syncData.createSection({ ...line, cns_title: value });
        } else {
            getWData().rows = getWData().rows.filter((e) => e.cns_id !== line.cns_id);
            getWData().resultRows = getResultRows();
            getWData().grid.records = getWData().resultRows;
        }

        getWData().cellOpen!.onCancelEdit = undefined;
    };

    getWData().cellOpen!.openEdit = true;
    getWData().textInput = '';
    getWData().setRefresh(Math.random());
}

export function removeSection(closeModalDots: () => void) {
    const record = getWData().cellOpen?.record;
    if (record?.cns_row_type == 'section') {
        for (let i = getWData().rows2.length - 1; i >= 0; i--) {
            if (getWData().rows2[i].cns_parent_id === record.cns_id) {
                for (let j = getWData().rows3.length - 1; j >= 0; j--) {
                    if (getWData().rows3[j].cns_parent_id === getWData().rows2[i].cns_id) {
                        getWData().rows3.splice(j, 1);
                    }
                }

                getWData().rows2.splice(i, 1);
            }
        }

        const index = getWData().rows.findIndex((e) => e.cns_id === record.cns_id);
        if (index !== -1) {
            getWData().rows.splice(index, 1);
        }
    }

    closeModalDots();
    firstCalc();
    refreshFar();
    refreshStatusRecords();

    getWData().resultRows = getResultRows();
    getWData().grid.records = getWData().resultRows;
}

export function removeGroup(closeModalDots: () => void) {
    const record = getWData().cellOpen?.record;
    if (record?.cns_row_type == 'work_type') {
        for (let j = getWData().rows3.length - 1; j >= 0; j--) {
            if (getWData().rows3[j].cns_parent_id === record.cns_id) {
                getWData().rows3.splice(j, 1);
            }
        }

        const index = getWData().rows2.findIndex((e) => e.cns_id === record.cns_id);
        if (index !== -1) {
            getWData().rows2.splice(index, 1);
        }

        getWData().rows3 = getWData().rows3.filter((e) => e.cns_group_id !== record.cns_group_id);
    }

    closeModalDots();
    firstCalc();
    refreshFar();
    refreshStatusRecords();

    getWData().resultRows = getResultRows();
    getWData().grid.records = getWData().resultRows;
}

export function removeNomenklature(closeModalDots: () => void) {
    const record = getWData().cellOpen?.record;
    if (record?.cns_row_type == 'nomenclature') {
        const index = getWData().rows3.findIndex((e) => e.cns_id === record.cns_id);
        if (index !== -1) {
            getWData().rows3.splice(index, 1);
        }
    }

    closeModalDots();
    firstCalc();
    refreshStatusRecords();
    refreshFar();

    getWData().resultRows = getResultRows();
    getWData().grid.records = getWData().resultRows;
}

export function renameRow() {
    console.log('renameRow');
    const record = getWData().cellOpen?.record;
    if (record?.cns_row_type == 'section' || record?.cns_row_type == 'work_type') {
        if (window.innerWidth < sizes.tablet) {
            getWData().grid.scrollLeft = getWData().grid.getCellRect(0, 1).width;
        }

        setTimeout(() => {
            getWData().cellOpen = {
                ...getWData().cellOpen,
                openEdit: true,
                col: 1,
            } as CellOpen;

            getWData().textInput = record.cns_title || '';
            getWData().setRefresh(Math.random());
        });
    }

    closeModalDots();
}

export async function connectNomenclatures(section_id?: string, group_id?: string) {
    closeModalDots();


    const modal = Modal.confirm({
        className: 'modal-connect-nomenclatures',
        title: 'Справочник номенклатур',
        icon: <></>,
        closable: true,
        closeIcon: <CloseOutlined />,
        content: <ModalConnectNomenclaturesContent
            section_id={section_id}
            group_id={group_id}
            closeModal={() => modal.destroy()}
        />,
        footer: <></>,
    });

}

