import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessItem, getAccessValue } from '../actions/tokenActions';
import { localeKeys } from '../i18n/localeKeys';
import { apiAuth, resetApi } from '../shared/api_client';
import { removeValueStore, setValueStore } from '../shared/store';
import { useQueryParams } from '../shared/useQueryParams';

const AuthScreen = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [pass, setPass] = useState('');
    const [login, setLogin] = useState('');
    const query = useQueryParams();

    async function auth(values: { email: string; password: string }) {
        console.log('auth', values);
        try {
            setLoading(true);
            const res = await apiAuth.login.login({
                login: values.email,
                password: values.password,
            });
            if (res) {
                setValueStore('token', res.data.access_token ?? '');
                setValueStore('refresh_token', res.data.refresh_token ?? '');
                setValueStore('time_token', `${res.data.expires_in}`);

                removeValueStore('token_refresh_lock');
                resetApi(res.data.access_token);

                const clearRedirectDomain = (query['redirect'] ?? '')
                    .replace('http://', '')
                    .replace('https://', '')
                    .split('/')[0]
                    .split(':')[0];

                console.log({ clearRedirectDomain });

                if (
                    query['redirect'] &&
                    process.env.REACT_APP_ALLOW_REDIRECT?.split(',').includes(clearRedirectDomain)
                ) {
                    window.location.href = query['redirect'];
                } else {
                    const user = await apiAuth.me.getMe().then((e) => e.data);

                    console.log('e');

                    try {
                        if (
                            getAccessValue('EPO_PR_LIST') == AccessItem.NONE &&
                            getAccessValue('FIN_CONTR') == AccessItem.NONE &&
                            getAccessValue('CON_DIR') == AccessItem.NONE
                        ) {
                            setError('Нет доступа');
                            setLoading(false);
                            return;
                        }
                        if (
                            getAccessValue('EPO_PR_LIST') == AccessItem.NONE &&
                            getAccessValue('CON_DIR') != AccessItem.NONE
                        ) {
                            window.location.href = '/contragents';
                            setLoading(false);
                            return;
                        }
                    } catch (e) {
                        console.log(e);
                        setError('Нет доступа');
                        setLoading(false);
                        return;
                    }

                    if (user.telegram_id) {
                        window.location.href = '/';
                    } else {
                        window.location.href = '/profile';
                    }
                }
            }
        } catch (e) {
            setError('Неверный логин или пароль');
        }

        setLoading(false);
    }

    const [passwordVisible, setPasswordVisible] = useState(false);
    const { t } = useTranslation();

    return (
        <div className={`auth_screen ${error ? 'error' : ''}`}>
            <div className="auth_logo">
                <img src="/auth/auth_logo.svg" alt="logo" />
                <img src="/auth/logo2.svg" alt="logo" />
                <img src="/auth/logo2_sm.svg" alt="logo" />
            </div>
            <div className="auth_content">
                <div className="auth_title">{t(localeKeys.authScreen.title)}</div>

                <Form
                    className="form"
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        // if (!values.email.includes('@') || !values.email.includes('.')) {
                        //   setError("Введите корректный адрес почты");
                        //   return;
                        // }
                        auth(values);

                        return false;
                    }}
                    disabled={loading}
                >
                    <Form.Item name="email" label="Почта" validateStatus={error ? 'error' : undefined}>
                        <Input
                            onChange={() => {
                                setError('');
                                setLogin(form.getFieldValue('email'));
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="password" label="Пароль" validateStatus={error ? 'error' : undefined} help={error}>
                        <Input
                            type={passwordVisible ? 'text' : 'password'}
                            onChange={() => {
                                setError('');
                                setPass(form.getFieldValue('password'));
                            }}
                        />
                    </Form.Item>
                    {
                        <span
                            style={{ visibility: !pass ? 'hidden' : 'visible' }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            className={'iconPass'}
                        >
                            {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    }
                    {/*{error && <div className="error_message">{error}</div>}*/}
                    <Button type="primary" htmlType="submit" disabled={!!error || !pass || !login || loading}>
                        Войти
                    </Button>

                    <div className={'tooltip_form'}>
                        Для получения доступа или смены пароля необходимо обратиться <br /> к системному администратору
                        по адресу 991in@pnkgroup.ru
                    </div>
                </Form>
            </div>

            <div className="auth_footer">
                <img src="/auth/art_lebedev.svg" alt="Арт Лебедев" />
                Задизайнено в Студии Артемия Лебедева <br />
                Информация о сайте
            </div>
        </div>
    );
};

export default AuthScreen;
