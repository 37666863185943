import { Select } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../i18n/localeKeys';
import { User } from '../shared/Api';
import { apiAuth } from '../shared/api_client';
import LayoutComponent from '../shared/layout_component';
import { removeValueStore } from '../shared/store';
import {useUser} from "../actions/useUser";

const ProfileScreen: FunctionComponent<any> = () => {
    const [user, setUser] = useState<User | undefined>();
    const [isPending, setPending] = useState(false);
    const [isProcessConnect, setProcessConnect] = useState(false);

    const {isLoadingUser, userInfo, isErrorUser, clearCacheUser} = useUser();

    useEffect(() => {
        setUser(userInfo?.data)
    }, [userInfo]);

    function showTelegramAuth() {
        setPending(true);
        setProcessConnect(true);
        const element = document.querySelector('.widget_container');
        const script = document.createElement('script');
        if (element) {
            script.src = 'https://telegram.org/js/telegram-widget.js?22';
            script.async = true;
            script.setAttribute('data-telegram-login', `${process.env.REACT_APP_BOT_NAME}`);
            script.setAttribute('data-size', 'large');
            script.setAttribute('data-color', 'primary');
            script.setAttribute('data-request-access', 'write');
            script.setAttribute('data-onauth', 'onTelegramAuth(user)');

            element.appendChild(script);
        }

        const ids = setInterval(() => {
            if ((document.querySelector(`#telegram-login-${process.env.REACT_APP_BOT_NAME}`) as any)?._ready) {
                clearInterval(ids);
                setPending(false);
            }
        }, 100);
    }

    async function onTelegramAuth(user: { id: string; first_name: string; last_name: string; username?: string }) {
        console.log(user);
        setPending(true);
        await apiAuth.me.putMe({
            telegram_id: user.id,
            telegram_username: user.username,
            init_telegram: 1,
        });
        clearCacheUser();
        setPending(false);
        setProcessConnect(false);

        document.querySelector(`#telegram-login-${process.env.REACT_APP_BOT_NAME}`)?.remove();
    }

    async function disconnectTelegram() {
        setPending(true);
        await apiAuth.me.putMe({
            telegram_id: '',
        });
        clearCacheUser()
        setPending(false);
    }

    function logout() {
        removeValueStore('token');
        removeValueStore('refresh_token');
        removeValueStore('time_token');
        removeValueStore('wellBill');
        window.location.href = '/auth';
    }

    const { t } = useTranslation();

    (window as any).onTelegramAuth = onTelegramAuth;

    const { i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;

    return (
        <LayoutComponent title={t(localeKeys.profileScreen.title)} className="profile_screen" onAction={() => {}}>
            {user?.avatar_url && <img className="avatar" src={user?.avatar_url} alt={t(localeKeys.profileScreen.avatar)} />}
            {!user?.avatar_url && (
                <div className="avatar">
                    <img src="/images/avatar.svg" alt={t(localeKeys.profileScreen.avatar)} />
                    <div className={'user_fio'}>
                        {user?.full_name?.[0]}
                        {user?.full_name?.split(' ')[1]?.[0]}
                    </div>
                </div>
            )}
            <div className="user_info">
                <div className="full_name">{user?.full_name}</div>
                <div className="email">{user?.email}</div>
                <div className="widget_container">
                    {(isPending || isLoadingUser) && <div>{t(localeKeys.shared.loading)}...</div>}
                    {!isProcessConnect && (
                        <>
                            {!user?.telegram_id && (
                                <button className="telegram_auth" onClick={showTelegramAuth}>
                                    {t(localeKeys.profileScreen.bindTelegram)}
                                </button>
                            )}
                            {user?.telegram_id && (
                                <>
                                    {t(localeKeys.profileScreen.telegram)}: {user.telegram_username ? `@${user.telegram_username}` : user.telegram_id}
                                    <button className="telegram_auth_disconnect" onClick={disconnectTelegram}>
                                        {t(localeKeys.profileScreen.unbindTelegram)}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="widget_container">
                    <Select
                        value={activeLocale}
                        onChange={(lang) => {
                            i18n.changeLanguage(lang);
                        }}
                        options={[
                            { value: 'ru', label: 'Русский' },
                            { value: 'en', label: 'English' },
                        ]}
                    />
                </div>
            </div>
            <div className="logout" onClick={logout}>
                <img src="/layout/logout.svg" alt={ t(localeKeys.profileScreen.logout)} /> &nbsp; {t(localeKeys.profileScreen.logout)}
            </div>
        </LayoutComponent>
    );
};

export default ProfileScreen;
