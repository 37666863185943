import {CloseOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import cheetahGrid, {ListGrid} from 'cheetah-grid';
import type {BaseStyle as HeaderBaseStyle} from 'cheetah-grid/header/style/BaseStyle';
import {HeadersDefine} from 'cheetah-grid/list-grid/layout-map';
import type {ColumnStyleOption, HeaderStyleOption} from 'cheetah-grid/ts-types';
import {FunctionComponent, useEffect, useState} from 'react';
import {numberFormatter} from '../../../../shared/dateFormat';
import {sizes} from '../../../../shared/sizes';
import {approveRecords, disapproveRows} from '../../../table_extends/approveRow';
import {getWData} from '../../../table_extends/getWData';
import {syncData} from '../../../table_extends/syncData';
import {TableMode, getTableBody} from '../../../table_extends/table_body/table_body';
import {getTableHeaders} from '../../../table_extends/table_header/table_headers';
import {themeTable} from '../../../table_extends/table_theme';
import {RecordStatus, TableRecord} from '../TableRecord';

interface DataType {
    key: React.Key;
    id: string;
    title: string;
    plan_value: string;
}

function mapTableRow(record: TableRecord): DataType {
    let id = record.cns_id;

    switch (record.cns_row_type) {
        case 'project':
            id = record.cns_section_id;
            break;
        case 'section':
            id = record.cns_section_id;
            break;
        case 'work_type':
            id = record.cns_group_id ?? '';
            break;
        case 'nomenclature':
            id = record.cns_nomenclature_id ?? '';
            break;
    }

    return {
        key: `${id}_${Math.random()}`,
        id: id,
        title: record.cns_title || '',
        plan_value: `${record.cns_budget_plan_sum_wat ?? 0}`,
    };
}

const ModalApproveBudget: FunctionComponent<{
    keyModal: any;
    col: number;
    row: number;
    record: TableRecord;
    approve: (ids: string[]) => void;
    disapprove: (ids: string[]) => void;
    close: () => void;
}> = (props) => {
    const canvasItem = document.querySelector('canvas')?.getBoundingClientRect();
    const ModalApproveBudgetElement = document.querySelector('.ModalApproveBudget')?.getBoundingClientRect();

    const [data, setData] = useState<DataType[]>([]);
    const [grid, setGrid] = useState<ListGrid<DataType> | undefined>();

    const [keys, setKeys] = useState<string[]>([]);

    const cell = getWData().cellOpen?.cell;

    let y = cell?.top + cell?.height - window.scrollY - getWData().grid.scrollTop;

    let x = cell?.left + cell?.width - getWData().grid?.scrollLeft;

    const hasSpaceRight =
        document.querySelector('.grid-scrollable')!.getBoundingClientRect().right -
        (getWData().cellOpen!.cell.right - document.querySelector('.grid-scrollable')!.scrollLeft) >
        650;

    const hasSpaceBottom =
        document.querySelector('.grid-scrollable')!.getBoundingClientRect().bottom -
        (getWData().cellOpen!.cell.bottom - document.querySelector('.grid-scrollable')!.scrollTop) >
        450 + data.length * 20;

    useEffect(() => {
        console.log('useEffect', props.record.cns_id);
        console.time('map modal approve');

        let rows: DataType[] = []

        switch (props.record.cns_row_type) {
            case 'project':
                rows = getWData()
                    .rows.filter((e) => e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW)
                    .map((e) => mapTableRow(e));
                break;
            case 'section':
                rows = getWData()
                    .rows2.filter(
                        (e) =>
                            e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW &&
                            e.cns_section_id === props.record.cns_section_id,
                    )
                    .map((e) => mapTableRow(e));
                break;
            case 'work_type':
                rows =
                    getWData()
                        .rows3.filter(
                        (e) =>
                            e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW &&
                            e.cns_group_id === props.record.cns_group_id,
                    )
                        .map((e) => mapTableRow(e));
                break;
            case 'nomenclature':
                rows =
                    getWData()
                        .rows3.filter(
                        (e) =>
                            e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW &&
                            e.cns_id === props.record.cns_id,
                    )
                        .map((e) => mapTableRow(e));
                break;
        }

        setData(rows)

        console.timeEnd('map modal approve');
        console.log({data, rows});
    }, [props.record.cns_id]);

    const styleHeader: HeaderStyleOption | HeaderBaseStyle = {
        textAlign: 'right',
        padding: [0, 5, 0, 5],
        font: themeTable.fontHeaderBold,
        color: themeTable.fontColor,
    };
    const styleCell: ColumnStyleOption = {
        textOverflow: 'ellipsis',
        textAlign: 'right',
        padding: [0, 5, 0, 5],
    };

    function getHeaders(): HeadersDefine<DataType> {
        let columns: HeadersDefine<DataType> = [
            {
                field: () => '',
                caption: '',
                width: 40,
                style: {
                    padding: [0, 0, 0, 5],
                    borderColor: [
                        themeTable.borderColor,
                        themeTable.transparent,
                        themeTable.borderColor,
                        themeTable.borderColor,
                    ],
                    textOverflow: 'ellipsis',
                } as any,
                icon: (record: DataType) => {
                    return {
                        src: keys.includes(record.id) ? '/canvas_table/checkbox_2.svg' : '/canvas_table/checkbox_1.svg',
                    };
                },
                headerIcon: {
                    src: keys.length === data.length ? '/canvas_table/checkbox_2.svg' : '/canvas_table/checkbox_1.svg',
                },
                headerStyle: {
                    padding: [0, 0, 0, 5],
                },
            },
            {
                field: (record: DataType) => record.title,
                caption:
                    props.record.cns_row_type == 'project'
                        ? 'Раздел'
                        : props.record.cns_row_type == 'section'
                            ? 'Подраздел'
                            : props.record.cns_row_type == 'work_type'
                                ? 'Номенклатура'
                                : 'Номенклатура',
                style: {
                    padding: [0, 0, 0, 5],
                    textOverflow: 'ellipsis',
                    borderColor: [
                        themeTable.borderColor,
                        themeTable.borderColor,
                        themeTable.borderColor,
                        themeTable.transparent,
                    ],
                } as any,
                width: 'auto',
                headerStyle: {...styleHeader, textAlign: 'left'},
            },
            {
                field: (record: DataType) => numberFormatter(record.plan_value),
                caption: 'План',
                style: {
                    ...(styleCell as any),
                    borderColor: [
                        themeTable.borderColor,
                        themeTable.transparent,
                        themeTable.borderColor,
                        themeTable.transparent,
                    ],
                },
                minWidth: 145,
                headerStyle: styleHeader,
            },
        ].filter((_, index) => {
            if (props.record.cns_row_type == 'project') {
                return true;
            }

            return [0, 1, 3].includes(index);
        }) as any;

        return columns as any;
    }

    useEffect(() => {
        document.querySelector('#modalApproveCanvas .cheetah-grid')?.remove();
        const grid2 = new cheetahGrid.ListGrid({
            // Parent element on which to place the grid
            parentElement: document.querySelector('#modalApproveCanvas') as any,
            // Header definition
            header: getHeaders(),
            // Array data to be displayed on the grid
            // @ts-ignore
            // data: data,
            records: data,
            theme: {
                ...(cheetahGrid.themes.MATERIAL_DESIGN as any),
                underlayBackgroundColor: themeTable.bgColorEdit,
                borderColor: themeTable.borderColor,
                highlightBorderColor: () => {
                    return '';
                },
                selectionBgColor: 'transparent',
                defaultBgColor: themeTable.bgColorEdit,
                font: themeTable.font,
            },
            disableColumnResize: true,
        });

        setKeys(data.map((e) => e.id));

        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.MOUSEDOWN_CELL, (...args) => {
            const {col, row} = args[0];
            console.log(`col: ${col}, row: ${row}`);
            const currentRow = data[row - 1];
            if (currentRow) {
                let res = (window as any).modalApprove.keys;
                if (res.includes(currentRow.id)) {
                    res = res.filter((e: string) => e !== currentRow.id);
                } else {
                    res = [...res, currentRow.id];
                }

                setKeys(res);
                (window as any).modalApprove.keys = res;
            }

            if (row == 0 && col == 0) {
                if ((window as any).modalApprove.keys.length) {
                    setKeys([]);
                    (window as any).modalApprove.keys = [];
                } else {
                    setKeys(data.map((e) => e.id));
                    (window as any).modalApprove.keys = data.map((e) => e.id);
                }
            }
            grid2.invalidate();
        });

        setGrid(grid2);

        (window as any).modalApprove.grid = grid2;
    }, [data]);

    useEffect(() => {
        if (grid) {
            grid.header = getHeaders();
            grid.invalidate();
            (window as any).modalApprove.grid = grid;
        }
    }, [keys]);

    (window as any).modalApprove = {
        setKeys: setKeys,
        keys: keys,
        approve: props.approve,
        closeModal: closeModalApproveBudget,
        hasSpaceRight: hasSpaceRight,
        hasSpaceBottom: hasSpaceBottom,
        canvasItem: canvasItem,
        x: x,
        y: y,
        col: props.col,
        row: props.row,
        record: props.record,
        data: data,
        grid,
        ModalApproveBudgetElement,
    };

    return (
        <div
            key={props.keyModal}
            className="ModalApproveBudget"
            style={
                window.innerWidth > sizes.mobile
                    ? {
                        top: `${Math.max(y + (canvasItem?.top ?? 0) + (hasSpaceBottom ? 0 : -270 - data.length * 8), 0)}px`,
                        left: `${x + (canvasItem?.left ?? 0) + (hasSpaceRight ? 0 : -690)}px`,
                    }
                    : {
                        bottom: 0,
                        left: 0,
                    }
            }
        >
            <div className="title">
                {props.record.cns_row_type == 'project' && <>{keys.length} разделов</>}
                {props.record.cns_row_type == 'section' && <>{keys.length} подразделов</>}
                {props.record.cns_row_type == 'work_type' && <>{keys.length} номенклатур</>}
                {props.record.cns_row_type == 'nomenclature' && <>Номенклатура</>}

                <CloseOutlined onClick={props.close}/>
            </div>
            <div
                id="modalApproveCanvas"
                style={{height: Math.min(data.length * 40 + 75, window.innerHeight * 0.75)}}
            ></div>

            <div style={{marginTop: data.length > 5 ? 15 : 0}}>
                <Button
                    type="primary"
                    onClick={() => {
                        console.log('1111')
                        props.approve(data.filter((e) => keys.includes(e.id)).map((e) => e.id));
                    }}
                    data-test="btnApprove"
                >
                    Согласовать
                </Button>
                <Button
                    type="default"
                    onClick={() => props.disapprove(data.filter((e) => keys.includes(e.id)).map((e) => e.id))}
                    data-test="btnDisapprove"
                >
                    Отклонить
                </Button>
            </div>
        </div>
    );
};

export const ModalApproveBudgetBind: FunctionComponent<any> = () => {
    return (
        <ModalApproveBudget
            keyModal={`ModalApproveBudget_${getWData().refresh}`}
            col={getWData().cellOpen!.col}
            row={getWData().cellOpen!.row}
            record={getWData().cellOpen!.record}
            approve={(ids: string[]) => {
                console.log('approve', ids);
                approveRecords(ids);

                const sections = getWData().rows.filter(
                    (row) => ids.includes(row.cns_id ?? '') || ids.includes(row.cns_section_id ?? ''),
                );
                const sections_ids = getWData().rows2.filter((e) => ids.includes(`${e.cns_section_id}` ?? ''));

                const groups = getWData().rows2.filter((row) => ids.includes(row.cns_id ?? '') || ids.includes(row.cns_group_id ?? ''),);
                const groups_ids = getWData().rows3.filter((e) =>
                    ids.includes(`${e.cns_group_id}` ?? ''),
                );

                const items = getWData().rows3.filter(
                    (row) => ids.includes(row.cns_id ?? '') || ids.includes(row.cns_nomenclature_id ?? ''),
                );

                const ids2 = ids.map((e) => e.split('_')[2] ?? e.split('_')[1] ?? e.split('_')[0] ?? '');
                if (items.length) {
                    for (let id of items) {
                        syncData.approveBudgetPlan(ids2, id, 'approved');
                    }
                } else if (groups.length) {
                    for (let id of groups) {
                        syncData.approveBudgetPlan(
                            groups_ids
                                .filter((e) => e.cns_group_id === id.cns_group_id)
                                .map((e) => e.cns_group_id) as string[],
                            id,
                            'approved',
                        );
                    }
                } else if (sections.length) {
                    for (let id of sections) {
                        syncData.approveBudgetPlan(
                            sections_ids
                                .filter((e) => e.cns_section_id === id.cns_section_id)
                                .map((e) => e.cns_section_id) as string[],
                            id,
                            'approved',
                        );
                    }
                }

                closeModalApproveBudget();

                getWData().grid.layout = {
                    header: getTableHeaders(),
                    body: getTableBody(TableMode.VIEW),
                };

                getWData().grid?.invalidate();
            }}
            disapprove={(ids: string[]) => {
                console.log('disapprove', ids);

                disapproveRows(ids);

                getWData().grid.layout = {
                    header: getTableHeaders(),
                    body: getTableBody(TableMode.VIEW),
                };

                closeModalApproveBudget();
                getWData().grid.invalidate();
                syncData.approveBudgetPlan(
                    ids.map((e) => e.split('_')[2] ?? e.split('_')[1] ?? e.split('_')[0] ?? ''),
                    getWData().cellOpen?.record!,
                    'rejected',
                );
            }}
            close={() => {
                closeModalApproveBudget();
            }}
        />
    );
};

export function closeModalApproveBudget() {
    if (!getWData().cellOpen?.isModalApproveBudget) return;
    console.log('closeModalApproveBudget');

    if (getWData().cellOpen?.isModalApproveBudget) {
        getWData().cellOpen!.isModalApproveBudget = false;
    }
    getWData().setRefresh(Math.random());
}

export function disAppproveBudget(record: TableRecord) {
    console.log('disAppproveBudget', record);
    const indexRecord = getWData().rows3.findIndex((e) => e.cns_id === record.cns_id);
    if (
        indexRecord > -1 &&
        getWData().rows3.filter((record) => getWData().rows3[indexRecord].cns_parent_id === record.cns_parent_id).length
    ) {
        getWData().rows3[indexRecord].cns_status = RecordStatus.BUDGET_PLAN_REJECTED;

        const indexGroup = getWData().rows2.findIndex((e) => e.cns_id === record.cns_parent_id);
        if (indexGroup > -1) {
            getWData().rows2[indexGroup].cns_status = RecordStatus.BUDGET_PLAN_REJECTED;

            const indexSection = getWData().rows.findIndex(
                (e) => e.cns_id === getWData().rows2[indexGroup].cns_parent_id,
            );
            if (indexSection > -1) {
                getWData().rows[indexSection].cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
                getWData().resultRows[0].cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
                getWData().grid?.invalidate();
            }
        }
    }
}
