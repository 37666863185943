import {Dropdown, SelectProps} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {Project} from '../../ApiEpo';
import tokenActions, {AccessItem} from '../../actions/tokenActions';
import {localeKeys} from '../../i18n/localeKeys';
import {numberFormatter} from '../../shared/dateFormat';
import {stopEvent} from '../../shared/stopEvent';
import {getEmptyTableRecordContract} from "../canvas_table/components/TableRecord";

export const PassportMore: FunctionComponent<{
    onView: (id: string) => void;
    onEdit: (id: string) => void;
    onArchive: (id: string) => void;
    onRestore: (id: string) => void;
    status: 'active' | 'archive';
    record: Project;
    t: (key: string) => string;
}> = (props) => {
    if (
        tokenActions.listProjects == AccessItem.VIEW_ONLY ||
        tokenActions.listProjects == AccessItem.VIEW ||
        tokenActions.listProjects == AccessItem.VIEW_ALL
    ) {
        return (
            <span style={{cursor: 'pointer'}} onClick={(e) => stopEvent(e)}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'view',
                                label: 'Просмотр',
                            },
                        ],
                        onClick: ({key}) => {
                            if (key == 'view') {
                                props.onView(props.record.id ?? '');
                                return;
                            }
                        },
                    }}
                >
                    <img src="/canvas_table/dots_1.svg" alt={'...'}/>
                </Dropdown>
            </span>
        );
    }

    if (tokenActions.listProjects != AccessItem.EDIT) {
        return <></>;
    }

    return (
        <span style={{cursor: 'pointer'}} onClick={(e) => stopEvent(e)}>
            <Dropdown
                menu={{
                    items: [
                        props.status == 'active'
                            ? {
                                key: 'edit',
                                label: props.t(localeKeys.passportListScreen.table.editBtn),
                            }
                            : null,
                        props.status == 'active'
                            ? {
                                key: 'archive',
                                label: props.t(localeKeys.passportListScreen.table.archiveBtn),
                            }
                            : {
                                key: 'restore',
                                label: props.t(localeKeys.passportListScreen.table.restoreBtn),
                            },
                    ],
                    onClick: ({key}) => {
                        if (key == 'edit') {
                            props.onEdit(props.record.id ?? '');
                            return;
                        }
                        if (key == 'archive') {
                            props.onArchive(props.record.id ?? '');
                            return;
                        }
                        if (key == 'restore') {
                            props.onRestore(props.record.id ?? '');
                            return;
                        }
                    },
                }}
            >
                <img src="/canvas_table/dots_1.svg" alt={'...'}/>
            </Dropdown>
        </span>
    );
};
const PassportListColumns: (props: {
    onView: (id: string) => void;
    onEdit: (id: string) => void;
    onArchive: (id: string) => void;
    onRestore: (id: string) => void;
    onClickErrors: (id: string) => void;
    status: 'active' | 'archive';
    t: (key: string) => string;
}) => ColumnsType<Project> = (props) => {
    const res: ColumnsType<Project> = [
        {
            title: props.t(localeKeys.passportListScreen.table.title),
            dataIndex: 'title',
            // fixed: 'left',
            render: (text, record) => {
                if (!record.id) return <>{text}</>;

                return (
                    <div
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                        data-test={`passport_${record.title}`}
                    >
                        <Link to={`/passport/${record.id}`}>{text}</Link>
                        <PassportMore
                            onView={props.onView}
                            onEdit={props.onEdit}
                            onArchive={props.onArchive}
                            onRestore={props.onRestore}
                            status={props.status}
                            record={record}
                            t={props.t}
                        />
                    </div>
                );
            },
        },
        {
            title: props.t(localeKeys.passportListScreen.table.location),
            dataIndex: 'location',
            render: (text, record) => record.region_title ?? record.location ?? '',
        },
        {
            title: props.t(localeKeys.passportListScreen.table.srp),
            dataIndex: 'members',
            render: (text, record) => {
                if (text === '') return '';
                return <>{record.members?.filter((e) => e.position == 'pm' || e.position == 'senior_pm').map(e => e.full_name).join(' / ') || '-'}</>;
            },
        },
        {
            title: props.t(localeKeys.passportListScreen.table.square),
            dataIndex: 'total_area',
            render: (text) => {
                if (text === '') return '';
                return numberFormatter(text, {isInt: true}) || '0';
            },
        },
        {
            title: props.t(localeKeys.passportListScreen.table.plan_amount2).split('\n').map(e => <>{e}<br/></>),
            dataIndex: 'plan_amount',
            render: (text) => numberFormatter(text, {isInt: true}) || '0',
        },
        {
            title: props.t(localeKeys.passportListScreen.table.fact_amount),
            dataIndex: "fact_payments_amount",
            children: [
                {
                    title: props.t(localeKeys.passportListScreen.table.fact_amount2).split('\n').map(e => <>{e}<br/></>),
                    dataIndex: "fact_payments_amount",
                    render: (text, record) => numberFormatter((record.fact_payments_amount ?? 0), {isNol: ' '}),
                },
                {
                    title: props.t(localeKeys.passportListScreen.table.fact_amount3),
                    dataIndex: "fact_payments_percent",
                    render: (text, record) => numberFormatter((record.fact_payments_percent ?? 0), {isNol: ' '}),
                }
            ]
        },
        {
            title: props.t(localeKeys.passportListScreen.table.balance2).split('\n').map(e => <>{e}<br/></>),
            dataIndex: "fact_payments_balance",
            render: (text, record) => numberFormatter((record.fact_payments_balance ?? 0)),
        },
        {
            title: props.t(localeKeys.passportListScreen.table.errors1),
            dataIndex: 'errors_total',
            children: [
                {
                    title: props.t(localeKeys.passportListScreen.table.errors2),
                    onCell: (row, index) => ({
                        onClick: (e) => {
                            props.onClickErrors(row.id ?? '');
                            return stopEvent(e);
                        },
                    }),
                    render: (text, record) => record.id ? <span className={'text-link'}>{numberFormatter((record.errors_total ?? 0))}</span> : numberFormatter((record.errors_total ?? 0)),
                    dataIndex: 'errors_total',
                },
                {
                    title: props.t(localeKeys.passportListScreen.table.errors3),
                    onCell: (row, index) => ({
                        onClick: (e) => {
                            props.onClickErrors(row.id ?? '');
                            return stopEvent(e);
                        },
                    }),
                    render: (text, record) => record.id ? <span className={'text-link'}>{numberFormatter((record.errors_unclosed ?? 0))}</span> : numberFormatter((record.errors_unclosed ?? 0)),
                    dataIndex: 'errors_unclosed',
                }
            ]
        },
    ];

    return res.filter((e: any) => {
        if (tokenActions.finViewProjects == AccessItem.NONE) {
            if ([
                'plan_amount',
                'fact_payments_amount',
                'fact_payments_balance',
            ].includes(e.dataIndex)) {
                return false;
            }
        }

        if (tokenActions.projectListDetails == AccessItem.NONE) {
            if ([
                'errors_total',
            ].includes(e.dataIndex)) {
                return false;
            }
        }

        return true;
    })
};

export default PassportListColumns;

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}
