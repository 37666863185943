import {Button, Dropdown, Input, MenuProps, message, Tabs, TabsProps} from 'antd';
import {FunctionComponent, useEffect, useState} from 'react';
import LayoutComponent from '../../shared/layout_component';
import {api} from '../../shared/api_client';
import {ProjectListStatus, useProjectList} from '../../actions/useProject';
import {FCTableInvoicesList, InvoiceItem} from './FCTableInvoicesList';
import FCProjectListTree from './FCProjectListTree';
import {useInvoiceStatusesList} from '../../actions/useInvoiceStatusesList';
import {ExportOutlined} from "@ant-design/icons";
import {fetchFiles} from '../../shared/DownloadFile';
import {debounce} from 'lodash';
import {
    finControllerColumns,
    getFinControllerCountTabs
} from "./FinControllerUtils/FinControllerCollumns";
import tokenActions, {AccessItem} from "../../actions/tokenActions";
import {numberFormatter} from "../../shared/dateFormat";
import {localeKeys} from "../../i18n/localeKeys";
import {useTranslation} from "react-i18next";

export interface selectCheckBox {
    projectId: string;
    checkBoxList: InvoiceItem[]
    selectedRowKeys: React.Key[]
}

const FinController: FunctionComponent = () => {
    const [defaultStatus] = useState<ProjectListStatus>('on_work')
    const [statusTabs, setStatusTabs] = useState<ProjectListStatus>();
    const [project, setSelectProject] = useState<{ section_id: string, project_id: string }>()
    const {projectList, projectListError, projectListLoading} = useProjectList(statusTabs ?? defaultStatus)
    const {invoiceStatusesList, invoiceStatusesListError, invoiceStatusesListLoading} = useInvoiceStatusesList()
    const [checkBoxList, setCheckBoxList] = useState<selectCheckBox[]>([])
    const [inputValue, setInputValue] = useState<string>('')

    const [tabsDropDownSelect, setTabsDropDownSelect] = useState<number>(0);

    const [invoiceGrouped, setInvoiceGrouped] = useState<boolean>(false)
    const [refreshData, setRefreshData] = useState<boolean>(false)

    const countTabs = getFinControllerCountTabs(invoiceStatusesList ?? {});
    const {t} = useTranslation();

    useEffect(() => {
        if (tokenActions.finContr == AccessItem.NONE) {
            window.location.href = '/';
        }
    }, []);

    const items: MenuProps['items'] = invoiceStatusesList?.data && invoiceStatusesList.data.length > 0 ?
        [...invoiceStatusesList?.data?.slice(countTabs).map((item, index) => {
            return {
                key: `${item.text}`,
                disabled: item.count == 0,
                label: (<a onClick={() => {
                    setTabsDropDownSelect(index + 1)
                }} target="_blank" rel="noopener noreferrer">
                    {`${item.text} (${item.count})`}
                </a>),
            }
        })
        ] : [];


    const [isLoadingExport, setIsLoadingExport] = useState(false);
    const downloadFiles = () => {
        const idList = checkBoxList.map((item) => item.checkBoxList.map(item => item.idProject)).flat();
        const ids = idList.filter(item => item !== undefined);
        fetchFiles(`${api.baseUrl}/invoices/export`, 'invoice.pdf', {ids: ids as string[]})
            .catch((error) => {
                message.error(`Ошибка ${error}`)
            })
            .finally(() => {
                setIsLoadingExport(false);
            });
    }

    const debounceDownloadFiles = debounce(downloadFiles, 800)

    const onChangeTab = (status: ProjectListStatus, dropDownTabs?: boolean) => {
        console.log('onChangeTab', status, dropDownTabs)
        setStatusTabs(status)
        if (!dropDownTabs) setTabsDropDownSelect(0)

        setCheckBoxList([])
    };
    //дерево и таблица которые не вошли в основные табы
    const dropdownStatus: JSX.Element[] | JSX.Element = invoiceStatusesList?.data ? [...invoiceStatusesList.data.slice(countTabs).map((item) => {
        const render = (!projectListLoading
        && !projectListError
        && projectList?.data
            ? <div className='FC-wrapper'>
                <FCProjectListTree data={projectList.data} selectProject={project => setSelectProject(project)}/>
                <FCTableInvoicesList
                    selectCheckBox={setCheckBoxList}
                    checkBoxList={checkBoxList}
                    project={project}
                    columns={finControllerColumns}
                    status={statusTabs ?? defaultStatus}
                    invoiceGrouped={invoiceGrouped}
                    refreshData={refreshData}
                    allStatus={invoiceStatusesList.data ?? []}
                    searchValue={inputValue}/>
            </div> : <div>{t(localeKeys.shared.loading)}</div>)
        return render
    })] : <></>;
    //основные табы
    const tabs: TabsProps['items'] = invoiceStatusesList?.data ? [...invoiceStatusesList.data.slice(0, countTabs).map((item, index) => {
        return {
            key: item.value ?? '',
            label: `${item.text} (${item.count})`,
            disabled: item.count == 0,
            children: !projectListLoading
            && !projectListError
            && projectList?.data
                ? <div className='FC-wrapper'>
                    <FCProjectListTree data={projectList.data} selectProject={project => setSelectProject(project)}/>
                    <FCTableInvoicesList
                        selectCheckBox={setCheckBoxList}
                        checkBoxList={checkBoxList}
                        project={project}
                        columns={finControllerColumns}
                        status={statusTabs ?? defaultStatus}
                        invoiceGrouped={invoiceGrouped}
                        refreshData={refreshData}
                        allStatus={invoiceStatusesList.data ?? []}
                        searchValue={inputValue}/>
                </div> : <div>{t(localeKeys.shared.loading)}</div>,
        }
    })] : [];
    //подсчет кол-ва отображаемых таблов
    if (countTabs < (invoiceStatusesList?.data?.length ?? 0)) {
        tabs.push({
            key: 'more',
            label: <Dropdown menu={{items}} placement="bottomLeft" arrow>
                <a>{`${t(localeKeys.shared.more)} ${items.length}...`}</a>
            </Dropdown>,
            disabled: true,
        })
    }

    let defaultTabs
    //дефолтный таб
    useEffect(() => {
        if (invoiceStatusesList?.data) {
            if (invoiceStatusesList?.data[0])
                defaultTabs = invoiceStatusesList?.data[0].value
        }
    }, [invoiceStatusesList, invoiceStatusesListLoading, invoiceStatusesListError]);
    //запрос при открытии не основных табов (тех кто во вкладке Еще....)
    useEffect(() => {
        if (tabsDropDownSelect !== 0) {
            const newStatus = invoiceStatusesList?.data?.slice(countTabs).map(item => item.value)
            if (newStatus && newStatus[tabsDropDownSelect - 1]) {
                onChangeTab(newStatus[tabsDropDownSelect - 1] as ProjectListStatus, true)
            }
        }
    }, [tabsDropDownSelect]);

    (window as any).screenFinController = {
        defaultTabs,
        items,
        countTabs,
        checkBoxList,
    }


    const getTextExport = (list: selectCheckBox[]) => {
        const count = list.map(e => e.checkBoxList.length).reduce((a, b) => a + b, 0)
        const size = list.reduce((total: number, item: selectCheckBox) => {
            const sumProject: number = item.checkBoxList.reduce((total2, item2) => total2 + item2.volume, 0)
            return sumProject + total
        }, 0);
        const sum = list.reduce((total: number, item: selectCheckBox) => {
            const sumProject: number = item.checkBoxList.reduce((total2, item2) => total2 + item2.amount, 0)
            return sumProject + total
        }, 0)

        let message = '';
        if (count % 10 === 1 && count % 100 !== 11) {
            message = t(localeKeys.finController.selectedCount)
        } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
            message = t(localeKeys.finController.selectedCount2)
        } else {
            message = t(localeKeys.finController.selectedCount3)
        }

        return message.replace( '{{count}}', `${count}`).replace('{{size}}', `${size}`).replace('{{sum}}', numberFormatter(sum));
    }

    return (
        <LayoutComponent title={t(localeKeys.finController.title)}
                         className={`fin_controller_screen ${checkBoxList.length > 0 ? 'FC-Footer' : ''}`}
                         onAction={() => {
                         }}>
            {invoiceStatusesList && !invoiceStatusesListError && !invoiceStatusesListLoading
                ? <>
                    {tabsDropDownSelect === 0 ?
                        <Tabs
                            tabPosition={'top'}
                            moreIcon={<span>{t(localeKeys.shared.more)}... ({`${items.length}`})</span>}
                            className={'fin_controller_screen__content'}
                            activeKey={tabsDropDownSelect === 0 ? defaultTabs : '0'}
                            defaultActiveKey={tabsDropDownSelect === 0 ? defaultTabs : '0'}
                            items={tabs}
                            onChange={(e) => onChangeTab(e as ProjectListStatus)}
                            tabBarExtraContent={<>
                                <Input
                                    className={'fin_controller_screen__search'}
                                    placeholder={t(localeKeys.shared.search)}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    suffix={<img className={`tree-contragent-img`} src="/layout/Serch.svg"/>}/>
                            </>}
                        />
                        :
                        <>
                            <div>
                                <Tabs
                                    tabPosition={'top'}
                                    moreIcon={<span>{t(localeKeys.shared.more)}... ({`${items.length}`})</span>}
                                    className={'fin_controller_screen__content  FC-hidden'}
                                    activeKey={tabsDropDownSelect === 0 ? defaultTabs : '0'}
                                    defaultActiveKey={tabsDropDownSelect === 0 ? defaultTabs : '0'}
                                    items={tabs}
                                    onChange={(e) => onChangeTab(e as ProjectListStatus)}
                                    tabBarExtraContent={<>
                                        <Input
                                            className={'fin_controller_screen__search'}
                                            placeholder={t(localeKeys.shared.search)}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            suffix={<img className={`tree-contragent-img`} src="/layout/Serch.svg"/>}/>
                                    </>}
                                />
                            </div>
                            <div className='tabsDropDownSelect fin_controller_screen__content'>
                                {dropdownStatus && tabsDropDownSelect !== 0 ? Array.isArray(dropdownStatus)
                                    ? <>{dropdownStatus[tabsDropDownSelect - 1]}</>
                                    : <>{dropdownStatus}</> : null}
                            </div>
                        </>
                    }

                </>

                : "Загрузка..."}
            {checkBoxList.length > 0 ?
                <div className={"fin_controller_screen__export"}>
                    <div>{getTextExport(checkBoxList)}</div>
                    <Button type={'primary'} loading={isLoadingExport} disabled={isLoadingExport} onClick={() => {
                        setIsLoadingExport(true);
                        debounceDownloadFiles()
                    }}><ExportOutlined/>{t(localeKeys.shared.exportBtn)}</Button>
                </div>
                : null
            }
        </LayoutComponent>
    );
};

export default FinController;
