export const themeTable = {
  fontColor: '#000000',
  bgColorBase: '#F0F1F6',
  bgColor: '#D7F1FF',
  bgColor2: '#F0F1F6',
  bgColor3: '#F0F1F6',
  bgColor5: '#e9e9f9',
  bgColorEdit: 'white',
  bgColorWarning: '#fff7af',
  bgColorError: '#FF8B8B',
  bgColorSuccess: '#BBFFCE',
  bgColorBlack: '#000',
  borderColor: '#D1D2D6',

  font: '18px ALS Wagon Regular',
  fontBold: '18px ALS Wagon Bold',
  fontMedium: '18px ALS Wagon Medium',

  fontHeader: '16px ALS Wagon Bold',
  fontHeader2: '14px ALS Wagon Bold',

  fontHeaderRegular: '16px ALS Wagon Regular',
  fontHeader2Regular: '16px ALS Wagon Regular',
  fontHeaderBold: '18px ALS Wagon Bold',

  disabledColumnBg: 'rgba(0, 0, 0, 0.10)',
  disabledColumnColor: 'rgba(0, 0, 0, 0.4)',

  transparent: 'transparent',
};
