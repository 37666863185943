import {InvoiceHelperValueList} from "../../../ApiEpo";

export const finControllerColumns = [
  { title: 'Дата счета', translate: 'invoice_date' },
  { title: 'Номер счёта', translate: 'invoice_number' },
  { title: 'Объем номенклатуры', translate: 'volume' },
  { title: 'Цена за ед. номенклатуры', translate: 'price' },
  { title: 'Сумма', translate: 'amount' },
  { title: 'Статус', translate: 'status_label' },
  { title: 'Дата создания счета', translate: 'created_at' },
  { title: 'Проект', translate: 'project_title' },
  { title: 'Ед. изм', translate: 'measure_unit' },
]

export function getFinControllerCountTabs(invoiceStatusesList: InvoiceHelperValueList) {
  let countTabs = invoiceStatusesList?.data?.length ?? 0;

  if (window.innerWidth < 750) {
    countTabs = 2
  } else if (window.innerWidth < 900) {
    countTabs = 3
  } else if (window.innerWidth < 1000) {
    countTabs = 4
  } else if (window.innerWidth < 1150) {
    countTabs = 5
  } else if (window.innerWidth < 1300) {
    countTabs = 6
  } else if (window.innerWidth < 1450) {
    countTabs = 7
  } else if (window.innerWidth < 1500) {
    countTabs = 8
  }

  return countTabs
}
