import {ContractorShort} from "../ApiDirectory";

export interface IContragentList {
  id: string;
  name: string;
  rating: string;
  location: string[];
  type: { id: string; name: string };
  tags: string[];
  inn: string;
  comment: string;
  legal_address: string;
  contacts: string;
  related_user?: string;
  row_number: number;
}

export const contragentKey = 'contragents';

export function getEmptyContragent(): IContragentList {
  return {
    id: '',
    name: '',
    rating: '',
    location: [],
    type: { id: '', name: '' },
    tags: [],
    inn: '',
    comment: '',
    legal_address: '',
    contacts: '',
    row_number: 0,
  };
}

export interface IContragentView {
  id: string;
  name: string;
  type_id: string;
  type?: any;
  location: string[];
  rating: string;
  tags: string[];
  comment: string;
  inn: string;
  ogrn: string;
  legal_address: string;
  postal_address: string;
  phone: string;
  email: string;
  documents: {
    uid?: string;
    id: string;
    file_id: string;
    name: string;
    url: string;
    remove: boolean;
  }[];
  requisites: {
    id: string;
    bank_name: string;
    account: string;
    bik: string;
    correspondent_account: string;
    kpp: string;
    remove: boolean;
  }[];
  contacts: string;
  user_uid?: string;
  doc_number: any;
  doc_date: any;
  related_user?: string;
}

export const contragentViewKey = 'contragent_view';

export function getEmptyContragentView(): IContragentView {
  return {
    id: '',
    name: '',
    type_id: '',
    location: [],
    rating: `0`,
    tags: [],
    comment: '',
    inn: '',
    ogrn: '',
    legal_address: '',
    postal_address: '',
    phone: '',
    email: '',
    doc_number: '',
    doc_date: '',
    documents: [],
    requisites: [],
    contacts: '',
    related_user: '',
  };
}

export function getEmptyContractorShort(id = ''): ContractorShort {
  return {
    id: id,
    name: '',
    rating: 1,
    location: [],
    type: { id: '', name: '' },
    tags: [],
    inn: '',
    comment: '',
    legal_address: '',

    related_user: '',
    row_number: 0,

    doc_number: '',
    doc_date: '',

    ogrn: '',
  };
}
