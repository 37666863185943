import { FunctionComponent } from 'react';
import HeaderComponent, { TypeActionHeader } from './header_component';

const LayoutComponent: FunctionComponent<{
    children?: any;
    title: string;
    className: string;
    onAction: (type: TypeActionHeader, payload: any) => void;
    selectProjects?: boolean;
}> = (props) => {
    return (
        <div className={`layout_component ${props.className}`}>
            <HeaderComponent title={props.title} onAction={props.onAction} selectProjects={props.selectProjects} />
            {props.children}
        </div>
    );
};
export default LayoutComponent;
