import { FunctionComponent, useEffect, useState } from 'react';

const HtmlTruncate: FunctionComponent<{ html: string; maxLines: number }> = ({ html, maxLines }) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const [id, setId] = useState(Math.random().toString());

    useEffect(() => {
        setTimeout(() => {
            const itemById = document.getElementById(`html-truncate-${id}`);
            if (itemById) {
                setIsOverflow(itemById.scrollHeight > itemById.clientHeight);
            }
        }, 100);
    }, [html, maxLines]);

    return (
        <div>
            <div id={`html-truncate-${id}`} style={{ maxHeight: `${maxLines * 1.5}rem`, overflow: 'hidden' }}>
                <div
                    className={'html-truncate'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                    dangerouslySetInnerHTML={{
                        __html: html.replaceAll('<a ', "<a target='_blank' onclick='window.open(this.href)' "),
                    }}
                />
            </div>
            {isOverflow && <div style={{ marginTop: -10 }}>...</div>}
        </div>
    );
};

export default HtmlTruncate;
