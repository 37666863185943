import { CloseOutlined } from '@ant-design/icons';
import { FunctionComponent, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { getPassportCache } from '../../../table_extends/loadData';
import ModalHistoryFilters, { ModalHistoryFiltersChange } from './modal_history_filters';
import ModalHistoryTable from './modal_history_table';

const ModalHistory: FunctionComponent<{
    close: () => void;
    base: {
        section: string | null;
        group: string | null;
        nomenclature: string | null;
        project: string;
    };
}> = function (props) {
    const [curState, setCurState] = useState<ModalHistoryFiltersChange>({
        section: null,
        group: null,
        nomenclature: null,
        dates: null,
        event: null,
        users: null,
        selectedInterval: null,
        isInit: false,
    });

    let type = '';
    let operation: number | null = null;

    switch (curState.event) {
        case 'upd_project':
            type = 'Project';
            break;
        case 'upd_bo':
            type = 'ProjectGuidelineBudgetApprove';
            break;
        case 'upd_bp':
            type = 'ProjectNomenclature';
            operation = 1;
            break;
        case 'upd_dates':
            type = 'ProjectNomenclatureDates';
            operation = 1;
            break;
        case 'upd_contracts':
            type = 'ProjectNomenclatureContract';
            break;
    }

    (window as any).modalHistory = {
        section: curState.section,
        group: curState.group,
        nomenclature: curState.nomenclature,
        dates: curState.dates,
        event: curState.event,
        users: curState.users,
        selectedInterval: curState.selectedInterval,
    };

    return (
        <div className={'ModalHistory'}>
            <div className="title">
                История изменений
                <CloseOutlined onClick={props.close} />
            </div>
            <div className="content">
                <ModalHistoryFilters
                    close={props.close}
                    reset={() => {
                        setCurState({
                            section: null,
                            group: null,
                            nomenclature: null,
                            dates: null,
                            event: null,
                            users: null,
                            selectedInterval: null,
                            isInit: true,
                        });
                    }}
                    base={props.base}
                    onChangeFilters={(val) => {
                        console.log('onChangeFilters', val);
                        setCurState(val);
                    }}
                />
                <br />
                <br />

                {curState.isInit && (
                    <ModalHistoryTable
                        params={{
                            section_id: curState.section ? curState.section : undefined,
                            type_id: curState.group ? curState.group : undefined,
                            nomenclature_id: curState.nomenclature ? curState.nomenclature : undefined,
                            system_id: 'epo',
                            object_type: type as any,
                            // operations: [0, 1, 2, 3],
                            'operations[]': operation != null ? [operation] : (undefined as any),
                            user_ids: curState.users ? [curState.users] : undefined,
                            page_size: 30,
                            object_ids: [props.base.project],
                            beg_timestamp: curState.selectedInterval?.start
                                ? `${curState.selectedInterval.start.unix()}`
                                : undefined,
                            end_timestamp: curState.selectedInterval?.end
                                ? `${curState.selectedInterval.end.unix()}`
                                : undefined,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ModalHistory;

export const ModalHistoryBind: FunctionComponent = () => {
    return (
        <ModalHistory
            base={{
                section: getWData().cellOpen!.record.cns_section_id ?? null,
                group: getWData().cellOpen!.record.cns_group_id ?? null,
                nomenclature: getWData().cellOpen!.record.cns_nomenclature_id ?? null,
                project: getPassportCache().id ?? '',
            }}
            close={() => {
                closeModalHistory();
            }}
        />
    );
};

export function closeModalHistory() {
    if (!getWData().cellOpen?.isModalHistory) return;
    console.log('closeModalHistory');

    if (getWData().cellOpen?.isModalHistory) {
        getWData().cellOpen!.isModalHistory = false;
    }
    getWData().setRefresh(Math.random());
}
