import {DefaultOptionType} from "rc-select/lib/Select";
import {TableRecord} from "../../screens/canvas_table/components/TableRecord";

export function modalFilterContragents(nomenclatureIds: string[], rows3: TableRecord[]) {
  const list: { [x: string]: DefaultOptionType } = {};

  for (let item of rows3) {
    if (nomenclatureIds.length) {
      if (nomenclatureIds.includes(item.cns_nomenclature_id ?? '')) {
        for (let e of item.cns_contractors) {
          list[e.value ?? ''] = e;
        }
      }
    } else {
      for (let e of item.cns_contractors) {
        list[e.value ?? ''] = e;
      }
    }
  }

  for (let item of rows3) {
    const contractorIds = item.cns_contractors.map(e => e.value ?? '');
    if (nomenclatureIds.length) {
      if (nomenclatureIds.includes(item.cns_nomenclature_id ?? '')) {
        for (let item of Object.keys(list)) {
          if (!contractorIds.includes(item)) {
            delete list[item];
          }
        }
      }
    } else {
      for (let e of item.cns_contractors) {
        if (!contractorIds.includes(e.value ?? '')) {
          delete list[e.value ?? ''];
        }
      }
    }

  }

  return Object.values(list);
}
