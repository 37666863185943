import { Project } from '../ApiEpo';

// export interface PassportListItem {
//   id: string;
//   number: string;
//   title: string;
//   place: string;
//   budget_plan: string;
//   budget_fact: string;
//   start: string;
//   end: string;
//   user_grp: string;
//   user_srp: string;
//   user_rp: string;
//   user_ny: string;
//   sections: string[];
// }

export function getEmptyPassportListItem(): Project {
  return {
    id: '',
    name: '',
    title: '',
    status: '',
    region_id: '',
    region_title: '',
    start_date: '',
    end_date: '',
    members: [],
    total_area: '' as any,
    created_at: '',
    plan_amount: 0,
    fact_amount: 0,
  };
}
