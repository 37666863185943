import { ConfigProvider, Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import locale from 'antd/locale/ru_RU';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { sizes } from '../../../../shared/sizes';
import { MyDatePicker } from '../../../../shared/stopEvent';
import enUS from 'antd/es/calendar/locale/en_US';

export const ModalHistoryInterval: FunctionComponent<{
    selectedInterval: { start: Moment; end: Moment } | null;
    onChangeInterval: (interval: { start: Moment; end: Moment }) => void;
    formatDate: string;
}> = function (props) {
    const [isInit, setIsInit] = useState<boolean>(false);
    const [start, setStart] = useState<Moment>(moment());
    const [end, setEnd] = useState<Moment>(moment());

    useEffect(() => {
        setStart(props.selectedInterval?.start?.clone?.() ?? moment());
        setEnd(props.selectedInterval?.end?.clone?.() ?? moment());
        setIsInit(true);
    }, []);

    useEffect(() => {
        if (
            isInit &&
            (start.format(props.formatDate) !== props.selectedInterval?.start.format(props.formatDate) ||
                end.format(props.formatDate) !== props.selectedInterval?.end.format(props.formatDate))
        ) {
            props.onChangeInterval({ start, end });
        }
    }, [start, end]);

    return (
        <ConfigProvider locale={locale}>
            <Form labelCol={{ span: 16 }} labelAlign={'left'}>
                <FormItem label={'Начало периода'}>
                    <MyDatePicker
                        locale={enUS}
                        style={{ width: '150px' }}
                        showTime={window.innerWidth > sizes.mobile ? { format: 'HH:mm' } : undefined}
                        format={props.formatDate}
                        defaultValue={props.selectedInterval?.start?.clone?.()}
                        onChange={(val) => {
                            setStart(val ?? moment());
                        }}
                    />
                </FormItem>
                <FormItem label={'Конец периода'}>
                    <MyDatePicker
                        locale={enUS}
                        style={{ width: '150px' }}
                        showTime={window.innerWidth > sizes.mobile ? { format: 'HH:mm' } : undefined}
                        format={props.formatDate}
                        defaultValue={props.selectedInterval?.end?.clone?.()}
                        onChange={(val) => {
                            setEnd(val ?? moment());
                        }}
                    />
                </FormItem>
            </Form>
        </ConfigProvider>
    );
};
