import { getValueStore, removeValueStore, setValueStore } from './store';
import { getWData } from '../screens/table_extends/getWData';
import { getTableHeaders } from '../screens/table_extends/table_header/table_headers';
import { getTableBody, TableMode } from '../screens/table_extends/table_body/table_body';
import {ContractorShort} from "../ApiDirectory";

export const wDataHelpers = {
  bgColorLvl5: {
    get: () => getValueStore('bgColorLvl5'),
    set: (val: string) => {
      setValueStore('bgColorLvl5', val);
      getWData().setRefresh(Math.random())
    },
    reset: () => {
      removeValueStore('bgColorLvl5');
      getWData().setRefresh(Math.random())
    }
  },
  columnTitle: {
    get: () => getValueStore('columnTitleWidth'),
    set: (val: string) => {
      setValueStore('columnTitleWidth', val);
      },
    reset: () => {
      removeValueStore('columnTitleWidth');
    }
  },
}


