import { getWData } from '../../../table_extends/getWData';
import { Button, Input, notification, Tree, TreeProps } from 'antd';
import { uniq } from 'lodash';
import { useRef, useState } from 'react';
import { doConnectNomenclatures, doConnectSections, doConnectWorkTypes } from '../../actions/doConnectSection';

function ModalConnectNomenclaturesContent(props: { section_id?: string, group_id?: string, closeModal: () => void }) {
    let selected: string[] = [];

    type TreeNode = {
        key: string,
        title: string,
        disabled: boolean,
        children: {
            key: string,
            title: string,
            disabled: boolean,
            children: { key: string, title: string, disabled: boolean }[]
        }[]
    };

    const tree: TreeNode[] = [];

    let sections = getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'section');

    for (const section of sections) {
        const workTypesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == section.section_id && e.row_type == 'work_type');

        const hasNomenclaturesBySection = getWData().rows3.filter(nomenclature => nomenclature.cns_section_id == section.section_id).length;
        const countNomenclaturesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == section.section_id && e.row_type == 'nomenclature').length;

        tree.push({
            key: section.section_id ?? '',
            title: section.title ?? '',
            disabled: countNomenclaturesBySection > 0 ? countNomenclaturesBySection == hasNomenclaturesBySection : !!getWData().rows.find(section2 => section2.cns_section_id == section.section_id),
            children: workTypesBySection.map(group => {
                const nomenclaturesByGroup = getWData().db.nomenclaturesStruct.filter(e => e.type_id == group.type_id && e.section_id == section.section_id && e.row_type == 'nomenclature');

                const hasNomenclaturesByGroup = getWData().rows3.filter(nomenclature => nomenclature.cns_group_id == group.type_id && nomenclature.cns_section_id == section.section_id).length;
                const countNomenclaturesByGroup = nomenclaturesByGroup.length;

                return ({
                    key: `${group.section_id}_${group.type_id}`,
                    title: group.title ?? '',
                    disabled: countNomenclaturesByGroup > 0 ? countNomenclaturesByGroup == hasNomenclaturesByGroup : false,
                    children: nomenclaturesByGroup.map(item => ({
                        key: `${item.section_id}_${item.type_id}_${item.nomenclature_id}`,
                        title: item.title ?? '',
                        disabled: getWData().rows3.map(e => e.cns_id).includes(`${item.section_id}_${item.type_id}_${item.nomenclature_id}`),
                    })),
                });
            }),
        });
    }

    const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
        const ids = ((checkedKeys as any) as string[]);
        const nomenclatures = getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'nomenclature' && ids.includes(`${e.section_id}_${e.type_id}_${e.nomenclature_id}` ?? ''));

        const groupIds = uniq(nomenclatures.map(e => `${e.section_id}_${e.type_id}` ?? ''));
        const sectionIds = uniq(nomenclatures.map(e => e.section_id ?? ''));
        const nomenclatureIds = uniq(nomenclatures.map(e => `${e.section_id}_${e.type_id}_${e.nomenclature_id}` ?? ''));

        selected = uniq([...ids, ...nomenclatureIds, ...sectionIds, ...groupIds]);
    };

    const [search, setSearch] = useState<string>('');

    const [treeSearch, setTreeSearch] = useState<TreeNode[]>(tree);

    const treeRef = useRef<any>(null);
    const [processSave, setProcessSave] = useState<boolean>(false);

    return <>
        <div className={`ModalConnectNomenclature`}>
            <Input
                disabled={processSave}
                onInput={(e: any) => {
                    setTreeSearch(tree.filter(section => {
                        const search_text = e.target.value.trim().toLowerCase();
                        if (section.title.toLowerCase().includes(search_text)) {
                            return true;
                        }

                        if (section.children.some(group => group.title.toLowerCase().includes(search_text))) {
                            return true;
                        }

                        if (section.children.some(group => group.children.some(item => item.title.toLowerCase().includes(search_text)))) {
                            return true;
                        }

                        return false;
                    }));
                    setSearch(e.target.value);
                }}
                placeholder={'Поиск'}
            />
            <br />
            <br />

            <Tree
                disabled={processSave}
                ref={treeRef}
                checkable
                treeData={treeSearch}
                defaultExpandedKeys={[props.section_id ?? '', props.group_id ?? '']}
                defaultCheckedKeys={[]}
                onCheck={onCheck}
            />
        </div>
        <div style={{ textAlign: 'right', paddingTop: '20px' }}>
            <Button
                disabled={processSave}
                size={'small'}
                type={'text'}
                onClick={async () => {
                    props.closeModal();
                }}
            >
                Отмена
            </Button> &nbsp; &nbsp;
            <Button
                data-test={'BTN_CONNECT_NOMENCLATURES'}
                disabled={processSave}
                loading={processSave}
                size={'small'}
                type={'primary'}
                onClick={async () => {
                    setProcessSave(true);

                    try {

                        console.log({ selected });

                        const resultSections = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => item.section_id === uid && item.row_type === 'section'));
                        const resultGroups = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => `${item.section_id}_${item.type_id}` === uid && item.row_type === 'work_type'));
                        const resultNomenclatures = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => `${item.section_id}_${item.type_id}_${item.nomenclature_id}` === uid && item.row_type === 'nomenclature'));

                        console.log({ resultSections, resultGroups, resultNomenclatures });

                        await doConnectSections(resultSections.filter(section_id => !getWData().rows.map(e => e.cns_section_id).includes(section_id)));

                        for (const section of resultSections) {
                            await doConnectWorkTypes(section, resultGroups
                                .filter(uid => uid.split('_')[0] === section)
                                .map(e => e.split('_')[1])
                                .filter(group_id => !getWData().rows2.map(e => e.cns_group_id).includes(group_id)),
                            );
                        }

                        for (const section of resultSections) {
                            const section_id = section;
                            for (const group of resultGroups) {
                                const group_id = group.split('_')[1];

                                if (section_id == group.split('_')[0]) {
                                    const nomenclatures = resultNomenclatures
                                        .filter(uid => {
                                            const [section_id2, type_id2, nomenclature_id2] = uid.split('_');
                                            return section_id2 === section_id && type_id2 === group_id;
                                        })
                                        .map(e => e.split('_')[2]);

                                    await doConnectNomenclatures(section_id, group_id, nomenclatures);
                                }
                            }
                        }
                        props.closeModal();

                    } catch (e) {
                        console.error(e);

                        notification.error({
                            message: 'Не удалось добавить номенклатуры',
                        });
                    }
                    setProcessSave(false);

                }}
            >
                Добавить
            </Button>
        </div>
    </>;
}


export default ModalConnectNomenclaturesContent;
