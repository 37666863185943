import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
import moment, { Moment } from 'moment/moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { useUsers } from '../../../../actions/useUsers';
import { sizes } from '../../../../shared/sizes';
import { getWData } from '../../../table_extends/getWData';
import IconClear from './iconClear';
import { ModalHistoryInterval } from './modal_history_interval';
import {DateFormat, DateFormatFull} from "../../../../shared/dateFormat";

export interface ModalHistoryFiltersChange {
    section: string | null;
    group: string | null;
    nomenclature: string | null;
    dates: string | null;
    event: string | null;
    users: string | null;
    isInit: boolean | undefined;
    selectedInterval: { start: Moment; end: Moment } | null;
}

const ModalHistoryFilters: FunctionComponent<{
    close: () => void;
    reset: () => void;
    base: {
        section: string | null;
        group: string | null;
        nomenclature: string | null;
        project: string;
    };
    onChangeFilters: (props: ModalHistoryFiltersChange) => void;
}> = (props) => {
    const [section, setSection] = useState<string | null>(props.base.section ? props.base.section : null);
    const [group, setGroup] = useState<string | null>(props.base.group ? props.base.group : null);
    const [nomenclature, setNomenclature] = useState<string | null>(
        props.base.nomenclature ? props.base.nomenclature : null,
    );
    const [searchNomenklatures, setSearchNomenklatures] = useState<string | null>(null);

    const [dates, setDates] = useState<string | null>(null);
    const [event, setEvent] = useState<string | null>(null);
    const [users, setUsers] = useState<string | null>(null);
    const [searchUsers, setSearchUsers] = useState<string | null>(null);
    const [selectedInterval, setSelectedInterval] = useState<{ start: Moment; end: Moment } | null>(null);

    const { users: usersList, usersLoading, usersError } = useUsers(searchUsers ?? '');

    function handleSearchNomenclature(search: string) {
        console.log('handleSearchNomenclature', search);
        setSearchNomenklatures(search.toLowerCase());
    }

    useEffect(() => {
        setSection(props.base.section ? props.base.section : null);
        setGroup(props.base.group ? props.base.group : null);
        setNomenclature(props.base.nomenclature ? props.base.nomenclature : null);

        (window as any).modalHistoryFilters = {
            section,
            group,
            nomenclature,
            searchNomenklatures,
            selectedInterval,
            dates,
            formattedInterval,
        };
    }, []);

    useEffect(() => {
        props.onChangeFilters({
            section,
            group,
            nomenclature,
            dates,
            event,
            users,
            selectedInterval: selectedInterval ? { ...selectedInterval } : null,
            isInit: true,
        });
        // console.log('getWData rows2',getWData().rows2)
    }, [section, group, nomenclature, dates, event, users]);

    useEffect(() => {
        console.log('useEffect', section, group, nomenclature, dates, event, users, selectedInterval);
        props.onChangeFilters({
            section,
            group,
            nomenclature,
            dates,
            event,
            users,
            selectedInterval: selectedInterval ? { ...selectedInterval } : null,
            isInit: true,
        });
    }, [section, group, nomenclature, dates, event, users]);

    const [random, setRandom] = useState(Math.random());

    const formatDate = window.innerWidth > sizes.mobile ? DateFormatFull : DateFormat;
    const formattedInterval = selectedInterval
        ? `${selectedInterval.start.format(formatDate)} - ${selectedInterval.end.format(formatDate)}`
        : '';

    const eventsOptions = [
        { value: 'upd_project', label: 'Изменен проект' },
        { value: 'upd_bo', label: 'Изменен БО' },
        { value: 'upd_bp', label: 'Изменен БП' },
        { value: 'upd_dates', label: 'Изменены даты' },
        { value: 'upd_contracts', label: 'Изменен контракт' },
    ];

    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);

    // @ts-ignore
    return (
        <div className={'wrap_filters'}>
            {window.innerWidth < sizes.mobile && (
                <div className={'mobile-main-filters'}>
                    <Select
                        placeholder={'Все события'}
                        value={event}
                        onChange={(val) => {
                            setEvent(val);
                        }}
                        allowClear={true}
                        onClear={() => {
                            setEvent(null);
                        }}
                        options={eventsOptions}
                    ></Select>

                    <Button
                        type={'dashed'}
                        onClick={() => setIsOpenFilters(true)}
                        className={dates || event || users || section || group || nomenclature ? 'dots' : ''}
                    >
                        <SettingOutlined />
                    </Button>
                </div>
            )}

            {(window.innerWidth >= sizes.mobile || isOpenFilters) && (
                <div className={`modal_wrap_filters ${window.innerWidth < sizes.mobile ? 'mobile' : ''}`}>
                    {window.innerWidth < sizes.mobile && (
                        <div>
                            <div className="title">
                                История изменений
                                <CloseOutlined onClick={() => setIsOpenFilters(false)} />
                            </div>
                        </div>
                    )}
                    <div className={'filters_first'}>
                        <Select
                            placeholder={'Все разделы'}
                            value={section}
                            onChange={(val) => {
                                setSection(val);
                                setGroup(null);
                                setNomenclature(null);
                                setSearchNomenklatures(null);
                            }}
                            allowClear={{ clearIcon: <IconClear /> }}
                            onClear={() => {
                                setSection(null);
                            }}
                        >
                            {getWData().rows.map((row) => (
                                <Select.Option value={row.cns_section_id}>{row.cns_title}</Select.Option>
                            ))}
                        </Select>
                        <Select
                            disabled={!Boolean(section)}
                            allowClear={{ clearIcon: <IconClear /> }}
                            value={group}
                            placeholder={'Все группы'}
                            onChange={(val) => {
                                setGroup(val);
                                setNomenclature(null);
                                setSearchNomenklatures('');
                            }}
                            onClear={() => {
                                setGroup('');
                            }}
                        >
                            {getWData()
                                .rows2.filter((row) => (section ? row.cns_section_id == section : true))
                                .map((row) => (
                                    <Select.Option value={row.cns_group_id}>
                                        {row.cns_title
                                            ? `${row.cns_number} ${row.cns_title}`
                                            : `${row.cns_number} Нет наименования`}
                                    </Select.Option>
                                ))}
                        </Select>
                        <Select
                            disabled={!Boolean(group)}
                            allowClear={{ clearIcon: <IconClear /> }}
                            value={nomenclature}
                            showSearch
                            onSearch={(val) => handleSearchNomenclature(val)}
                            placeholder={'Все номенклатуры'}
                            filterOption={false}
                            onChange={(value, option) => {
                                setNomenclature(value);
                            }}
                            onClear={() => {
                                setNomenclature('');
                            }}
                        >
                            {getWData()
                                .rows3.filter(
                                    (row) =>
                                        (section ? row.cns_section_id == section : true) &&
                                        (group ? row.cns_group_id == group : true),
                                )
                                .filter((row) => {
                                    if (!searchNomenklatures) return true;
                                    return row.cns_title?.toLowerCase().indexOf(searchNomenklatures) != -1;
                                })
                                .map((row) => (
                                    <Select.Option value={row.cns_nomenclature_id} key={row.cns_nomenclature_id}>
                                        {row.cns_title
                                            ? `${row.cns_number} ${row.cns_title}`
                                            : `${row.cns_number} Нет наименования`}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className={'filters_second'}>
                        <Select
                            placeholder={'За все время'}
                            value={dates}
                            onChange={(val) => {
                                setDates(val);
                                if (val == 'select_time') {
                                    Modal.info({
                                        type: 'info',
                                        closable: true,
                                        className: 'ModalSelectDate',
                                        title: 'Выберите период',
                                        content: (
                                            <ModalHistoryInterval
                                                selectedInterval={selectedInterval}
                                                onChangeInterval={(vals) => {
                                                    sessionStorage.setItem(
                                                        'formattedInterval',
                                                        `${vals.start.format(formatDate)} - ${vals.end.format(formatDate)}`,
                                                    );
                                                    setSelectedInterval(vals);
                                                }}
                                                formatDate={formatDate}
                                            />
                                        ),
                                        okText: 'Добавить',
                                        onOk: () => {
                                            setDates(sessionStorage.getItem('formattedInterval'));
                                        },
                                        onCancel: () => {
                                            setDates(null);
                                            setSelectedInterval(null);
                                            delete sessionStorage.formattedInterval;
                                        },
                                    });
                                }

                                let start: moment.Moment | null = null;
                                let end: moment.Moment | null = null;

                                switch (val) {
                                    case 'today':
                                        start = moment().set({ h: 0, m: 0 });
                                        end = moment().set({ h: 23, m: 59, s: 59 });
                                        break;
                                    case 'week':
                                        start = moment().add(-7, 'days').set({ h: 0, m: 0 });
                                        end = moment().set({ h: 23, m: 59, s: 59 });

                                        break;
                                    case 'month':
                                        start = moment().add(-1, 'months').set({ h: 0, m: 0 });
                                        end = moment().set({ h: 23, m: 59, s: 59 });
                                        break;
                                }

                                if (start && end) {
                                    setSelectedInterval({ start: start, end: end });
                                    setDates(`${start.format(formatDate)} - ${end.format(formatDate)}`);
                                }
                            }}
                            allowClear={{ clearIcon: <IconClear /> }}
                            onClear={() => {
                                setDates(null);
                                setSelectedInterval(null);
                            }}
                        >
                            <Select.Option value={'all'}>За все время</Select.Option>
                            <Select.Option value={'today'}>Сегодня</Select.Option>
                            <Select.Option value={'week'}>Неделя</Select.Option>
                            <Select.Option value={'month'}>Месяц</Select.Option>
                            <Select.Option value={'select_time'}>Выбрать период</Select.Option>
                            {selectedInterval && (
                                <Select.Option value={formattedInterval}>{formattedInterval}</Select.Option>
                            )}
                        </Select>
                        <Select
                            placeholder={'Все события'}
                            value={event}
                            onChange={(val) => {
                                setEvent(val);
                            }}
                            allowClear={{ clearIcon: <IconClear /> }}
                            onClear={() => {
                                setEvent(null);
                            }}
                            options={eventsOptions}
                        ></Select>
                        <Select
                            showSearch
                            filterOption={false}
                            placeholder={'Все сотрудники'}
                            value={users}
                            onSearch={(value) => {
                                setSearchUsers(value);
                            }}
                            onChange={(val) => {
                                setUsers(val);
                            }}
                            allowClear={{ clearIcon: <IconClear /> }}
                            onClear={() => {
                                setUsers(null);
                            }}
                            options={usersList?.data?.map((user) => {
                                return {
                                    label: user.text,
                                    value: user.value,
                                };
                            })}
                        />
                        <div className={'empty'}></div>
                    </div>

                    {window.innerWidth < sizes.mobile && (
                        <div className="footer">
                            <Button type={'primary'} onClick={() => setIsOpenFilters(false)}>
                                Применить
                            </Button>
                            <Button
                                type={'dashed'}
                                danger={true}
                                onClick={() => {
                                    props.reset();
                                    setDates(null);
                                    setSelectedInterval(null);
                                    setEvent(null);
                                    setUsers(null);
                                    setSection(null);
                                    setGroup(null);
                                    setNomenclature(null);
                                    setRandom(Math.random());
                                    setIsOpenFilters(false);
                                }}
                            >
                                Сбросить
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ModalHistoryFilters;
