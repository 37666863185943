import { useQuery } from "react-query";
import { api } from "../shared/api_client";
import { ProjectListStatus } from "./useProject";

export const useInvoicesListGrouped = ({ status, project_id, section_id, page, per_page, search }: { status?: ProjectListStatus, project_id: string, section_id: string, page?: number, per_page?: number, search?: string }) => {
  const {
    data: invoicesListGrouped,
    error: invoicesListGroupedError,
    isLoading: invoicesListGroupedLoading,
  } = useQuery({
    queryKey: ['invoicesListGrouped', status, project_id, section_id, page, per_page, search],
    queryFn: () => api.invoices.groupedList({ status: status, project_id: project_id, section_id: section_id, page: page, per_page: per_page, search: search }).then((e) => e.data),
    enabled: project_id !== '' && section_id !== '',
    keepPreviousData: true,
  })

  return {
    invoicesListGrouped,
    invoicesListGroupedError,
    invoicesListGroupedLoading,
  };
}
