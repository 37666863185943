import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {getWData} from "../../../table_extends/getWData";
import {IModalAddGlobal} from "./modal_add_contract";

export const ModalInvoice: FunctionComponent<IModalAddGlobal> = (props) => {

    return <>
        {props.open && <div className={`ModalInvoice`}>
            ModalInvoice
        </div>}
    </>
}


export function closeModalInvoice() {
    if (!getWData().cellOpen?.isModalInvoice) return;
    console.log('closeModalInvoice');

    if (getWData().cellOpen?.isModalInvoice) {
        getWData().cellOpen!.isModalInvoice = false;
    }
    getWData().setRefresh(Math.random());
}
