import cheetahGrid, {ListGrid} from 'cheetah-grid';
import React, {useEffect, useState} from 'react';

import moment from 'moment';
import {useParams} from 'react-router-dom';
import {useContractors} from '../../actions/useContractors';
import {useSettingsView} from '../../actions/useSettingsView';
import {ProjectDetail} from '../../ApiEpo';
import LayoutComponent from '../../shared/layout_component';
import {sizes} from '../../shared/sizes';
import TableLoading from '../../shared/table_loading';
import {allowMoveApproveBudget} from '../table_extends/allowEditCell';
import {refreshStatusRecords} from '../table_extends/approveRow';
import {refreshFar} from '../table_extends/calcLocalValueRecord';
import {firstCalc} from '../table_extends/firstCalc';
import {getExcludesColumns, getWData, WData} from '../table_extends/getWData';
import {getPassportCache, loadData} from '../table_extends/loadData';
import {syncData} from '../table_extends/syncData';
import {getTableBody, TableMode} from '../table_extends/table_body/table_body';
import {getTableHeaders, tableHeaders} from '../table_extends/table_header/table_headers';
import {themeTable} from '../table_extends/table_theme';
import {closeInput} from './actions/closeInput';
import {closeModalDots} from './actions/closeModalDots';
import {onCellClick} from './actions/onCellClick';
import {onCellLeave} from './actions/onCellLeave';
import {onCellMove} from './actions/onCellMove';
import './canvas_table.scss';
import {ModalHistoryBind} from './components/modal_history/modal_history';
import {ModalAddRowBind} from './components/modals/modal_add_row';
import {closeModalApproveBudget, ModalApproveBudgetBind} from './components/modals/modal_approve_budget';
import {closeModalContragent} from './components/modals/modal_contragent';
import {closeModalSendApprove, ModalSendApproveBind} from './components/modals/modal_send_approve';
import {ModalSettingsBind} from './components/modals/modal_settings';
import {TableRecord} from './components/TableRecord';
import {checkEndLoading} from './components/utils/checkEndLoading';
import {SuperEditorBind} from './SuperEditor';
import {ModalAddContractBind} from './components/modals/modal_add_contract';
import {ModalAddInvoiceBind} from './components/modals/modal_add_invoice';
import {delay} from '../../shared/delay';
import {ModalAddWayBillsBind} from './components/modals/modal_add_way_bills';
import {ModalAddBillsBind} from './components/modals/modal_add_bills';
import {numberFormatter} from '../../shared/dateFormat';
import {closeModalInvoice} from './components/modals/modal_invice';
import {closeModalWellBill} from './components/modals/modal_well_bill';
import {closeModalBill} from './components/modals/modal_bill';
import {useMembersRps} from '../../actions/useMembers';
import {WrapModalViewContract} from './components/modals/modal_view_contract';
import {ModalErrorsBind} from './components/modals/modal_errors';
import { getNamePropsWithData, getTypeModalEnum, TypeModalEnum } from './components/utils/getErrorFieldPrefics';
import {useTranslation} from 'react-i18next';
import {isMobile2} from '../../shared/isMobile';
import {getResultRows} from '../table_extends/getResultRows';
import {ModalErrorViewBind} from './components/modals/modal_error_view';
import {wDataHelpers} from '../../shared/wDataHelpers';
import {useDb} from "../../actions/useDb";
import {getContractorsDb} from "../../indexedb";
import {useContragentsDb} from "../../actions/useContragentsDB";
import {ModalContractsAgreementBind} from "./components/modals/modal_contracts_agreement";
import {useContractsAgreement} from "../../actions/useContractsAgreement";
import { useNomenclaturesTable } from "../../actions/useNomenclaturesTable";
import tokenActions from '../../actions/tokenActions';


function Canvas3Screen() {
    const {initDb, isInit} = useDb()
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const routeParams = useParams();
    const [passport, setPassport] = useState<ProjectDetail | undefined>();
    const { settings } = useSettingsView();
    const [timer, setTimer] = useState<any>(0);
    const [showLoading, setShowLoading] = useState(true);
    const {loadAllContragents} = useContragentsDb()
    const {contractsAgreement, isLoadingContractsAgreement} = useContractsAgreement()
    const {nomenclatures, nomenclaturesLoading} = useNomenclaturesTable()

    useEffect(() => {
        if (contractsAgreement?.data?.length) {
            getWData().db.contractsAgreement = contractsAgreement.data;
        }
    }, [isLoadingContractsAgreement]);

    useEffect(() => {
        if (nomenclatures?.rows) {
            getWData().db.nomenclaturesStruct = nomenclatures?.rows;
        }
    }, [nomenclaturesLoading]);

    function onBlur(e: any) {
        const className = e.target.classList;
        const hasClick = className.contains('header_component') || className.contains('tableCanvas');

        if (hasClick) {
            console.log('close header');
            closeInput(-1, -1);
        }
    }

    useEffect(() => {
        if (getWData().isInit) return;

        setTimeout(async () => {
            const res = await loadDataPassport({ showLoader: true });
            if (res && res.passport.status !== 'archive') {
                if (process.env.REACT_APP_ENABLE_SYNC !== 'false') {
                    await syncData.connect(routeParams['id'] ?? '');
                }
            }
        });

        window.onclick = onBlur;

        if (!process.env.REACT_APP_NO_ANIMATE) {
            const t = setInterval(() => {
                getWData().grid?.invalidate();
            }, 1000);
            setTimer(t);
        }

        return () => {
            window.removeEventListener('click', onBlur);
            clearInterval(timer);
        };
    }, []);

    async function loadDataPassport(params: { showLoader: boolean }) {
        if (getWData().isInit) return;
        console.log('INIT');
        if (params.showLoader) {
            setShowLoading(true);
        }

        getWData().rows = [];
        getWData().rows2 = [];
        getWData().rows3 = [];
        getWData().resultRows = [];
        getWData().errors = [];
        getWData().isInit = true;

        if (isInit == '') {
            await initDb();
            await delay(300);
            getWData().db.dbContractors = await getContractorsDb({page: 1, perPage: 100000});
            if (getWData().db.dbContractors.length == 0) {
                await loadAllContragents();
                getWData().db.dbContractors = await getContractorsDb({page: 1, perPage: 100000});
            }
        }

        const res2 = await loadData(routeParams['id'] ?? '');

        const { passport, rowsTable, errors, errorsSimple } = res2;

        setPassport(passport);

        getWData().rows = rowsTable.rows;
        getWData().rows2 = rowsTable.rows2;
        getWData().rows3 = rowsTable.rows3;
        getWData().rows4 = rowsTable.rows4;
        if (tokenActions.visibleFiveLevel != 'NONE') {
            getWData().rows5 = rowsTable.rows5;
        }
        getWData().hasAllowMoveOfApprove = !![...rowsTable.rows, ...rowsTable.rows2, ...rowsTable.rows3].find((e) => allowMoveApproveBudget(e));

        firstCalc();
        refreshStatusRecords();
        refreshFar();

        renderTable();

        checkEndLoading(setShowLoading);

        return res2;
    }

    const [grid, setGrid] = useState<ListGrid<TableRecord> | undefined>();

    ((window as any).canvasT as WData) = {
        ...((window as any).canvasT ?? {}),
        rows: getWData().rows,
        rows2: getWData().rows2,
        rows3: getWData().rows3,
        rows4: getWData().rows4,
        rows5: tokenActions.visibleFiveLevel != 'NONE' ? getWData().rows5 : [],
        resultRows: getWData().resultRows,
        grid,
        expandedLevel1: getWData().expandedLevel1,
        expandedLevel2: getWData().expandedLevel2,
        expandedLevel3: getWData().expandedLevel3,
        expandedLevel4: getWData().expandedLevel4,
        moveCell: undefined,
        isInit: false,
        excludedColumns: getExcludesColumns(),
        resultRowValues: getWData().resultRowValues,
        tableMode: getWData().tableMode,
        textInput: getWData().textInput,
        setRefresh: setRefresh,
        loadDataPassport: loadDataPassport,
        refreshStatusRecords: refreshStatusRecords,
        body: getTableBody(TableMode.VIEW),
        refresh: {
            firstCalc,
            refreshStatusRecords,
            refreshFar: refreshFar,
        },
        dedlainDays: Number(settings?.data?.find((e) => e.name == 'BEFORE_DEADLINE_DATES_COUNT')?.value ?? '0'),
        struct: {
            getTableHeaders: getTableHeaders,
            getTableBody: getTableBody,
            getResultRows: getResultRows,
        },
        globalActions: {
            isModalErrors: getWData().globalActions?.isModalErrors ?? false,
            isModalErrorView: getWData().globalActions?.isModalErrorView ?? false,
            isModalAddManyContracts: getWData().globalActions?.isModalAddManyContracts ?? false,
            isModalAddInvoice: getWData().globalActions?.isModalAddInvoice ?? false,
            isModalAddWayBills: getWData().globalActions?.isModalAddWayBills ?? false,
            isModalAddBills: getWData().globalActions?.isModalAddBills ?? false,
            isModalContractsAgreement: getWData().globalActions?.isModalContractsAgreement ?? false,
        },
        pinned: {
            numberPinned: getWData().pinned?.numberPinned ?? true,
            titlePinned: getWData().pinned?.titlePinned ?? true,
            unitPinned: getWData().pinned?.unitPinned ?? true,
        },
        helpers: wDataHelpers,
        db: {
            dbContractors: getWData().db.dbContractors,
            loadAllContragents: loadAllContragents,
            contractsAgreement: contractsAgreement?.data ?? [],
            nomenclaturesStruct: nomenclatures?.rows ?? [],
        },
        calc: {
            hasContractsAgreement: (contractsAgreement?.data ?? []).find( e => e.status != 'signed' && e.status != 'rejected') != undefined
        }
    };

    function renderTable() {
        const pinned = getWData().pinned;

        if (grid || getWData().grid) return;
        const grid2 = new cheetahGrid.ListGrid<TableRecord>({
            parentElement: document.querySelector('.tableCanvas') as any,
            theme: {
                ...(cheetahGrid.themes.MATERIAL_DESIGN as any),
                underlayBackgroundColor: themeTable.bgColorBase,
                borderColor: themeTable.transparent,
                highlightBorderColor: (val) => {
                    if (getWData().resultRows[val.row - tableHeaders.length]?.cns_row_type == 'section') return '';

                    return '';
                },
                selectionBgColor: 'transparent',
                defaultBgColor: themeTable.bgColorBase,
            },
            disableColumnResize: true,
            headerRowHeight: [40, 35, 40],
            frozenRowCount: 4,
            frozenColCount: isMobile2 ? 1 : (pinned.numberPinned ? 1 : 0) + (pinned.titlePinned ? 2 : 0) + (pinned.unitPinned ? 1 : 0),
            layout: {
                header: getTableHeaders(),
                body: getTableBody(TableMode.VIEW),
            },
        });

        grid2.records = getWData().resultRows;
        grid2.layout = {
            header: getTableHeaders(),
            body: getTableBody(TableMode.VIEW),
        };

        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.MOUSEDOWN_CELL, (...args) => {
            // closeModalDots();

            setTimeout(() => onCellClick(args[0]));

            setTimeout(() => onCellMove(args[0]), 100);
        });
        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.MOUSEMOVE_CELL, (...args) => {
            if (
                ['cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date'].includes(
                    getWData().cellOpen?.columnKey ?? '',
                )
            ) {
                return;
            }

            onCellMove(args[0]);
        });
        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.MOUSELEAVE_CELL, (...args) => {
            if (
                ['cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date'].includes(
                    getWData().cellOpen?.columnKey ?? '',
                )
            ) {
                return;
            }

            onCellLeave(args[0]);
        });

        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.MOUSEDOWN_GRID, (...args) => {
            onCellLeave(args[0]);
        });

        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.SCROLL, (_) => {
            const d1 = getWData().cellOpen?.timeOpen?.unix() ?? 0;
            const d2 = moment().unix();
            if (getWData().cellOpen?.openEdit && d2 - d1 > (window.outerWidth < sizes.mobile ? 1 : 0.01)) {
                closeInput(getWData().cellOpen!.col, getWData().cellOpen!.row, getWData().textInput);
            }

            closeModalDots();
            closeModalInvoice();
            closeModalWellBill();
            closeModalBill();

            if (getWData().tableMode !== TableMode.SCROLL) {
                getWData().tableMode = TableMode.SCROLL;
                closeModalApproveBudget();
                closeModalContragent();
                closeModalSendApprove();

                grid2.layout = {
                    header: getTableHeaders(),
                    body: getTableBody(TableMode.SCROLL),
                };
                getWData().grid.invalidate();
            }
        });

        grid2.listen(cheetahGrid.ListGrid.EVENT_TYPE.RESIZE_COLUMN, (...args) => {
            console.log(args);
        });

        grid2.focusCell(1, 1);

        setGrid(grid2 as any);
        (window as any).canvasT2 = { grid: grid2 };
    }


    const a = getWData();


    const [search, setSearch] = useState('');
    const contractors = useContractors(search);

    function getContractors(search: any) {
        setSearch(search);
    }

    // const data = api.helpers.contractorsList();
    //
    // useEffect(() => {
    //     api.helpers.contractorsList().then((res) => {
    //         console.log('777', res);
    //         console.log('7778', a.rows2[0]?.cns_title);
    //     });
    // }, []);

    const memberRps = useMembersRps();

    return (
        <LayoutComponent
            selectProjects={true}
            title={passport?.title ?? 'Проект'}
            className="canvas_table"
            onAction={async (type) => {
                if (type == 'add_many_contracts') {
                    getWData().globalActions.isModalAddManyContracts = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalAddManyContracts = true;
                    getWData().setRefresh(Math.random());
                }
                if (type == 'add_invoices') {
                    getWData().globalActions.isModalAddInvoice = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalAddInvoice = true;
                    getWData().setRefresh(Math.random());
                }
                if (type == 'add_way_bills') {
                    getWData().globalActions.isModalAddWayBills = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalAddWayBills = true;
                    getWData().setRefresh(Math.random());
                }
                if (type == 'add_bills') {
                    getWData().globalActions.isModalAddBills = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalAddBills = true;
                    getWData().setRefresh(Math.random());
                }
                if (type == 'show_errors') {
                    getWData().globalActions.isModalErrors = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalErrors = true;
                    getWData().setRefresh(Math.random());
                }
                if (type == 'show_contracts_agreement') {
                    getWData().globalActions.isModalContractsAgreement = false;
                    getWData().setRefresh(Math.random());
                    await delay();
                    getWData().globalActions.isModalContractsAgreement = true;
                    getWData().setRefresh(Math.random());
                }
            }}
        >

            {showLoading && <TableLoading baseLoading={true} animate={true} offsetTop={true} />}
            {getPassportCache()?.id && <div className={'tableCanvas__info'}>
                <div className={'tableCanvas__info_item'}><span><img src={'/canvas_table/icon_location.svg'}
                                                                     alt={'location'} /> Регион:</span> {getPassportCache().location ?? getPassportCache().region_title ?? getPassportCache().region_name ?? ''}  &nbsp;
                </div>
                <div className={'tableCanvas__info_item'}><span><img src={'/canvas_table/icon_peoples.svg'}
                                                                     alt={'peoples'} /> РП / СРП:</span> {memberRps} &nbsp;
                </div>
                <div className={'tableCanvas__info_item'}><span><img src={'/canvas_table/icon_speeder.svg'}
                                                                     alt={'speeder'} /> Коэфф-т твердых покрытий:</span> {numberFormatter(getPassportCache().hard_surface_ratio ?? 0, {
                    isInt: false,
                    suffix: ' %',
                })} &nbsp;</div>
                <div className={'tableCanvas__info_item'}><span><img src={'/canvas_table/icon_rectangle.svg'}
                                                                     alt={'rectangle'} /> Площадь здания:</span> {numberFormatter(getPassportCache().total_area ?? 0, {
                    isInt: false,
                    suffix: ' м²',
                })} &nbsp;</div>
            </div>}
            <div
                className={`tableCanvas`}
                style={{
                    opacity: showLoading ? 0 : 1,
                    height: 'calc(100% - var(--visibleHeaderHeight))',
                    paddingLeft: window.outerWidth > sizes.mobile ? '20px' : '0px',
                    paddingRight: window.outerWidth > sizes.mobile ? '20px' : '0px',
                }}
            ></div>
            <span className="super_editor_wrapper">{getWData().cellOpen?.openEdit && <SuperEditorBind />}</span>
            {getWData().cellOpen?.isModalAdd && <ModalAddRowBind />}
            {getWData().cellOpen?.isModalApproveBudget && <ModalApproveBudgetBind />}
            {/*{getWData().cellOpen?.isModalContragent && <ModalContragentBind/>}*/}
            {getWData().cellOpen?.isModalSendApprove && <ModalSendApproveBind />}
            {getWData().cellOpen?.isModalSettings && <ModalSettingsBind renderTable={renderTable} />}
            {getWData().cellOpen?.isModalHistory && <ModalHistoryBind />}
            <ModalAddContractBind key={`modal_add_contract_${refresh}`} isOpen={getWData().globalActions.isModalAddManyContracts!} />
            <ModalAddInvoiceBind key={`modal_add_invoices_${refresh}`} isOpen={getWData().globalActions.isModalAddInvoice!} />
            <ModalAddWayBillsBind key={`modal_add_way_bills_${refresh}`} isOpen={getWData().globalActions.isModalAddWayBills!} />
            <ModalAddBillsBind key={`modal_add_bills_${refresh}`} isOpen={getWData().globalActions.isModalAddBills!} />
            {/*<ModalWarpper type={'invoice'} isOpen={getWData().cellOpen?.isModalInvoice ?? false} />*/}
            {/*<ModalWarpper type={'well_bill'} isOpen={getWData().cellOpen?.isModalWayBill ?? false} />*/}
            {/*<ModalWarpper type={'bill'} isOpen={getWData().cellOpen?.isModalBill ?? false} />*/}
            <WrapModalViewContract isOpen={getWData().cellOpen?.isModalContragent ?? false}
                                   type={TypeModalEnum.contracts} />
            <WrapModalViewContract isOpen={getWData().cellOpen?.isModalInvoice ?? false}
                                   type={TypeModalEnum.invoices} />
            <WrapModalViewContract isOpen={getWData().cellOpen?.isModalWayBill ?? false}
                                   type={TypeModalEnum.way_bills} />
            <WrapModalViewContract isOpen={getWData().cellOpen?.isModalBill ?? false} type={TypeModalEnum.bills} />
            <ModalErrorsBind isOpen={getWData().globalActions.isModalErrors ?? false}
                             passportId={getPassportCache()?.id ?? ''}
                             onClose={() => {
                             }}
            />
            <ModalErrorViewBind
                isOpen={getWData().globalActions.isModalErrorView ?? false}
                isAgreementContract={true}
                errors={(function () {
                    if (getWData().globalActions.isModalErrorView) {
                        const id = `${getWData().globalActions.isModalErrorView}`.split('_').pop();
                        const type = getTypeModalEnum(getWData().globalActions.isModalErrorView.toString().split('_')[0]);

                        const item = getWData().cellOpen?.record?.[getNamePropsWithData(type)]?.[0];

                        const error_ids =  getWData().errorsSimple.filter(error => error.document_id == item?.document_id).map(e => e.id)
                        return getWData().errors.filter(error => error_ids.includes(error.id))
                    }

                    return [];
                })()}
                onAction={() => {
                }}
                typeError={(() => {
                    if (typeof getWData().globalActions.isModalErrorView == 'string') {
                        return getTypeModalEnum(getWData().globalActions.isModalErrorView.toString().split('_')[0]);
                    }
                    if (getWData().cellOpen?.isModalInvoice) return TypeModalEnum.invoices;
                    if (getWData().cellOpen?.isModalWayBill) return TypeModalEnum.way_bills;
                    if (getWData().cellOpen?.isModalBill) return TypeModalEnum.bills;

                    return TypeModalEnum.contracts;
                })()}
                onClose={() => {
                    getWData().globalActions.isModalErrorView = false;
                    setRefresh(Math.random());
                }}
            />
            <ModalContractsAgreementBind isOpen={getWData().globalActions.isModalContractsAgreement ?? false} />
        </LayoutComponent>
    );
}

export default Canvas3Screen;
