import { useQuery, useQueryClient } from 'react-query';
import { Prices } from '../ApiDirectory';
import { PriceNomenclatureRecord, PriceRegions, PriceRowInline } from '../screens/prices/PricesListHelper';
import { uniqBy } from 'lodash';
import { directoryApi } from '../shared/api_client';

export function usePricesTable() {
  const {
    data: prices,
    error: pricesError,
    isLoading: pricesLoading,
  } = useQuery({
    queryKey: [`prices`],
    queryFn: async (): Promise<{ rows: PriceRowInline[], regions: PriceRegions[] }> => {
      const res2 = await directoryApi.nomenclaturePrices.nomenclaturePricesList();

      const response = res2.data;

      const res: PriceRowInline[] = [];

      if (response.data) {
        for (const section of response.data) {
          const key = response.data.indexOf(section);
          res.push({
            id: `section-${key}`,
            section_id: '',
            type_id: '',
            type: 'section',
            title: section.work_sections_title ?? '',
            order: section.work_sections_order ?? 0,
            regions: [],
          });

          if (section.work_types) {
            for (const workType of section.work_types) {
              const key2 = section.work_types.indexOf(workType);
              res.push({
                id: `work_type-${key2}`,
                section_id: `section-${key}`,
                type_id: `work_type-${key2}`,
                type: 'work_type',
                title: workType.work_types_title ?? '',
                order: workType.work_types_order ?? 0,
                regions: [],
              });


              if (workType.prices) {
                const uniqNomenclatures = uniqBy(workType.prices, 'nomenclature_id');

                for (const nomenclature of uniqNomenclatures) {
                  const nomenclatures = workType.prices.filter(e => e.nomenclature_id == nomenclature.nomenclature_id);

                  const price = nomenclatures[0];
                  res.push({
                    id: price.nomenclature_id ?? '',
                    section_id: `section-${key}`,
                    type_id: `work_type-${key2}`,
                    title: price.nomenclature_title ?? '',
                    order: price.nomenclature_order ?? 0,
                    type: 'nomenclature',
                    regions: (response.filters?.regions ?? []).map(e => ({
                      id: e.id ?? '',
                      title: e.title ?? '',
                      price: nomenclatures.find(e3 => e3.region_id == e.id)?.price ?? 0,
                    })),
                  });
                }

              }
            }

          }

        }
      }

      console.log({ res });

      return {
        rows: res,
        regions: (response.filters?.regions ?? []).map(e => ({ id: e.id ?? '', title: e.title ?? '' })),
      };
    },
  });

  const queryClient = useQueryClient();

  const clearCache = () => {
    queryClient.invalidateQueries(['prices']);
  };


  return {
    prices: prices?.rows ?? [],
    pricesRegions: prices?.regions ?? [],
    pricesError,
    pricesLoading,
    pricesClearCache: clearCache,
  };
}
