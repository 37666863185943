import { ListGrid } from 'cheetah-grid';
import { getOffsetX } from '../../../shared/getOffsetX';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { getWData } from '../getWData';

export function checkBtnSettings(
  event: MouseEvent,
  col: number,
  row: number,
  columnKey: keyof TableRecord,
  grid: ListGrid<TableRecord>,
) {
  if (!(columnKey == 'cns_budget_fakt_far' && row == 0)) return false;

  const cell = getWData().grid.getCellRect(col, row);
  let left = getOffsetX(event) - cell.left + grid.scrollLeft;
  return left > cell.width - 30;
}
