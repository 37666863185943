import { getWData } from '../../table_extends/getWData';
import { getEmptyTableRecord, TableRecord } from '../components/TableRecord';
import { ListGrid } from 'cheetah-grid';
import { getTableHeaders, tableHeaders } from '../../table_extends/table_header/table_headers';
import { getTableColumnKey } from '../../table_extends/getTableColumnKey';
import { checkOpenModalAdd } from '../../table_extends/check_click/checkOpenModalAdd';
import { checkBtnSendApprove } from '../../table_extends/check_click/checkBtnSendApprove';
import { checkOpenModalApproveBudget } from '../../table_extends/check_click/checkOpenModalApproveBudget';
import {
    checkOpenModalContragent,
    checkOpenModalContragentView
} from '../../table_extends/check_click/checkOpenModalContragent';
import { checkBtnSettings } from '../../table_extends/check_click/checkBtnSettings';
import moment from 'moment/moment';
import { allowOpenEditor } from '../../table_extends/allowEditCell';
import { checkEditPlanedDates } from '../../table_extends/check_click/checkEditPlanedDates';
import { getResultRows } from '../../table_extends/getResultRows';
import { getTableBody, TableMode } from '../../table_extends/table_body/table_body';
import { closeInput } from './closeInput';
import { openEditor } from './openEditor';
import {checkOpenModalInvoice, checkOpenModalInvoiceView} from "../../table_extends/check_click/checkOpenModalInvoice";
import {
    checkOpenModalWellBill,
    checkOpenModalWellBillView
} from "../../table_extends/check_click/checkOpenModalWellBill";
import {checkOpenModalBill, checkOpenModalBillView} from "../../table_extends/check_click/checkOpenModalBill";
import { checkPinnedNumber } from '../../table_extends/check_click/checkPinnedNumber';
import { checkPinnedUnit } from '../../table_extends/check_click/checkPinnedUnit';
import { checkPinnedTitle } from '../../table_extends/check_click/checkPinnedTitle';
import { updStorePinned } from '../../../shared/updStorePinned';
import {
    checkAddBillIcon,
    checkAddContractIcon,
    checkAddInvoiceIcon,
    checkAddWayBillIcon,
} from '../../table_extends/check_click/checkAddContractIcon';
import { delay } from '../../../shared/delay';

export async function onCellClick({
                                col,
                                row,
                                event,
                            }: {
    col: number;
    row: number;
    event: MouseEvent;
}) {
    console.log(
        'onCellClick',
        { col, row },
        event,
        event.offsetX -
        getWData().grid.getCellRect(col, row).left +
        getWData().grid.scrollLeft,
    );

    let resultRows: TableRecord[] = getWData().resultRows;
    const grid: ListGrid<TableRecord> = getWData().grid;

    const currentRow: TableRecord = resultRows[row - tableHeaders.length] ?? getEmptyTableRecord();
    console.log('onCellClick', currentRow, getTableColumnKey(col, getWData().body));

    if (getWData().cellOpen?.openEdit) {
        closeInput(
            getWData().cellOpen?.col ?? -1,
            getWData().cellOpen?.row ?? -1,
            getWData().textInput,
        );
    }

    const columnKey = getTableColumnKey(col, getWData().body);
    const columnIsView =  !getWData().excludedColumns.includes(columnKey);

    getWData().cellOpen = {
        record: currentRow,
        col,
        row,
        cell: getWData().grid.getCellRect(col, row),
        columnKey,
        isModalAdd: checkOpenModalAdd(event, grid, row, col, currentRow),
        isModalSendApprove: checkBtnSendApprove(event, col, row, grid, currentRow),
        isModalApproveBudget: checkOpenModalApproveBudget(col, row, currentRow),
        isModalContragent: checkOpenModalContragent(col, row, currentRow),
        isModalInvoice: checkOpenModalInvoice( col, row, currentRow),
        isModalWayBill: checkOpenModalWellBill(col, row, currentRow),
        isModalBill: checkOpenModalBill(col, row, currentRow),
        isModalSettings: checkBtnSettings(event, col, row, columnKey, grid),
        isModalAddContract: checkAddContractIcon(columnKey, currentRow),
        isModalHistory: false,
        openEdit: false,
        onCancelEdit: undefined,
        timeOpen: moment(),
    };

    if (checkOpenModalContragentView(col, row, currentRow)) {
        getWData().globalActions.isModalErrorView = `${currentRow.cns_contracts?.[0]?.id}`
        getWData().setRefresh(Math.random());
    }

    if (checkOpenModalInvoiceView(col, row, currentRow)) {
        getWData().globalActions.isModalErrorView = `invoices_${currentRow.cns_invoices?.[0]?.id}`
        getWData().setRefresh(Math.random());
    }

    if (checkOpenModalWellBillView(col, row, currentRow)) {
        getWData().globalActions.isModalErrorView = `way_bills_${currentRow.cns_way_bills?.[0]?.id}`
        getWData().setRefresh(Math.random());
    }

    if (checkOpenModalBillView(col, row, currentRow)) {
        getWData().globalActions.isModalErrorView = `bills_${currentRow.cns_bills?.[0]?.id}`
        getWData().setRefresh(Math.random());
    }

    if (checkAddContractIcon(columnKey, currentRow)) {
        console.log(`checkAddContractIcon`);
        if (getWData().globalActions.isModalAddManyContracts) {
            getWData().globalActions.isModalAddManyContracts = false;
            getWData().setRefresh(Math.random());
            await delay(200);
        }
        getWData().globalActions.isModalAddManyContracts = getWData().cellOpen?.record.cns_nomenclature_id ?? true;
        getWData().setRefresh(Math.random());
    }
    if (checkAddInvoiceIcon(columnKey, currentRow)) {
        console.log(`checkAddInvoiceIcon`);
        if (getWData().globalActions.isModalAddInvoice) {
            getWData().globalActions.isModalAddInvoice = false;
            getWData().setRefresh(Math.random());
            await delay(200);
        }
        getWData().globalActions.isModalAddInvoice = getWData().cellOpen?.record.cns_contracts[0]?.id ?? true;
        getWData().setRefresh(Math.random());
    }
    if (checkAddWayBillIcon(columnKey, currentRow)) {
        console.log(`checkAddWayBillIcon`);
        if (getWData().globalActions.isModalAddWayBills) {
            getWData().globalActions.isModalAddWayBills = false;
            getWData().setRefresh(Math.random());
            await delay(200);
        }
        getWData().globalActions.isModalAddWayBills = getWData().cellOpen?.record.cns_contracts[0]?.id ?? true;
        getWData().setRefresh(Math.random());
    }
    if (checkAddBillIcon(columnKey, currentRow)) {
        console.log(`checkAddBillIcon`);
        if (getWData().globalActions.isModalAddBills) {
            getWData().globalActions.isModalAddBills = false;
            getWData().setRefresh(Math.random());
            await delay(200);
        }
        getWData().globalActions.isModalAddBills = getWData().cellOpen?.record.cns_contracts[0]?.id ?? true;
        getWData().setRefresh(Math.random());
    }

    getWData().moveCell = {
        col,
        row,
        record: currentRow,
        event,
    };

    if (!getWData().cellOpen || (getWData().cellOpen && (getWData().cellOpen!.isModalAdd || getWData().cellOpen!.isModalApproveBudget || getWData().cellOpen!.isModalContragent))) {
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalApproveBudget') && getWData().cellOpen!.isModalApproveBudget) {
        getWData().cellOpen!.isModalApproveBudget = false;
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalSendApprove') && getWData().cellOpen!.isModalSendApprove) {
        getWData().cellOpen!.isModalSendApprove = false;
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalContragent') && getWData().cellOpen!.isModalContragent) {
        getWData().cellOpen!.isModalContragent = false;
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalInvoice') && getWData().cellOpen!.isModalInvoice) {
        getWData().cellOpen!.isModalInvoice = false;
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalWellBill') && getWData().cellOpen!.isModalWayBill) {
        getWData().cellOpen!.isModalWayBill = false;
        getWData().setRefresh(Math.random());
    }

    if (document.querySelector('.ModalBill') && getWData().cellOpen!.isModalBill) {
        getWData().cellOpen!.isModalBill = false;
        getWData().setRefresh(Math.random());
    }

    let expandedLevel1 = getWData().expandedLevel1;
    let expandedLevel2 = getWData().expandedLevel2;
    let expandedLevel3 = getWData().expandedLevel3;
    let expandedLevel4 = getWData().expandedLevel4;
    let rows2 = getWData().rows2 as TableRecord[];

    if ([0, 1, 2, 3, 4, 5].includes(resultRows[row - tableHeaders.length]?.cns_level)) {
        if (checkBtnSendApprove(event, col, row, grid, currentRow)) {
            getWData().setRefresh(Math.random());
            return;
        }
    }

    const stopExpanded =
        checkOpenModalAdd(event, grid, row, col, currentRow) ||
        checkBtnSendApprove(event, col, row, grid, currentRow) ||
        checkOpenModalApproveBudget(col, row, currentRow) ||
        checkEditPlanedDates(columnKey, currentRow) ||
        checkOpenModalContragent(col, row, currentRow) ||
        checkOpenModalInvoice( col, row, currentRow) ||
        checkOpenModalWellBill(col, row, currentRow) ||
        checkOpenModalBill(col, row, currentRow) ||
        checkAddContractIcon(columnKey, currentRow) ||
        checkAddInvoiceIcon(columnKey, currentRow) ||
        checkAddWayBillIcon(columnKey, currentRow) ||
        checkAddBillIcon(columnKey, currentRow) ||
        checkOpenModalContragentView(col, row, currentRow) ||
    !columnIsView;

    console.log({stopExpanded});

    if (!stopExpanded) {
        if (currentRow.cns_row_type == 'section' && !getWData().cellOpen?.isModalAdd) {
            // console.log(`open modal add`);
            let id = currentRow.cns_section_id;

            if (expandedLevel1.includes(id)) {
                expandedLevel1 = expandedLevel1.filter((e: string) => e !== id);

                const ids3 = rows2
                    .filter((e) => e.cns_section_id === id)
                    .map((e) => `${e.cns_section_id}_${e.cns_group_id}`);

                expandedLevel2 = expandedLevel2.filter((e: any) => !ids3.includes(e));
            } else {
                expandedLevel1 = [...expandedLevel1, id].sort();
            }
        }

        if (currentRow.cns_row_type == 'work_type' && !getWData().cellOpen?.isModalAdd) {
            let id = `${currentRow.cns_section_id}_${currentRow.cns_group_id}`;

            if (expandedLevel2.includes(id)) {
                expandedLevel2 = expandedLevel2.filter((e) => e !== id);
            } else {
                expandedLevel2 = [...expandedLevel2, id].sort();
            }
        }

        if (currentRow.cns_row_type == 'nomenclature' && !getWData().cellOpen?.isModalAdd && currentRow.cns_contracts.length) {
            let id = `${currentRow.cns_section_id}_${currentRow.cns_group_id}_${currentRow.cns_nomenclature_id}`;

            if (expandedLevel3.includes(id)) {
                expandedLevel3 = expandedLevel3.filter((e) => e !== id);
            } else {
                expandedLevel3 = [...expandedLevel3, id].sort();
            }
        }

        if (currentRow.cns_row_type == 'contract' && !getWData().cellOpen?.isModalAdd) {
            let id = `${currentRow.cns_id}`;

            if (expandedLevel4.includes(id)) {
                expandedLevel4 = expandedLevel4.filter((e) => e !== id);
            } else {
                expandedLevel4 = [...expandedLevel4, id].sort();
            }
        }

        getWData().expandedLevel1 = [...expandedLevel1];
        getWData().expandedLevel2 = [...expandedLevel2];
        getWData().expandedLevel3 = [...expandedLevel3];
        getWData().expandedLevel4 = [...expandedLevel4];

        let rows = getResultRows();

        getWData().resultRows = rows;

        grid.records = rows;
    }

    if (allowOpenEditor(currentRow, columnKey) && columnIsView) {
        openEditor(col, row, currentRow);
    }

    const base = {
        numberPinned: false,
        titlePinned: false,
        unitPinned: false,
    };

    const isClickPinnedNumber = checkPinnedNumber(event, col, row, grid);
    const isClickPinnedTitle = checkPinnedTitle(event, col, row, grid);
    const isClickPinnedUnit = checkPinnedUnit(event, col, row, grid);
    const pinned = getWData().pinned;

    if (isClickPinnedNumber) {
        getWData().pinned.titlePinned = false;
        getWData().pinned.unitPinned = false;

        getWData().pinned.numberPinned = !getWData().pinned.numberPinned;
        updStorePinned();
    }

    if (isClickPinnedTitle) {
        getWData().pinned.numberPinned = true;
        getWData().pinned.unitPinned = false;

        getWData().pinned.titlePinned = !getWData().pinned.titlePinned;
        updStorePinned();
    }

    if (isClickPinnedUnit) {
        getWData().pinned.numberPinned = true;
        getWData().pinned.titlePinned = true;
        getWData().pinned.unitPinned = !getWData().pinned.unitPinned;
        updStorePinned();
    }


    grid.layout = {
        header: getTableHeaders(),
        body: getTableBody(TableMode.VIEW),
    };

    grid.frozenColCount =  (pinned.numberPinned ? 1 : 0) + ( pinned.titlePinned ? 2 : 0) + ( pinned.unitPinned ? 1 : 0);


    getWData().moveCell = { col, row, record: currentRow };
    grid.invalidateCell(col, row);

    // if (checkOpenModalContragent(col, row, currentRow)) {
    //     getWData().setRefresh(Math.random())
    // }
    getWData().setRefresh(Math.random());
}
