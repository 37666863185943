import React, { useContext, useMemo } from 'react';
import { HolderOutlined, MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Input, Modal, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { directoryApi } from "../../shared/api_client";
import { delay } from "../../shared/delay";

export interface DirectionDataType {
    id: string;
    key: string;
    title: string;
}

interface SortableRowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

export const SortableRowContext = React.createContext<SortableRowContextProps>({});

export const SortableDragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(SortableRowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

interface SortableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

export const SortableRow: React.FC<SortableRowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<SortableRowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <SortableRowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </SortableRowContext.Provider>
    );
};

export function doCreateRegion(regionClearCache: () => void) {
    let title = '';
    const modal = Modal.confirm( {
        className: 'modal_direction',
        title: 'Создание нового региона',
        icon: null,
        content: <>
            <Input placeholder={ 'Название региона' } autoFocus
                   onChange={ e => title = e.target.value }
            />
        </>,
        footer: <div className={ 'modal_btns' }>
            <Button type={ 'link' } onClick={ () => {
                modal.destroy();
            } }>Отмена</Button>
            <Button type={ 'primary' } size={ 'small' } onClick={ async () => {
                await directoryApi.regions.regionsCreate( { title } );
                await delay( 100 );
                regionClearCache();
                modal.destroy();
            } }>Ок</Button>
        </div>,
    } );

}
