import { Collapse, CollapseProps, Space, Switch, Table, TableColumnsType, Tabs, TabsProps, Tree, TreeDataNode, TreeProps } from 'antd';
import { Children, FunctionComponent, useEffect, useState } from 'react';
import LayoutComponent from '../../shared/layout_component';
import { TableProps } from 'antd/lib';
import { RefTable } from 'antd/es/table/interface';
import { api } from '../../shared/api_client';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProjectListStatus, useProjectList } from '../../actions/useProject';
import { FCTableInvoicesList } from './FCTableInvoicesList';
import { InvoiceProjectItem, InvoiceProjectsList } from '../../ApiEpo';

interface FCProjectListTreeProps {
    data: InvoiceProjectItem[]
    selectProject: (val: {section_id: string, project_id: string})=>void
}

const FCProjectListTree: FunctionComponent<FCProjectListTreeProps> = ({ ...props }) => {
    const [defaultStatus] = useState<ProjectListStatus>('on_work')
    const [tree, setTree] = useState<TreeDataNode[]>();

    const [invoiceGrouped, setInvoiceGrouped] = useState<boolean>(false)
    const [refreshData, setRefreshData] = useState<boolean>(false)

    const getProjectListTree = (projects: InvoiceProjectItem[]): TreeDataNode[] => {
        if (projects)
            return [...projects?.map((item, index1) => {
                if (item.sections && Array.isArray(item.sections)) {
                    return {
                        title: item.title ?? '',
                        key: `index-${index1}-item-${item.id}`,
                        children: item.sections.map((item2, index2) => {
                            return {
                                title: item2.section_name ?? '',
                                key: `index-${index1}-${index2}`,
                                section_id: item2.section_id,
                                project_id: item2.project_id
                            }
                        })
                    }
                } else {
                    return {
                        title: item.title ?? '',
                        key: `index-${index1}-item-${item.id}`,
                        children: []
                    };
                }

            })]
        return []
    }
    const onSelect: TreeProps['onSelect'] = (selectedKeys, info: any) => {
        props.selectProject({section_id: info.node.section_id, project_id: info.node.project_id})
    };

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const firstItem = props.data[0];
            if(firstItem.sections && firstItem.sections.length > 0){

                const project_id = firstItem.sections[0].project_id;
                const section_id = firstItem.sections[0].section_id;

                if ( project_id && section_id ) {
                    props.selectProject( { section_id: section_id, project_id: project_id })
                }
            }
        }
    }, [props.data])

    useEffect(() => {
        if (props.data) {
        setTree(getProjectListTree(props.data))
        }
    }, [props.data])

    return (
        <div className='FC-tree'>
            {tree ?
            <Tree
                defaultExpandedKeys={tree && tree.length > 0 ? [tree[0].key] : []}
                defaultSelectedKeys={['index-0-0']}
                onSelect={onSelect}
                treeData={tree}
            />
             
             : null}
        </div>
    );
};

export default FCProjectListTree;
