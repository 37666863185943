import { useQuery } from "react-query";
import { api } from "../shared/api_client";

export const useInvoiceStatusesList = () => {
  const {
    data: invoiceStatusesList,
    error: invoiceStatusesListError,
    isLoading: invoiceStatusesListLoading,
  } = useQuery({
    queryKey: ['invoiceStatusesList'],
    queryFn: () => api.helpers.invoiceStatusesList().then((e) => e.data),
  })

  return {
    invoiceStatusesList,
    invoiceStatusesListError,
    invoiceStatusesListLoading,
  };
}
