import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { TableRecord } from '../../canvas_table/components/TableRecord';

export function checkEditPlanedDates(colunmKey: keyof TableRecord, record: TableRecord) {
  return (
    (colunmKey == 'cns_plane_date_start' || colunmKey == 'cns_plane_date_end') &&
    tokenActions.planeDates == AccessItem.EDIT &&
    record.cns_row_type != ''
  );
}
