import {useQuery} from 'react-query';
import {api} from '../shared/api_client';
import {getProjectLocationsDb, syncProjectLocations} from "../indexedb";
import {message} from "antd";

export function useLocationList() {
  const {
    data: locations,
    error: locationsError,
    isLoading: locationsLoading,
  } = useQuery({
    queryKey: [`regions`],
    queryFn: async () => {
      try {
        const res = (await api.helpers.projectLocationsList().then((e) => e.data ?? [])).data?.map(e => e ?? '') ?? [];
        const mappedRes = res.map( e => ({id: e.id ?? e, title: e.title ?? e}))
        await syncProjectLocations(mappedRes)
        return {data: mappedRes};
      } catch (e) {
        message.error('Не удалось получить актуальный список регионов');
        return Promise.resolve({data: (await getProjectLocationsDb())});
      }
    },
  });

  return {
    locations,
    locationsError,
    locationsLoading,
  };
}
