import { DatePicker } from 'antd';
import { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React from 'react';

export function stopEvent(event: React.MouseEvent<HTMLElement, MouseEvent>) {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
}

export const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
