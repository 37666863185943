import {FunctionComponent} from "react";

export const Load: FunctionComponent<{ wrap?: boolean }> = (props) => {
    const res = (
        <div className="load">
            <img src="/layout/loading.gif" alt=""/>
        </div>
    );

    if (props.wrap) {
        return <div style={{minHeight: props.wrap ? "200px" : undefined}}>
            {res}
        </div>
    }

    return res;
}
