import { getWData } from '../../table_extends/getWData';
import { TableMode } from '../../table_extends/table_body/table_body';

export function onCellLeave(param: any) {
  if (getWData().tableMode === TableMode.SCROLL) return;
  const { col, row } = param;
  getWData().moveCell = undefined;

  getWData().grid?.invalidateCell(col, row);
  getWData().grid?.invalidateCell(3, row);

  getWData().setRefresh(Math.random());
}
