import { ProjectDetail, ProjectError, ProjectSimpleError, ProjectTable } from '../../ApiEpo';
import { api } from '../../shared/api_client';
import {
  ApiBills,
  ApiInvoice,
  ApiWayBills,
  getEmptyTableRecord,
  RecordStatus,
  TableRecord,
} from '../canvas_table/components/TableRecord';
import { allowSendApproveBudget } from './allowEditCell';
import {
  LoadDataCompleted,
  LoadDataContractors,
  LoadDataDiffBp,
  LoadDataFakt,
  LoadDataInvoice,
} from '../../Models/LoadData';
import { dateFormatter, parseDate } from '../../shared/dateFormat';
import {
  DocumentDb, getContractorsDb,
  getDocumentsDb,
  getProjectTableDb,
  updateProjectDetailDb,
  updateProjectTableDb,
} from '../../indexedb';
import { message } from 'antd';
import { IApiInvoice2 } from '../../Models/InvoiceForm';
import { IApiContragent2 } from '../../Models/ContragentForm';
import { getErrorsTableView } from './getErrorsTableView';
import { getWData } from './getWData';
import { getResultRows } from './getResultRows';
import { createDetailLines } from './loadDataDocuments';
import {delay} from "../../shared/delay";

const loadDataInfo: LoadData = {
  id: window.location.href.includes('/passport/') ? window.location.href.split('/').pop() || '' : '',
  passport: {},
  headers: [],
  hidden: [],
  rowsTable: {
    rows: [],
    rows2: [],
    rows3: [],
    rows4: [],
    rows5: [],
  },
  errors: [],
  errorsSimple: [],
  errorsTableView: {},
  // usersGrp: [],
};

(window as any).loadDataInfo = loadDataInfo;

(window as any).subscribeUpdates = [];

export function getPassportCache(): ProjectDetail {
  return (window as any).loadDataInfo?.passport;
}

export function getLoadDataInfo(): LoadData {
  return (window as any).loadDataInfo;
}

export interface LoadData {
  id: string,
  headers: string[];
  hidden: string[];
  passport: ProjectDetail;
  rowsTable: {
    rows: TableRecord[],
    rows2: TableRecord[],
    rows3: TableRecord[],
    rows4: TableRecord[],
    rows5: TableRecord[],
  },
  errors: ProjectError[],
  errorsSimple: ProjectSimpleError[],
  errorsTableView: { [x: string]: { [x: string]: string } }
  // usersGrp: User[],
}

async function loadDataDone(id: string, res: LoadData) {
  await updateProjectTableDb({ ...res, id });

  (window as any).loadDataInfo = res;

  callEventBus('updated-errors')
}

async function loadDataDoneErrors(res: LoadData) {

  getWData().resultRows = getResultRows();
  getWData().errors = res.errors;
  getWData().errorsSimple = res.errorsSimple;
  getWData().errorsTableView = getErrorsTableView(res.errors ?? [], res.rowsTable.rows ?? [], res.rowsTable.rows2 ?? [], res.rowsTable.rows3 ?? []);
}

export async function loadData(id: string): Promise<LoadData> {
  console.log('loadData', id);
  let projectDetail: ProjectDetail;
  let projectTable: ProjectTable;
  let errors: ProjectError[];
  let errorsSimple: ProjectSimpleError[];
  // let usersGrp: User[] = [];

  try {
    const passport = await api.projects.projectsDetail(id);
    const res = await api.projects.tableDetail(id);
    const errorsRes = await api.errors.errorsList({ all: 1, project_id: id });
    const errorsSimpleRes = await api.errors.simpleList({ all: 1, project_id: id });
    // const usersGrpRes = await api.helpers.cpmUsersList({page: 1, per_page: 1000});
    projectDetail = passport.data;
    projectTable = res.data;
    errors = errorsRes.data?.data ?? [];
    errorsSimple = errorsSimpleRes.data?.data ?? [];
    // usersGrp = usersGrpRes.data?.data ?? [];
    await updateProjectDetailDb(projectDetail);
  } catch (e) {
    console.log(e);
    message.error('Проект открыт в оффлайн режиме');

    const res = await getProjectTableDb(id);

    await loadDataDone(id, res);

    return res;
  }

  const contractorsDb = getWData().db.dbContractors;

  if (contractorsDb.length == 0) {
    getWData().db.dbContractors = await getContractorsDb({page: 1, perPage: 100000});
    if (getWData().db.dbContractors.length == 0) {
      const res = await getWData().db.loadAllContragents();
      getWData().db.dbContractors = res?.data ?? [];
    }
  }

  loadDataInfo.passport = projectDetail;

  const result: TableRecord[] = [];
  if (projectTable.body) {
    loadDataInfo.headers = projectTable.headers ?? [];
    loadDataInfo.hidden = projectTable.hidden ?? [];

    const documents = await getDocumentsDb(id);

    for (const record of projectTable.body) {
      const line = mapRow(record, projectTable.headers ?? [], projectDetail, documents);
      result.push(line);

      if (line.cns_contracts?.length) {
        result.push(...createDetailLines(line));
      }
    }
  }

  const items = result
    .filter(e => e.cns_row_type == 'nomenclature');

  items
    .forEach(line => line.cns_allow_send_approve = allowSendApproveBudget(line));

  result
    .filter(e => e.cns_row_type == 'work_type')
    .forEach(line => {
      const items2 = items.filter(e => e.cns_group_id == line.cns_group_id);
      if (items2.length == 0) return;
      line.cns_allow_send_approve = !!items2.find(e => allowSendApproveBudget(e));

      if (result.find(item => item.cns_row_type == 'nomenclature' && item.cns_group_id === line.cns_group_id && item.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) {
        line.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
      }
    });

  result
    .filter(e => e.cns_row_type == 'section')
    .forEach(line => {
      const items2 = items.filter(e => e.cns_section_id == line.cns_section_id);
      if (items2.length == 0) return;
      line.cns_allow_send_approve = !!items2.find(e => allowSendApproveBudget(e));

      if (result.find(item => item.cns_row_type === 'nomenclature' && item.cns_section_id === line.cns_section_id && item.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) {
        line.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
      }
    });

  for (let item of result) {
    if (item.cns_row_type == 'work_type') {
      const parent = result.find(e => e.cns_id == item.cns_parent_id || e.cns_group_id == item.cns_parent_id || e.cns_section_id == item.cns_parent_id);
      if (parent) {
        item.cns_parent_number = parent.cns_number;
      }
    }
    if (item.cns_row_type == 'nomenclature') {
      const parent = result.find(e => `${e.cns_parent_id2}` == `${item.cns_group_id}`);
      if (parent) {
        item.cns_parent_number = parent.cns_number;
      }
    }
    if (item.cns_row_type == 'contract') {
      // const invoices = result.filter(e => e.cns_parent_id2 == item.cns_id);
      // item.cns_invoice_size = invoices.reduce((s, e) => s + e.cns_invoice_size, 0);
      // item.cns_invoice_sum = invoices.reduce((s, e) => s + e.cns_invoice_sum, 0);
      // item.cns_invoice_price = item.cns_invoice_sum / item.cns_invoice_size;
      //
      // console.log({invoices});



      for (const contractorKey in item.cns_contractors) {
        const contractor = item.cns_contractors[contractorKey];

        const contractorDb = contractorsDb.find(c => c.id == contractor.value);

        if (contractorDb) {
          const color = contractorDb.rating_label === 'Желтый' ? '#fff7af' : contractorDb.rating_label === 'Зеленый' ? '#bbffce' : contractorDb.rating_label === 'Черный' ? 'black' : contractorDb.rating_label === 'Без рейтинга' ? undefined : undefined;

          contractor.item = contractorDb;
          contractor.color = color;
        }
      }
    }
  }

  console.log({ result });

  const rowsTable = {
    rows: result.filter((e) => e.cns_row_type == 'section'),
    rows2: result.filter((e) => e.cns_row_type == 'work_type'),
    rows3: result.filter((e) => e.cns_row_type == 'nomenclature'),
    rows4: result.filter((e) => e.cns_row_type == 'contract'),
    rows5: result.filter((e) => e.cns_row_type == 'detail'),
  };

  const res: LoadData = {
    id,
    headers: loadDataInfo.headers,
    hidden: loadDataInfo.hidden,
    passport: projectDetail,
    rowsTable,
    errors,
    errorsSimple,
    errorsTableView: getErrorsTableView(errors, rowsTable.rows, rowsTable.rows2, rowsTable.rows3),
    // usersGrp,
  };

  await loadDataDone(id, res);
  loadDataDoneErrors(res);

  return res;
}

function mapRow(record: string[], headers: string[], passport: ProjectDetail, documents: DocumentDb[]): TableRecord {
  const line: TableRecord = getEmptyTableRecord();

  for (let index = 0; index < record.length; index++) {
    const header = headers[index];

    switch (header) {
      case 'project_id':
        break;
      case 'parent_id':
        line.cns_parent_id = record[index];
        line.cns_parent_id2 = record[index];
        break;
      case 'level':
        line.cns_level = record[index] as any;
        break;
      case 'section_id':
        line.cns_section_id = record[index];
        break;
      case 'type_id':
        line.cns_group_id = record[index];
        break;
      case 'nomenclature_id':
        line.cns_nomenclature_id = record[index];
        break;
      case 'row_type':
        line.cns_row_type = record[index] as any;
        break;
      case 'nomenclature_type':
        break;
      case 'row_number':
        line.cns_number = record[index];
        break;
      case 'title':
        line.cns_title = record[index];
        break;
      case 'unit':
        line.cns_ed_izm = record[index];
        break;
      case 'bp_is_approved':
        if (record[index]) {
          line.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
        }
        break;
      case 'guideline_amount':
        break;
      case 'members':
        break;
      case 'bp_volume':
        line.cns_plane_size = record[index];
        break;
    }

    if (typeof header === 'object' && (header as any).hasOwnProperty('length')) {
      if ((header as any).includes('plan_volume')) {
        line.cns_budget_plan_size = record[index]?.[0] || '';
        line.cns_budget_plan_price = record[index]?.[1] || '';
        line.cns_budget_plan_sum_wat = record[index]?.[2] || '0';
        line.cns_budget_plan_far = record[index]?.[3] ?? '0';
        line.cns_budget_plan_far2 = record[index]?.[4] ?? '0';

        if (record[index][5]) {
          if (record[index][5] === 1 as any) {
            line.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            line.cns_has_sended_approve = record[index]?.[6] as any;
          }
          if (record[index][5] === 2 as any) {
            line.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
            line.cns_has_sended_approve = record[index]?.[6] as any;
          }
          if (record[index][5] === 3 as any) {
            line.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            line.cns_has_sended_approve = record[index]?.[6] as any;
          }
        }
      }

      if ((header as any).includes('fact_volume')) {
        line.cns_budget_fakt_size = record[index]?.[0];
        line.cns_budget_fakt_price = record[index]?.[1];
        line.cns_budget_fakt_sum_wat = record[index]?.[2];
        line.cns_budget_fakt_far = record[index]?.[3];
        line.cns_budget_fakt_last_change = record[index]?.[4];
      }

      if ((header as any).includes('start_date')) {
        line.cns_plane_date_start = parseDate(record[index]?.[0]);
        line.cns_plane_date_end = parseDate(record[index]?.[1]);

      }
      if ((header as any).includes('planning_date')) {
        line.cns_contact_date_start = parseDate(record[index]?.[0]);
        line.cns_contact_date_end = parseDate(record[index]?.[1]);
        line.cns_contact_date = parseDate(record[index][2]);
        line.cns_contractors = ((record[index][4] as any) as LoadDataContractors[])?.filter((e) => e)?.map(e => ({
          label: e.name,
          value: e.id,
        })) ?? [];
        line.cns_responsibles = record[index][5] as any ?? [];

        line.cns_contracts = [];
        if (record[index][3]) {
          for (let i = 0; i < record[index]?.[3]?.length; i++) {
            const item: any = record[index][3]?.[i];
            if (item)
              line.cns_contracts.push({
                id: item.id,
                document_id: item.document_id,
                project_contract_data_id: item.project_contract_data_id,
                parent_id: line.cns_id,
                parent_contract_id: item.contract_id ?? null,
                contragent: item.contractor,
                contragent_id: item.contractor_id,
                doc_number: item.contract_number,
                doc_date: parseDate(item.contract_date),
                date_start: parseDate(item.start_date),
                date_end: parseDate(item.end_date),
                price: item.price,
                size: item.volume,
                sum_wat: item.amount,
                documents: item.files,
                files: item.files?.length ?? 0,
                status: item.status,
              });
          }
        }

        for (let document of documents.filter(e => e.type === 'contract')) {
          const item: IApiContragent2 = document.item as any;
          const nomenclature = document.item.nomenclatures.find((e) => e.nomenclature_id == line.cns_nomenclature_id);

          if (nomenclature) {
            line.cns_contracts.push({
              id: document.id,
              document_id: document.id,
              project_contract_data_id: null,
              parent_id: line.cns_id,
              parent_contract_id: null,
              contragent: item.contractor,
              contragent_id: item.contractor_id,
              doc_number: item.contract_number,
              doc_date: parseDate(item.contract_date),
              date_start: parseDate(item.start_date),
              date_end: parseDate(item.end_date),
              price: `${nomenclature?.price ?? 0}`,
              size: `${nomenclature?.volume ?? 0}`,
              sum_wat: `${nomenclature?.amount ?? 0}`,
              files: item.files?.length ?? 0,
              documents: item.files as any,
              status: '',
              error_action: document.error_action,
            });
          }
        }

        if (line.cns_row_type == 'contract') {
          line.cns_budget_fakt_size = `${line.cns_contracts.reduce((a, b) => a + Number(b.size), 0)}`;
          line.cns_budget_fakt_price = `${line.cns_contracts.reduce((a, b) => a + Number(b.price), 0) / line.cns_contracts.length}`;
        }
        line.cns_budget_fakt_sum_wat = `${line.cns_contracts.reduce((a, b) => a + Number(b.sum_wat), 0)}`;
      }

      if ((header as any).includes('invoice_count')) {
        const _row: { [x: string]: any } = record[index] as any;
        for (let index in (header as LoadDataInvoice[])) {
          const columnName = (header as LoadDataInvoice[])[index];

          switch (columnName) {
            case 'invoice_count':
              line.cns_invoice_count = _row[index];
              break;
            case 'invoice_date':
              line.cns_invoice_date = parseDate(_row[index]);
              break;
            case 'invoice_volume':
              line.cns_invoice_size = _row[index] ?? '';
              break;
            case 'invoice_price':
              line.cns_invoice_price = _row[index] ?? '';
              break;
            case 'invoice_amount':
              line.cns_invoice_sum = _row[index] ?? '';
              break;
            case 'invoice_status':
              line.cns_invoice_status = _row[index];
              break;
            case 'invoices':
              line.cns_invoices = [];
              for (let i = 0; i < _row[index]?.length; i++) {
                const item: ApiInvoice = _row[index]?.[i];
                if (item)
                  line.cns_invoices.push({
                    id: item.id,
                    document_id: item.document_id,
                    project_contract_data_id: item.project_nomenclature_id,
                    parent_id: line.cns_id,
                    parent_contract_id: item.contract_id ?? null,
                    contragent: item.contractor_name,
                    contragent_id: item.contractor_id,
                    doc_number: item.invoice_number,
                    doc_date: parseDate(item.invoice_date),
                    date_start: '',
                    date_end: '',
                    price: `${item.price}`,
                    size: `${item.volume}`,
                    sum_wat: `${item.amount}`,
                    documents: item.files,
                    files: item.files?.length ?? 0,
                    status: item.status,
                  });
              }
              break;
            default:
              break;
          }
        }

        for (let document of documents.filter(e => e.type === 'invoice')) {
          const item: IApiInvoice2 = document.item as any;
          const nomenclature = document.item.nomenclatures.find((e) => e.nomenclature_id == line.cns_nomenclature_id);

          if (nomenclature) {
            line.cns_invoices.push({
              id: document.id,
              document_id: document.id,
              project_contract_data_id: null,
              parent_id: line.cns_id,
              parent_contract_id: null,
              contragent: item.contractor,
              contragent_id: item.contractor_id,
              doc_number: item.invoice_number,
              doc_date: parseDate(item.invoice_date),
              date_start: '',
              date_end: '',
              price: `${nomenclature?.price ?? 0}`,
              size: `${nomenclature?.volume ?? 0}`,
              sum_wat: `${nomenclature?.amount ?? 0}`,
              files: item.files?.length ?? 0,
              documents: item.files as any,
              status: item.status,
              error_action: document.error_action,
            });
          }
        }

        if (line.cns_invoices.length) {
          line.cns_invoice_count = `${line.cns_invoices.length == 1 ? line.cns_invoices[0].doc_number : line.cns_invoices.length}`;
          line.cns_invoice_date = line.cns_invoice_date ? dateFormatter(line.cns_invoice_date) : line.cns_invoices[0].doc_date;
          line.cns_invoice_size = line.cns_invoices.reduce((sum, invoice) => sum + Number(invoice.size), 0);
          line.cns_invoice_price = line.cns_invoices.reduce((sum, invoice) => sum + Number(invoice.price), 0) / line.cns_invoices.length;
          line.cns_invoice_sum = line.cns_invoices.reduce((sum, invoice) => sum + Number(invoice.sum_wat), 0);
        }

      }

      if ((header as any).includes('paid_amount_percent')) {
        const _row: { [x: string]: any } = record[index] as any;
        for (let columnNameIndex in (header as LoadDataFakt[])) {
          const columnName = (header as LoadDataFakt[])[columnNameIndex];
          switch (columnName) {
            case 'paid_amount_percent':
              line.cns_percent_payment = _row[columnNameIndex];
              break;
            case 'balance_percent':
              line.cns_payment_future = _row[columnNameIndex];
              break;
            default:
              break;
          }
        }
      }

      if ((header as any).includes('completed_percent')) {
        const _row: { [x: string]: any } = record[index] as any;
        for (let columnKey in (header as LoadDataCompleted[])) {
          const columnName = header[columnKey];
          switch (columnName) {
            case 'completed_percent':
              line.cns_well_bill_percent = _row[columnKey];
              break;
            case 'waybills_count':
              line.cns_well_bill_count = _row[columnKey];
              break;
            case 'completed_volume':
              line.cns_well_bill_size = _row[columnKey];
              break;
            case 'completed_amount':
              line.cns_well_bill_delivery_sum = _row[columnKey];
              break;
            case 'completed_delivery_date':
              line.cns_well_bill_delivery_date = parseDate(_row[columnKey]);
              break;
            case 'completed_doc_number':
              line.cns_well_bill_doc_number = _row[columnKey];
              break;
            case 'waybills':
              line.cns_way_bills = [];
              for (let i = 0; i < _row[columnKey]?.length; i++) {
                const item: ApiWayBills = _row[columnKey]?.[i];
                if (item)
                  line.cns_way_bills.push({
                    id: item.id,
                    document_id: item.document_id,
                    project_contract_data_id: item.project_nomenclature_id,
                    parent_id: line.cns_id,
                    parent_contract_id: item.contract_id ?? null,
                    contragent: item.contractor_name,
                    contragent_id: item.contractor_id,
                    doc_number: item.waybill_number,
                    doc_date: parseDate(item.waybill_date),
                    date_start: '',
                    date_end: '',
                    price: `${item.price}`,
                    size: `${item.volume}`,
                    sum_wat: `${item.amount}`,
                    documents: item.files,
                    files: item.files?.length ?? 0,
                    bill_id: item.bill_id,
                  });
              }
              break;
            default:
              break;
          }
        }
      }

      if ((header as any).includes('bills_count')) {
        const _row: { [x: string]: any } = record[index] as any;
        for (let columnKey in (header as LoadDataCompleted[])) {
          const columnName = header[columnKey];
          switch (columnName) {
            case 'bills_count':
              line.cns_bill_count = _row[columnKey];
              break;
            case 'bill_number':
              line.cns_bill_number = _row[columnKey];
              break;
            case 'bill_date':
              line.cns_bill_date = parseDate(_row[columnKey]);
              break;
            case 'bill_volume':
              line.cns_bill_size = _row[columnKey];
              break;
            case 'bill_price':
              line.cns_bill_price = _row[columnKey];
              break;
            case 'bill_amount':
              line.cns_bill_sum = _row[columnKey];
              break;
            case 'bills':
              line.cns_bills = [];
              for (let i = 0; i < _row[columnKey]?.length; i++) {
                const item: ApiBills = _row[columnKey]?.[i];
                if (item)
                  line.cns_bills.push({
                    id: item.id,
                    document_id: item.document_id,
                    project_contract_data_id: item.project_nomenclature_id,
                    parent_id: line.cns_id,
                    parent_contract_id: item.contract_id ?? null,
                    contragent: item.contractor_name,
                    contragent_id: item.contractor_id,
                    doc_number: item.bill_number,
                    doc_date: parseDate(item.bill_date),
                    date_start: '',
                    date_end: '',
                    price: `${item.waybills_price_avg}`,
                    size: `${item.waybills_volume_sum}`,
                    sum_wat: `${item.waybills_amount_sum}`,
                    documents: item.files,
                    files: item.files?.length ?? 0,
                    way_bill_doc_ids: item.waybill_document_ids ?? [],
                  });
              }
              break;
            default:
              break;
          }
        }
      }

      if ((header as any).includes('amount_diff')) {
        const _row: { [x: string]: any } = record[index] as any;
        for (let columnKey in (header as LoadDataDiffBp[])) {
          const columnName = header[columnKey];
          switch (columnName) {
            case 'amount_diff':
              line.cns_otkl_sum = _row[columnKey];
              break;
            case 'amount_diff_percent':
              line.cns_otkl_percent = _row[columnKey];
              break;
            default:
              break;
          }
        }
      }
    }

    switch (line.cns_row_type) {
      case 'section':
        line.cns_id = line.cns_section_id ?? '';
        break;
      case 'work_type':
        line.cns_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}_${line.cns_parent_id}`;
        // line.cns_parent_id = line.cns_parent_id;
        break;
      case 'nomenclature':
        line.cns_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}_${line.cns_nomenclature_id ?? ''}`;
        line.cns_parent_id = `${line.cns_section_id ?? ''}_${line.cns_group_id ?? ''}`;
        break;
      default:
        break;
    }

  }

  line.cns_contracts.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
  line.cns_invoices.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
  line.cns_way_bills.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
  line.cns_bills.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());

  return line;
}

export async function reloadErrors() {
  const errorsRes = await api.errors.errorsList({ all: 1, project_id: getLoadDataInfo().id });
  const errorsSimpleRes = await api.errors.simpleList({ all: 1, project_id: getLoadDataInfo().id });
  // const usersGrpRes = await api.helpers.cpmUsersList({page: 1, per_page: 1000});
  loadDataInfo.errors = errorsRes.data?.data ?? [];
  loadDataInfo.errorsSimple = errorsSimpleRes.data?.data ?? [];
}

export type EventBus = {
  event: 'updated-errors' | '';
  action: Function;
  tag: string
}

export function getEventBus(event: EventBus['event'] ) {
  return (window as any).subscribeUpdates?.filter((e: EventBus) => e.event == event);
}

export function addEvent2Bus(event: EventBus['event'], action: Function, tag: string) {
  // console.log('addEvent2Bus', event, tag);
  (window as any).subscribeUpdates.push({ event, action, tag });
}

export function removeEventBus(event: EventBus['event'], tag: string) {
  // console.log(`removeEventBus ${event} ${tag}`);
  (window as any).subscribeUpdates = (window as any).subscribeUpdates?.filter((e: EventBus) => e.tag != tag);
}

export function callEventBus(event: EventBus['event'], payload?: any) {
  // console.log('callEventBus', event, payload);
  const bus = getEventBus(event);
  bus?.forEach((e: EventBus) => e.action(payload));
}

(window as any).callEventBus = callEventBus;
