import React from 'react';
import { TableRecord } from '../screens/canvas_table/components/TableRecord';
import moment from 'moment/moment';
import {DocumentType} from "../indexedb";

export interface IFormFile {
  name: string;
  id: string;
}

export interface IFormAddContractsMany {
  id: string;
  number: string;
  date: string;
  start_date: string;
  end_date: string;
  contracts: IAddContracts[];
  contragent: string;
  files: IFormFile[];
}

export const emptyIModalAddContract = (): IFormAddContractsMany => ({
  id: '',
  number: '',
  date: moment().format('YYYY-MM-DD'),
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  contracts: [],
  contragent: '',
  files: [],
});

export interface IAddContracts {
  key: number | string;
  title: string;
  id: string;
  date: string;
  price: number,
  size: number,
  sum_wat: number,
}

export const emptyIAddContracts = (): IAddContracts => ({
  id: '',
  date: moment().format('YYYY-MM-DD'),
  price: 0,
  size: 0,
  sum_wat: 0,
  title: '',
  key: Date.now(),
});


export interface ContragentModalManyForm {
  title: string
  size: string
  price: string
  sum_wat: string
}

export interface IApiContragent {
  id?: string,
  contract_date: string;
  start_date: string;
  end_date: string;
  contract_number: string;
  contractor_id: string;
  nomenclatures: {
    project_id: string;
    section_id: string;
    type_id: string;
    nomenclature_id: string;
    price: number;
    volume: number;
    amount: number;
  }[];
  file_ids: string[];
  files?: { id: string, name: string }[]
}

export interface IApiContragent2 extends IApiContragent {
  contractor: string;
}


export interface IApiContract2 {
  id?: string,
  contract_date: string;
  start_date: string;
  end_date: string;
  contract_number: string;
  contractor_id: string;
  contractor?: {
    "id": string,
    "inn":string,
    "name":string,
    "ogrn":string,
    "tags":string,
    "type": {
      "id": string,
      "name": string
    },
    "email": string,
    "phone": string,
    "rating": 0,
    "comment": string,
    "contacts": string,
    "doc_date": string,
    "location": string[],
    "documents": any,
    "created_at": string,
    "doc_number": string,
    "requisites": any,
    "row_number": number,
    "updated_at": string,
    "related_user": any,
    "legal_address": any,
    "postal_address": any
  };
  nomenclatures: {
    project_id: string;
    section_id: string;
    type_id: string;
    nomenclature_id: string;
    price: number;
    volume: number;
    amount: number;
  }[];
  file_ids: string[];
  files?: { id: string, name: string }[]
}


