import {Button, message, Modal} from 'antd';
import React, {FunctionComponent} from 'react';
import {directoryApi} from "../../shared/api_client";
import {useQueryClient} from "react-query";
import {isOnline, removeContragents} from "../../indexedb";
import {ContractorShort} from "../../ApiDirectory";
import {localeKeys} from "../../i18n/localeKeys";
import {useTranslation} from "react-i18next";

const MobileContragentListRemoveContragent: FunctionComponent<{
    isOpen: boolean;
    close: () => void;
    contragent?: ContractorShort
}> = (props) => {
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return (
        <Modal
            width={1050}
            title={t(localeKeys.contragentsListScreen.modalRemove.removeContragent)}
            open={props.isOpen}
            onCancel={(e) => {
                props.close()
            }}
            footer={[
                <Button key="back" onClick={(e) => {
                    props.close()
                }}>
                    {t(localeKeys.shared.cancelBtn)}
                </Button>,
                <Button key="submit" type="primary" onClick={async () => {
                    if (isOnline) {
                        if (props.contragent?.id?.includes('.')) {
                            await removeContragents(props.contragent)
                            message.success(t(localeKeys.contragentsListScreen.modalRemove.contragentRemoved));
                            await queryClient.invalidateQueries(`contragents`);
                        } else {
                            const res = (await directoryApi.contractors.contractorsDelete(props.contragent?.id ?? ''))
                                .data as any;

                            if (res.errors || res.message) {
                                message.error(
                                    `${res.message ?? res.errors?.message ?? JSON.stringify(res.errors)}`,
                                );
                            } else {
                                message.success(t(localeKeys.contragentsListScreen.modalRemove.contragentRemoved));
                                await queryClient.invalidateQueries(`contragents`);
                            }
                        }
                    } else {
                        message.info(t(localeKeys.contragentsListScreen.modalRemove.contragentRemovedAfterInternet));
                        await removeContragents(props.contragent!)
                    }

                    props.close()
                }}>
                    {t(localeKeys.shared.deleteBtn)}
                </Button>
            ]}
        >
        </Modal>
    );
};

export default MobileContragentListRemoveContragent;
