import moment from 'moment';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../canvas_table/components/TableRecord';
import { allowEditNomeclatura } from './allowEditCell';
import { getWData } from './getWData';
import { themeTable } from './table_theme';
import {DateFormat} from "../../shared/dateFormat";
import { getValueStore } from '../../shared/store';

export function getBgCellBudgetSumWat(record: TableRecord) {
  if (record.cns_row_type == '') return themeTable.bgColor;

  if (record.cns_row_type == 'project') return themeTable.bgColor2;
  if (record.cns_row_type == 'section') return themeTable.bgColor;
  if (record.cns_row_type == 'work_type') return themeTable.bgColor2;
  if (record.cns_row_type == 'nomenclature') return themeTable.bgColor3;
  if (record.cns_row_type == 'detail') return getValueStore('bgColorLvl5') ?? themeTable.bgColor5;
}

export function getBgCellMainTitle2(record: TableRecord) {
  if (record.cns_row_type == '') return themeTable.transparent;
  if (record.cns_row_type === 'project') return themeTable.bgColor2;
  if (record.cns_row_type == 'section') return themeTable.bgColor;
  if (record.cns_row_type == 'work_type') return themeTable.bgColor2;
  if (record.cns_row_type == 'nomenclature') return themeTable.bgColor2;
  if (record.cns_row_type == 'contract') return record.cns_contractors[0]?.color ?? themeTable.bgColor2;
  if (record.cns_row_type == 'detail') return getValueStore('bgColorLvl5') ?? themeTable.bgColor5;
}

export function getColorMainTitle2(record: TableRecord) {
  if (record.cns_row_type == 'contract' && record.cns_contractors[0]?.color == 'black') return 'white';

  return themeTable.fontColor;
}
