import { TableRecord } from '../canvas_table/components/TableRecord';
import { getTableColumnKey } from './getTableColumnKey';
import { getWData } from './getWData';
import { getPassportCache } from './loadData';

export function calcLocalValueRecord(record: TableRecord, col: number, row: number) {
  let result: { [keyColumn: string]: string } = {};
  const columnKey = getTableColumnKey(col, getWData().body);
  if (
    record.cns_row_type == 'nomenclature' &&
    (columnKey === 'cns_budget_plan_size' || columnKey === 'cns_budget_plan_price')
  ) {
    result.cns_budget_plan_sum_wat = `${(Number(record.cns_budget_plan_size) || 0) * (Number(record.cns_budget_plan_price) || 0)}`;
  }

  return result;
}

export function updateRowResult(key: keyof TableRecord, oldValue: number, newValue: number) {
  const val = getWData().resultRowValues[key];
  if (val) {
    getWData().resultRowValues[key] = `${Number(val) + Number(newValue) - Number(oldValue)}`;
  }
}

export function updateBudgetFact(currentRow: TableRecord) {
  const val = currentRow.cns_contracts.filter((e) => e.id?.includes('-') || e.id?.includes('.'));
  if (!val.length) return;

  const index = getWData().rows3.findIndex((e) => e.cns_id === currentRow.cns_id);
  if (index > -1) {
    getWData().rows3[index].cns_budget_fakt_size = `${val.reduce((acc, cur) => acc + Number(cur.size), 0)}`;
    getWData().rows3[index].cns_budget_fakt_price = `${
      val.reduce((acc, cur) => acc + Number(cur.price), 0) / val.filter((e) => e.price).length
    }`;
    getWData().rows3[index].cns_budget_fakt_sum_wat = `${val.reduce((acc, cur) => acc + Number(cur.sum_wat), 0)}`;
  }
}

export function updateRowGroup(currentRow: TableRecord, key: keyof TableRecord, oldValue: number, newValue: number) {
  console.log('updateRowGroup');

  const group = getWData().rows2.findIndex((e) => e.cns_id === currentRow.cns_parent_id);
  if (group > -1) {
    (getWData().rows2[group][key] as any) =
      `${(Number(getWData().rows2[group][key] || '0') || 0) + (Number(newValue) || 0) - (Number(oldValue) || 0)}`;
  }
}

export function updateRowSection(currentRow: TableRecord, key: keyof TableRecord, oldValue: number, newValue: number) {
  console.log('updateRowSection');

  const group = getWData().rows2.find((e) => e.cns_id === currentRow.cns_parent_id);
  const section = getWData().rows.findIndex((e) => e.cns_id === group?.cns_parent_id);
  if (section > -1) {
    (getWData().rows[section][key] as any) =
      `${Number(getWData().rows[section][key] || '0') + Number(newValue) - Number(oldValue)}`;
  }
}

export function refreshFar() {
  const rows = getWData().rows;
  const rows2 = getWData().rows2;
  const rows3 = getWData().rows3;

  const totalSumWat = getWData().rows3.reduce((acc, cur) => acc + (Number(cur.cns_budget_plan_sum_wat) || 0), 0);
  const area = Number(getPassportCache().total_area ?? 1);

  for (let i = 0; i < rows3.length; i++) {
    const sumWat = Number(rows3[i].cns_budget_plan_sum_wat) || 0;

    rows3[i].cns_budget_plan_far = `${Math.round((sumWat / area) * 10) / 10}`;
    rows3[i].cns_budget_plan_far2 = `${Math.round((sumWat / area / (totalSumWat / area)) * 100 * 10) / 10}`;
  }

  for (let i = 0; i < rows2.length; i++) {
    const sumWat = Number(rows2[i].cns_budget_plan_sum_wat) || 0;

    rows2[i].cns_budget_plan_far = `${Math.round((sumWat / area) * 10) / 10}`;
    rows2[i].cns_budget_plan_far2 = `${Math.round((sumWat / area / (totalSumWat / area)) * 100 * 10) / 10}`;
  }

  for (let i = 0; i < rows.length; i++) {
    const sumWat = Number(rows[i].cns_budget_plan_sum_wat);

    rows[i].cns_budget_plan_far = `${Math.round((sumWat / area) * 10) / 10}`;
    rows[i].cns_budget_plan_far2 = Number(
      `${Math.round((sumWat / area / (totalSumWat / area)) * 100 * 10) / 10}`,
    ) as any;
  }

  const totalFar = rows3.reduce((acc, cur) => acc + Number(cur.cns_budget_plan_far2), 0);
  getWData().resultRowValues['cns_budget_plan_far'] =
    `${rows3.reduce((acc, cur) => acc + Number(cur.cns_budget_plan_far), 0)}`;
  getWData().resultRowValues['cns_budget_plan_far2'] = `${totalFar > 99 ? 100 : totalFar}`;
}
