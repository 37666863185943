import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import tokenActions, {AccessItem} from "../../../actions/tokenActions";

export function checkAddContractIcon(
  columnKey: keyof TableRecord,
  record: TableRecord,
) {
  if (!(tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts == AccessItem.FULL)) return false;
  if (columnKey == 'cns_budget_fakt_size2' && record.cns_row_type == 'nomenclature' && record.cns_status == RecordStatus.BUDGET_PLAN_APPROVED) {
    return true;
  }

  return false;
}

export function checkAddInvoiceIcon(
  columnKey: keyof TableRecord,
  record: TableRecord,
) {
  if (!(tokenActions.invoices === AccessItem.EDIT)) return false;
  if (columnKey == 'cns_invoice_size2' && record.cns_row_type == 'contract') {
    return true;
  }

  return false;
}

export function checkAddWayBillIcon(
  columnKey: keyof TableRecord,
  record: TableRecord,
) {
  if (!(tokenActions.wellBill === AccessItem.EDIT)) return false;
  if (columnKey == 'cns_well_bill_delivery_sum2' && record.cns_row_type == 'contract') {
    return true;
  }

  return false;
}

export function checkAddBillIcon(
  columnKey: keyof TableRecord,
  record: TableRecord,
) {
  if (!(tokenActions.bill === AccessItem.EDIT)) return false;
  if (columnKey == 'cns_bill_size2' && record.cns_row_type == 'contract') {
    return true;
  }

  return false;
}
