import { CellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { TableRecord } from '../canvas_table/components/TableRecord';
import { getWData } from './getWData';

export function getTableColumnKey(col: number, body: CellDefine<TableRecord>[][]): keyof TableRecord {
  // console.log(`getTableColumnKey`, col);
  if (col === -1) return 'cns_title';

  let offset = 0;
  if (tokenActions.budgetPlan == AccessItem.NONE && col > 3) {
    offset += 5;
  }

  // TODO тут будет пропуск колонки суммы для Менеджера Дат
  // if (
  //   !(tokenActions.contractsDates != AccessItem.NONE && tokenActions.budgetPlan == AccessItem.NONE) &&
  //   col + offset > 9
  // ) {
  //   offset += 1;
  // }

  if (tokenActions.planeDates == AccessItem.NONE && col + offset > 10) {
    offset += 2;
  }

  if (tokenActions.contracts == AccessItem.NONE && col + offset > 12) {
    offset += 2;
  }

  if (tokenActions.invoices == AccessItem.NONE && col + offset > 20) {
    offset += 7;
  }

  if (tokenActions.otklBp == AccessItem.NONE && col + offset > 22) {
    offset += 2;
  }

  if (tokenActions.budgetFact == AccessItem.NONE && col + offset > 23) {
    offset += 3;
  }

  if (tokenActions.wellBill == AccessItem.NONE && col + offset > 27) {
    offset += 6;
  }

  if (tokenActions.bill == AccessItem.NONE && col + offset > 33) {
    offset += 6;
  }

  // console.log(offset, col)

    switch (col + offset) {
        case 1:
            return "cns_title";
        case 2:
            return "cns_title2";
        case 3:
            return "cns_ed_izm";
        case 4:
            return "cns_budget_plan_size";
        case 5:
            return "cns_budget_plan_price";
        case 6:
            return "cns_budget_plan_sum_wat";
        case 7:
            return "cns_budget_plan_sum_wat2";
        case 8:
            return "cns_budget_plan_far";
        case 9:
            return "cns_budget_plan_far2";
        case 10:
            return "cns_plane_date_start";
        case 11:
            return "cns_plane_date_end";
        case 12:
            return "cns_contact_date";
        case 13:
            return "cns_contact_date_start";
        case 14:
            return "cns_contact_date_end";
        case 15:
            return "cns_responsibles";
        case 16:
            return "cns_contractors";
        case 17:
            return "cns_budget_fakt_size2";
        case 18:
            return "cns_budget_fakt_size";
      case 19:
        return "cns_budget_fakt_size3";
        case 20:
            return "cns_budget_fakt_price";
        case 21:
            return "cns_budget_fakt_sum_wat";
        case 22:
            return "cns_invoice_count";
        case 23:
            return "cns_invoice_date";
        case 24:
            return "cns_invoice_size2";
        case 25:
            return "cns_invoice_size";
        case 26:
            return "cns_invoice_price";
        case 27:
            return "cns_invoice_sum";
        case 28:
            return "cns_invoice_status";
        case 29:
            return "cns_otkl_sum";
        case 30:
            return "cns_otkl_percent";
        case 31:
            return "cns_percent_payment";
        case 32:
            return "cns_payment_future";
        case 33:
            return "cns_well_bill_percent";
        case 34:
            return "cns_well_bill_size";
        case 35:
          return "cns_well_bill_delivery_sum2";
        case 36:
          return "cns_well_bill_delivery_sum";
        case 37:
            return "cns_well_bill_delivery_date";
        case 38:
            return "cns_well_bill_doc_number";
        case 39:
            return "cns_bill_number";
        case 40:
            return "cns_bill_date";
        case 41:
            return "cns_bill_size2";
        case 42:
            return "cns_bill_size";
        case 43:
            return "cns_bill_price";
        case 44:
            return "cns_bill_sum";
        default:
            return (body[0][col] as any)?.field ?? ("" as string);
    }
}
export const numbersColumns: (keyof TableRecord)[] = [
  'cns_ed_izm',
  'cns_budget_plan_size',
  'cns_budget_plan_price',
  'cns_budget_plan_sum_wat',
  'cns_budget_plan_sum_wat2',
  'cns_budget_plan_far',
  'cns_budget_plan_far2',
  'cns_budget_fakt_size',
  'cns_budget_fakt_price',
  'cns_budget_fakt_sum_wat',
  'cns_budget_fakt_far',
  'cns_plane_size',
  'cns_invoice_size',
  'cns_invoice_price',
  'cns_invoice_sum',
  'cns_well_bill_count',
  'cns_well_bill_size',
  'cns_well_bill_percent',
  'cns_well_bill_delivery_sum',
  'cns_otkl_sum',
  'cns_otkl_percent',
  'cns_bill_size',
  'cns_bill_price',
  'cns_bill_sum',
];

export function allowViewDots(record: TableRecord) {
  const wData = getWData();
  const curRecord = wData.moveCell?.record;

  if (
    curRecord &&
    wData.moveCell &&
    curRecord?.cns_number === record.cns_number &&
    (curRecord.cns_row_type == 'section' || curRecord.cns_row_type == 'work_type') &&
    wData.moveCell!.col === 1
  ) {
    return true;
  }

  return false;
}

export function allowViewDots2(record: TableRecord) {
  const wData = getWData();
  const curRecord = wData.moveCell?.record;

  if (
    curRecord &&
    curRecord.cns_level != -1 &&
    wData.moveCell &&
    curRecord?.cns_id === record.cns_id &&
    wData.moveCell!.col === 2
  ) {
    if (Math.abs(getWData().moveCell?.event?.x - 600) < 20 && !getWData().hasAllowMoveOfApprove) {
      return true;
    }

    if (Math.abs(getWData().moveCell?.event?.x - 640) < 20 && getWData().hasAllowMoveOfApprove) {
      return true;
    }
  }

  return false;
}
