import React, { Suspense } from 'react';
import TableLoading from '../../shared/table_loading';
import ContragentList from './ContragentList';

export const routeContragents = [
    {
        path: '/contragents',
        element: (
            <Suspense fallback={<TableLoading baseLoading={true} animate={true} offsetTop={false} />}>
                <ContragentList />
            </Suspense>
        ),
    },
];
