import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import LayoutComponent from '../../shared/layout_component';
import {usePricesTable} from '../../actions/usePricesTable';
import pricesListColumns from './PricesListColumns';
import {App, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import {PriceRowInline} from './PricesListHelper';
import {directoryApi} from "../../shared/api_client";

const PricesList: FunctionComponent = () => {
    const {t} = useTranslation();
    const {prices, pricesRegions, pricesLoading, pricesError, pricesClearCache} = usePricesTable();
    const {notification} = App.useApp();

    const [pricesLocal, setPricesLocal] = useState<PriceRowInline[]>([]);

    const [processSave, setProcessSave] = useState<{id: string, region_id: string}[]>([]);

    useEffect(() => {
        setPricesLocal(JSON.parse(JSON.stringify(prices)))
    }, [prices]);

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const visibleRows = useMemo(() => {
        const res: PriceRowInline[] = [];

        const sections = pricesLocal.filter(p => p.type == 'section');

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            res.push(section);

            if (expandedRowKeys.includes(section.id)) {
                const workTypes = pricesLocal.filter(p => p.section_id == section.id && p.type == 'work_type');

                for (let j = 0; j < workTypes.length; j++) {
                    const workType = workTypes[j];

                    res.push(workType);

                    if (expandedRowKeys.includes(workType.id)) {
                        const nomenclatures = pricesLocal.filter(p => p.type_id == workType.id && p.type == 'nomenclature');
                        res.push(...nomenclatures);
                    }

                }
            }
        }

        return res;
    }, [pricesLocal, expandedRowKeys]);


    (window as any).pricesList = {
        expandedRowKeys,
        prices,
        pricesRegions,
        visibleRows,
    };

    return <LayoutComponent
        title={'Справочник цен'}
        className="PricesList"
        onAction={() => {
        }}
    >
        <Table
            className={'prices-table'}
            rowKey={(row, index) => `${index}`}
            dataSource={visibleRows}
            columns={pricesListColumns({
                rows: pricesLocal,
                regions: pricesRegions,
                processSave,
                onView: (id: string) => {
                },
                onEdit: (section_id: string, type_id: string, nomenclature_id: string, region_id: string, value: string) => {
                    console.log(`onEdit`, section_id, type_id, nomenclature_id, value);
                    const res: PriceRowInline[] = []

                    pricesLocal.forEach((p) => {
                        if (p.type == 'nomenclature' && p.section_id == section_id && p.type_id == type_id && p.id == nomenclature_id) {
                            const regionIndex = p.regions.findIndex(e => e.id == region_id);
                            if (regionIndex != -1) {
                                p.regions[regionIndex].price = Number(value);
                            }
                        }

                        res.push(p)
                    });

                    setPricesLocal(res);
                },
                onEditEnd: async (section_id: string, type_id: string, nomenclature_id: string, region_id: string, value: string) => {
                    console.log(`onEditEnd`, section_id, type_id, nomenclature_id, value);

                    const oldValue = prices.find(row => row.type == 'nomenclature' && row.id == nomenclature_id)?.regions.find(region => region.id == region_id)?.price;

                    if (oldValue != Number(value)) {
                        setProcessSave([...processSave, {id: nomenclature_id, region_id}]);

                        try {
                            await directoryApi.nomenclaturePrices.nomenclaturePricesCreate({
                                prices: [{
                                    nomenclature_id,
                                    region_id,
                                    price: Number(value)
                                }]
                            })

                            setProcessSave(processSave.filter(p => p.id != nomenclature_id && p.region_id != region_id));

                            const indexRow = prices.findIndex(row => row.type == 'nomenclature' && row.id == nomenclature_id);
                            if (indexRow > -1) {
                                const indexRegion = prices[indexRow].regions.findIndex(region => region.id == region_id);
                                if (indexRegion > -1) {
                                    prices[indexRow].regions[indexRegion].price = Number(value);
                                }
                            }
                        } catch (e) {
                            notification.error({message: 'Не удалось сохранить изменения'});
                            setProcessSave(processSave.filter(p => p.id != nomenclature_id && p.region_id != region_id));
                        }
                    }
                },
                expandedRowKeys: expandedRowKeys,
                setExpandedRowKeys: setExpandedRowKeys,
                t: (val) => t(val),
            })}
            onRow={(record, index) => ({
                onClick: () => {
                    const isExpanded = expandedRowKeys.includes(record.id!);
                    if (record.type == 'section') {
                        setExpandedRowKeys(isExpanded ? [...expandedRowKeys.filter((key) => key !== record.id)] : [...expandedRowKeys, record.id!]);
                    }

                    if (record.type == 'work_type') {
                        setExpandedRowKeys(isExpanded ? [...expandedRowKeys.filter((key) => key !== record.id)] : [...expandedRowKeys, record.id!]);
                    }
                },
            })}
            pagination={false}
        />
    </LayoutComponent>;
};

export default PricesList;
