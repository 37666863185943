import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Select } from 'antd';
import { useInvoicesStatus } from '../../actions/useInvoicesStatus';
import { InvoiceItem } from './FCTableInvoicesList';
import { InvoiceHelperValue } from '../../ApiEpo';
import ModalAddContractUpload from '../canvas_table/components/modals/modal_add_contracts_upload';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { emptyIModalAddContract, IFormAddContractsMany, IFormFile } from '../../Models/ContragentForm';
import { useQueryClient } from 'react-query';

interface IModalInvoicesStatus {
    isOpen: boolean;
    modalData?: InvoiceItem;
    onCancel: (val: boolean) => void;
    invoicesGrouped: boolean;
    onClose: () => void
}

const ModalInvoicesStatus: React.FC<IModalInvoicesStatus> = ({ ...props }) => {
    const [state, setState] = useState<IFormAddContractsMany>(emptyIModalAddContract());

    const [isOkDisabled, setIsOkDisabled] = useState<boolean>(true)
    const queryClient = useQueryClient();
    const onSuccess = () => {
        props.onClose()
        queryClient.invalidateQueries(['invoicesListGrouped']);
        queryClient.invalidateQueries(['invoiceStatusesList']);
    }
    const onError = () => {
        message.success('Что-то пошло не так')
    }

    const { mutate } = useInvoicesStatus(onSuccess, onError)

    const handleOk = () => {
        mutate({
            ids: props.modalData?.idProject,
            status: 'paid',
            file_ids: state.files.map(item => item.id)
        },)
        props.onClose()
        setState(emptyIModalAddContract());
    };

    const handleCancel = () => {
        setState(emptyIModalAddContract());
        props.onCancel(false);
    };

    (window as any).modalInvoiceStatus = {
        state,
        props,
    }


    return (
        <>
            <Modal title="Загрузить чеки"
                open={props.isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                okButtonProps={{ disabled: isOkDisabled }}
            >


                <ModalAddContractUpload
                key={`${props.modalData?.key}-${props.isOpen}`}
                    width={'170px'}
                    files={state.files}
                    disabled={false}
                    onUploaded={fileUploaded => {
                        setState({ ...state, files: fileUploaded });
                        setIsOkDisabled(false)
                    }}
                />
            </Modal>
        </>
    );
};

export default ModalInvoicesStatus;
