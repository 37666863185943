import { Table, TableProps } from 'antd';
import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Notification, NotificationList } from '../ApiEpo';
import { api } from '../shared/api_client';
import { dateTimeFormatter } from '../shared/dateFormat';
import LayoutComponent from '../shared/layout_component';
import { sizes } from '../shared/sizes';

const columns: TableProps<Notification>['columns'] = [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => {
            // if (moment(text).isSame(moment(), 'day')) {
            //     return dateTimeFormatter(text).split(' ')[1];
            // }

            return dateTimeFormatter(text).replace(' ', ' в ');
        },
        width: '140px',
    },
    {
        title: 'Уведомление',
        dataIndex: 'message',
        render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
    },
];

const NotificationsScreen: FunctionComponent = () => {
    const tableRef = useRef();

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [notificationsData, setNotificationsData] = useState<NotificationList | undefined>();
    const [notificationsPages, setNotificationsPages] = useState<{ [page: number]: Notification[] }>({});

    async function load(page = 1) {
        setLoading(true);
        const res = await api.notifications.notificationsList({ page, per_page: 30 });
        setNotificationsData(res.data);

        if (res.data?.data?.length && res.data.meta?.current_page) {
            setNotificationsPages({
                ...(window as any).notificationsTable.notificationsPages,
                [res.data.meta.current_page]: res.data.data ?? [],
            });
        }
        setLoading(false);
    }

    function loadNext() {
        console.log('loadNext');
        const tb =
            document.querySelector('.notificationsTable') || document.querySelector('.notification_row_mobile_wrap');
        if (
            tb &&
            tb.scrollHeight - tb.scrollTop - tb.clientHeight < window.innerHeight / 4 &&
            !(window as any).notificationsTable.loading &&
            page < (notificationsData?.meta?.last_page ?? 1000)
        ) {
            console.log('load next page');
            setPage(page + 1);
            load((window as any).notificationsTable.page + 1);
            (window as any).notificationsTable.page++;
        }
    }

    useEffect(() => {
        setNotificationsData(undefined);
        setNotificationsPages({});
        setPage(1);
        setTimeout(() => load(1), 100);

        const tb = document.querySelector('.notificationsTable');
        tb?.removeEventListener('scrollend', loadNext);
        tb?.addEventListener('scrollend', loadNext);

        const tb2 = document.querySelector('.notification_row_mobile_wrap');
        tb2?.removeEventListener('scrollend', loadNext);
        tb2?.addEventListener('scrollend', loadNext);

        if (tb2) {
            const header = document.querySelector('.header_component');
            (tb2 as HTMLElement).style.height = `${window.innerHeight - (header?.clientHeight ?? 0) - 5}px`;
        }
    }, []);

    const data: Notification[] = [];

    for (let item of Object.values(notificationsPages)) {
        let index = 0;
        for (let row of item) {
            index++;

            data.push(row);
        }
    }

    (window as any).notificationsTable = {
        page: page,
        notificationsData: notificationsData,
        notificationsPages: notificationsPages,
        data,
        loading,
        loadNext,
    };

    return (
        <LayoutComponent title="Уведомления" className="notifications_screen" onAction={() => {}}>
            {window.innerWidth > sizes.mobile && (
                <Table
                    loading={loading}
                    ref={tableRef.current}
                    rowKey={'id'}
                    className={'notificationsTable'}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            )}

            {window.innerWidth < sizes.mobile && (
                <div className={'notification_row_mobile_wrap'}>
                    {data.map((row) => (
                        <div key={`key_${row.id}`} className={`notification_row_mobile ${loading ? 'loading' : ''}`}>
                            <div className={'title'}>
                                <div className={'comment'}>{row.comment}</div>
                                {moment(row.created_at).isSame(moment(), 'day') && (
                                    <div className={'time'}>
                                        {dateTimeFormatter(row.created_at ?? '').split(' ')[1]}
                                    </div>
                                )}
                                {!moment(row.created_at).isSame(moment(), 'day') && (
                                    <div className={'time'}>{dateTimeFormatter(row.created_at ?? '')}</div>
                                )}
                            </div>
                            <div className={'message'} dangerouslySetInnerHTML={{ __html: row.message ?? '' }}></div>
                        </div>
                    ))}
                </div>
            )}
        </LayoutComponent>
    );
};

export default NotificationsScreen;
