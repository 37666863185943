import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Drawer, Dropdown, MenuProps } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tokenActions, { AccessItem } from '../actions/tokenActions';
import { usePassportList } from '../actions/usePassportList';
import { sizes } from './sizes';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../i18n/localeKeys';
import {
    addEvent2Bus,
    getLoadDataInfo,
    getPassportCache,
    LoadData,
    removeEventBus,
} from '../screens/table_extends/loadData';
import { useContractsAgreement } from "../actions/useContractsAgreement";

export type TypeActionHeader =
    'create_region'
    | 'create_project'
    | 'add_many_contracts'
    | 'add_invoices'
    | 'add_way_bills'
    | 'add_bills'
    | 'show_errors'
    | 'show_contracts_agreement'
    | 'create_contragent'
    | 'create_nomenclature_section'
    | 'create_measure_unit';

const HeaderComponent: FunctionComponent<{
    title: string;
    onAction: ( type: TypeActionHeader, payload: any ) => void;
    selectProjects?: boolean;
}> = ( props ) => {
    const route = window.location.pathname;
    const { passports } = usePassportList( { status: 'active' } );
    const { t } = useTranslation();
    const [ countErrors, setCountErrors ] = useState( 0 );
    const [ countContractAgreement, setCountContractAgreement ] = useState( 0 );
    const { contractsAgreement, clearCacheContractsAgreement } = useContractsAgreement()

    useEffect( () => {
        clearCacheContractsAgreement();
    }, [ getPassportCache().id ] );

    useEffect( () => {
        setCountErrors( 0 );

        function subscriptErrors() {
            const res = getLoadDataInfo();
            setCountErrors( res?.errorsSimple?.filter( e => !e.closed_at )?.length ?? 0 );
        }

        addEvent2Bus( 'updated-errors', subscriptErrors, 'header_component' )

        return () => {
            // removeEventBus('updated-errors', subscriptErrors, 'header_component')
        }

    }, [] );

    useEffect( () => {
        setCountContractAgreement( contractsAgreement?.data?.filter( e => e.status != 'signed' && e.status != 'rejected' )?.length ?? 0 )
    }, [ contractsAgreement ] );

    const [ open, setOpen ] = useState( false );
    const showDrawer = () => {
        setOpen( true );
    };

    const onClose = () => {
        setOpen( false );
    };

    const scrollSize = window.outerWidth < sizes.tablet ? 0 : 55;
    const isMobile = window.outerWidth < sizes.tablet;

    const canvasCreateItems: MenuProps['items'] = [
        (tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts == AccessItem.FULL) ? {
            key: 'add_many_contracts',
            label: t( localeKeys.header.addContract ),
            onClick: () => props.onAction && props.onAction( 'add_many_contracts', {} ),
            ['data-test']: 'ADD_MANY_CONTRACTS'
        } : null,
        tokenActions.invoices === AccessItem.EDIT ? {
            key: 'add_invoices',
            label: t( localeKeys.header.addInvoice ),
            onClick: () => props.onAction && props.onAction( 'add_invoices', {} ),
            ['data-test']: 'ADD_INVOICES'
        } : null,
        tokenActions.wellBill === AccessItem.EDIT ? {
            key: 'add_way_bills',
            label: t( localeKeys.header.addWaybill ),
            onClick: () => props.onAction && props.onAction( 'add_way_bills', {} ),
            ['data-test']: 'ADD_WAY_BILLS'
        } : null,
        tokenActions.bill === AccessItem.EDIT ? {
            key: 'add_bills',
            label: t( localeKeys.header.addBill ),
            onClick: () => props.onAction && props.onAction( 'add_bills', {} ),
            ['data-test']: 'ADD_BILLS'
        } : null,
    ].filter( e => e );

    const btnErrors = <Button
        className={ 'btn_show_errors' }
        data-test={ 'SHOW_ERRORS' }
        type="link"
        onClick={ () => props.onAction && props.onAction( 'show_errors', {} ) }
    >
        <img className="icon_notification" src="/canvas_table/icon_warning.svg"
             alt={ t( localeKeys.header.notifications ) }/>
        { !!countErrors && <div className={ 'header_component__error_count' }>{ countErrors }</div> }
    </Button>

    const btnContractsAgreement = <Button
        className={ 'btn_show_contracts_agreement' }
        data-test={ 'SHOW_CONTRACTS_AGREEMENT' }
        type="link"
        onClick={ () => props.onAction && props.onAction( 'show_contracts_agreement', {} ) }
    >
        <img className="icon_notification" src="/canvas_table/icon_contracts_agreement.svg"
             alt={ t( localeKeys.header.notifications ) }/>
        { !!countContractAgreement &&
            <div className={ 'header_component__error_count' }>{ countContractAgreement }</div> }
    </Button>


    const btnCanvas = <div className={ 'header_component__canvas-btns' }>
        { canvasCreateItems.length ?
            <Dropdown menu={ { items: canvasCreateItems } } rootClassName={ 'header_component__dropdown' }>
                <a
                    data-test={ 'HEADER_ADD' }
                    className={ 'header_component__btn_add' }
                    onClick={ ( e ) => e.preventDefault() }
                >
                    <PlusOutlined/> &nbsp;
                    { t( localeKeys.header.addBtn ) } &nbsp;
                </a>
            </Dropdown> : <></> }
        { !isMobile && <>
            { btnContractsAgreement }
            { btnErrors }
        </> }
    </div>;

    const rightPath = (
        <div className="header_component_right">
            { route.includes( '/passport' ) && !isMobile && btnCanvas }
            { tokenActions.createProject == AccessItem.ALLOW && route === '/' && (
                <Button
                    className={ 'btn_create_project' }
                    data-test={ 'CREATE_PROJECT' }
                    type="link"
                    onClick={ () => props.onAction && props.onAction( 'create_project', {} ) }
                >
                    <PlusOutlined/> { t( localeKeys.passportListScreen.addPassport ) }
                </Button>
            ) }
            { tokenActions.contragents == AccessItem.EDIT && route == '/contragents' && (
                <Button
                    className={ 'btn_create_project' }
                    data-test={ 'CREATE_CONTRAGENT' }
                    type="link"
                    onClick={ () => props.onAction && props.onAction( 'create_contragent', {} ) }
                >
                    <PlusOutlined/> { t( localeKeys.header.addContragent ) }
                </Button>
            ) }
            { tokenActions.directoryRegions == AccessItem.EDIT && route == '/regions' && (
                <Button
                    className={ 'btn_create_project' }
                    data-test={ 'CREATE_REGION' }
                    type="link"
                    onClick={ () => props.onAction && props.onAction( 'create_region', {} ) }
                >
                    <PlusOutlined/> Новый регион
                </Button>
            ) }
            { tokenActions.directoryMeasureUnits == AccessItem.EDIT && route == '/measure_unit' && (
                <Button
                    className={ 'btn_create_project' }
                    data-test={ 'CREATE_MEASURE_UNIT' }
                    type="link"
                    onClick={ () => props.onAction && props.onAction( 'create_measure_unit', {} ) }
                >
                    <PlusOutlined/> Добавить
                </Button>
            ) }
            { (tokenActions.directoryNomenclatures == AccessItem.EDIT || tokenActions.directoryNomenclatures == AccessItem.FULL) && route == '/nomenclatures' && (
                <Button
                    className={ 'btn_create_project' }
                    data-test={ 'CREATE_NOMENCLATURE_SECTION' }
                    type="link"
                    onClick={ () => props.onAction && props.onAction( 'create_nomenclature_section', {} ) }
                >
                    <PlusOutlined/> Добавить раздел
                </Button>
            ) }
            { route.includes( '/passport' ) && isMobile && btnErrors }
            <Link to={ '/notifications' } className={ route === '/notifications' ? 'active' : '' }>
                <img className="icon_notification" src="/header/notification.svg"
                     alt={ t( localeKeys.header.notifications ) }/>
            </Link>
            <Link to={ '/profile' } className={ route === '/profile' ? 'active' : '' }>
                <img className="icon_profile" src="/header/profile.svg" alt={ t( localeKeys.header.profile ) }/>
            </Link>
        </div>
    );

    const iconMenu = (
        <div className={ 'header_component__menu_icon' } onClick={ showDrawer }>
            <MenuOutlined style={ { fontSize: '28px', position: 'relative', top: '1.5px' } }/>
        </div>
    );

    const drawer = (
        <Drawer
            className={ 'header_component__drawer' }
            title={ <img className={ 'header_component__drawer_logo' } src={ '/header/logo_header.svg' }
                         alt={ 'logo' }/> }
            onClose={ onClose }
            open={ open }
            placement={ 'left' }
        >
            <div className={ 'header_component__drawer_menu' }>
                { tokenActions.listProjects != AccessItem.NONE &&
                    <Link to={ '/' }>{ t( localeKeys.header.projects ) }</Link> }
                { tokenActions.contragents != AccessItem.NONE &&
                    <Link to={ '/contragents' }>{ t( localeKeys.header.suppliers ) }</Link> }
                { tokenActions.finContr != AccessItem.NONE &&
                    <Link to={ '/fin-controller' }>{ t( localeKeys.header.financialController ) }</Link> }
                { tokenActions.directoryNomenclatures != AccessItem.NONE &&
                    <Link to={ '/nomenclatures' }>{ t( localeKeys.header.nomenclatures ) }</Link> }
                { tokenActions.directoryRegions != AccessItem.NONE &&
                    <Link to={ '/regions' }>{ t( localeKeys.header.regions ) }</Link> }
                { tokenActions.directoryMeasureUnits != AccessItem.NONE &&
                    <Link to={ '/measure_unit' }>Единицы измерения</Link> }
            </div>
        </Drawer>
    );

    const items =
        passports?.data?.map( ( e ) => ({
            key: e.id,
            label: <a href={ `/passport/${ e.id }` }> { e.title } </a>,
        }) ) ?? [];

    const breadcrumbs: BreadcrumbItemType[] = [
        { title: props.title, menu: props.selectProjects ? { items } : undefined },
    ];

    if ( window.outerWidth > sizes.mobile ) {
        breadcrumbs.unshift( {
                title: <>{ window.outerWidth > sizes.mobile &&
                    <img src="/header/logo_header.svg" alt="/" style={ { position: 'relative', top: 3 } }/> }</>,
                href: tokenActions.listProjects != AccessItem.NONE ? '/' : '/contragents',
            },
        );
    }

    return (<>
        <div
            className={ `header_component ${ route == '/contragents' ? 'header_component__contragents' : '' }` }
            style={ { maxWidth: window.outerWidth - scrollSize, display: 'var(--visibleHeader)' } }
        >
            { iconMenu }
            <Breadcrumb
                items={ breadcrumbs }
                separator={ <img src="/header/breadcrumb_dot.svg" alt="/"/> }
            ></Breadcrumb>
            { rightPath }

            { drawer }
        </div>
        { route.includes( '/passport' ) && window.outerWidth <= sizes.mobile && btnCanvas }
    </>);
};

export default HeaderComponent;

export function getHeaderComponentHeight() {
    return document.querySelector( '.header_component' )?.getBoundingClientRect().height ?? 0;
}
