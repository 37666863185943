import { allowHoverCell } from '../../table_extends/allowEditCell';
import { getWData } from '../../table_extends/getWData';
import { TableMode, getTableBody } from '../../table_extends/table_body/table_body';
import { getTableHeaders, tableHeaders } from '../../table_extends/table_header/table_headers';

export function onCellMove(param: { col: number; row: number; event: any }) {
  const { col, row, event } = param;
  const record = getWData().resultRows[row - tableHeaders.length];

  if (getWData().tableMode === TableMode.SCROLL && record) {
    if (allowHoverCell(record, col)) {
      getWData().tableMode = TableMode.VIEW;
      getWData().grid.layout = {
        header: getTableHeaders(),
        body: getTableBody(TableMode.VIEW),
      };
    }
  }

  getWData().moveCell = {
    col,
    row,
    record: record,
    event,
  };

  getWData().grid?.invalidate();
}
