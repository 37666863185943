import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FunctionComponent } from 'react';
import { getHeaderComponentHeight } from '../../../../shared/header_component';
import { sizes } from '../../../../shared/sizes';
import { sendToApprove } from '../../../table_extends/approveRow';
import { getResultRows } from '../../../table_extends/getResultRows';
import { getWData } from '../../../table_extends/getWData';
import { getPassportCache } from '../../../table_extends/loadData';
import { getTableBody, TableMode } from '../../../table_extends/table_body/table_body';
import { getTableHeaders } from '../../../table_extends/table_header/table_headers';
import { RecordStatus } from '../TableRecord';

export function ModalSendApprove(props: { keyModal: string; onApprove: () => void; onCancel: () => void }) {
    const cell = getWData().grid.getCellRect(3, getWData().cellOpen!.row);
    const grid = getWData().grid;

    let x = cell?.left - grid?.scrollLeft - 15;
    let y =
        cell?.top +
        cell?.height -
        window.scrollY -
        grid.scrollTop +
        getHeaderComponentHeight() +
        (window.innerWidth < 800 ? 60 : 0);

    if (y > window.innerHeight - 200) {
        y = cell?.top - window.scrollY - grid.scrollTop - (window.innerWidth < 800 ? 130 : 75) + 60;
    }

    const record = getWData().cellOpen?.record;

    return (
        <div
            key={props.keyModal}
            className={'ModalSendApprove'}
            style={
                window.innerWidth > sizes.mobile
                    ? {
                          position: 'absolute',
                          top: y,
                          left: x,
                      }
                    : {
                          bottom: 0,
                          left: 0,
                      }
            }
        >
            <div className={'title'}>
                {' '}
                {record?.cns_row_type == 'project' ? getPassportCache().title : record?.cns_title}{' '}
                <CloseOutlined onClick={props.onCancel} />{' '}
            </div>
            Подтвердить заполнение и отправить <br /> на согласование?
            <div>
                <Button type={'primary'} onClick={props.onApprove} data-test={'APPROVE'}>
                    Подтвердить
                </Button>
            </div>
        </div>
    );
}

export function closeModalSendApprove() {
    if (!getWData().cellOpen?.isModalSendApprove) return;
    console.log('closeModalSendApprove');

    if (getWData().cellOpen?.isModalSendApprove) {
        getWData().cellOpen!.isModalSendApprove = false;
    }
    getWData().setRefresh(Math.random());
}

export const ModalSendApproveBind: FunctionComponent<any> = () => {
    return (
        <ModalSendApprove
            keyModal={`modal_send_approve${getWData().refresh}`}
            onApprove={() => {
                let flag = false;
                if (!getWData().rows3.find((e) => e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) {
                    flag = true;
                }

                sendToApprove(getWData().cellOpen!.record);

                if (flag) {
                    getWData().grid.layout = {
                        header: getTableHeaders(),
                        body: getTableBody(TableMode.VIEW),
                    };
                }

                getWData().resultRows = getResultRows();
                getWData().grid.records = getWData().resultRows;
                getWData().grid.invalidate();

                closeModalSendApprove();
            }}
            onCancel={() => {
                closeModalSendApprove();
            }}
        />
    );
};
