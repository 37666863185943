import { FunctionComponent, useEffect, useState } from 'react';
import { Checkbox, Col, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { getValueStore, setValueStore } from '../shared/store';

const DebugScreen: FunctionComponent = () => {
    const [state, setState] = useState<{
        localStandEnable: boolean | null
        portEpo: string
        portRbac: string
        portSocket: string
        portHistory: string
        portDirectory: string
        portDocuments: string
    }>({
        localStandEnable: null,
        portEpo: '',
        portRbac: '',
        portSocket: '',
        portHistory: '',
        portDirectory: '',
        portDocuments: '',
    });

    useEffect(() => {
        setState({
            localStandEnable: getValueStore('LOCAL_STAND_ENABLE') === 'true',
            portRbac: getValueStore('PORT_RBAC') ?? '',
            portEpo: getValueStore('PORT_EPO') ?? '',
            portSocket: getValueStore('PORT_SOCKET') ?? '',
            portHistory: getValueStore('PORT_HISTORY') ?? '',
            portDirectory: getValueStore('PORT_DIRECTORY') ?? '',
            portDocuments: getValueStore('PORT_DOCUMENTS') ?? '',
        });
    }, []);

    useEffect(() => {
        if (state.localStandEnable !== null) {
            if (state.localStandEnable) {
                setValueStore('LOCAL_STAND_ENABLE', 'true');
                setValueStore('PORT_RBAC', state.portRbac);
                setValueStore('PORT_EPO', state.portEpo);
                setValueStore('PORT_SOCKET', state.portSocket);
                setValueStore('PORT_HISTORY', state.portHistory);
                setValueStore('PORT_DIRECTORY', state.portDirectory);
                setValueStore('PORT_DOCUMENTS', state.portDocuments);
            } else {
                setValueStore('LOCAL_STAND_ENABLE', 'false');
            }
        }
    }, [state]);

    function getFormLine(label: string, placeholder: string, value: string, onChange: (value: string) => void) {
        return <Col span={12} offset={3}>
            <FormItem label={label} labelCol={{ span: 12 }}>
                <Input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} disabled={!state.localStandEnable} />
            </FormItem>
        </Col>;
    }


    return <div style={{ padding: '1rem' }}>
        <h1>DebugScreen</h1>
        <div>
            <Row>
                <Col span={12} offset={8}>
                    <div className={'debug_checkbox'}>
                        <Checkbox checked={!!state.localStandEnable}
                                  onChange={(e) => setState({ ...state, localStandEnable: e.target.checked })} /> &nbsp;
                        Использовать локальный стенд
                    </div>
                    <br />
                    <br />
                </Col>

                {getFormLine( 'Сервис RBAC (порт)', 'Порт локального стенда (localhost)', state.portRbac, (value) => setState({ ...state, portRbac: value }) )}
                {getFormLine( 'Сервис EPO (порт)', 'Порт локального стенда (localhost)', state.portEpo, (value) => setState({ ...state, portEpo: value }) )}
                {getFormLine( 'Документы (порт)', 'Порт локального стенда (localhost)', state.portDocuments, (value) => setState({ ...state, portDocuments: value }) )}
                {getFormLine( 'Справочники (порт)', 'Порт локального стенда (localhost)', state.portDirectory, (value) => setState({ ...state, portDirectory: value }) )}
                {getFormLine( 'История (порт)', 'Порт локального стенда (localhost)', state.portHistory, (value) => setState({ ...state, portHistory: value }) )}
                {getFormLine( 'Сокет (порт)', 'Порт локального стенда (localhost)', state.portSocket, (value) => setState({ ...state, portSocket: value }) )}


            </Row>
        </div>
    </div>;
};

export default DebugScreen;

