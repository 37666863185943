import {useQuery, useQueryClient} from 'react-query';
import { apiAuth } from '../shared/api_client';

export function useUser() {
  const queryClient = useQueryClient();

  const {
    data: userInfo,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useQuery({
    queryKey: [`current_user`],
    queryFn: () => {
      return apiAuth.me.getMe()
    },
  });

  function clearCacheUser() {
    queryClient.removeQueries([`current_user`]);
  }

  return {
    userInfo,
    isLoadingUser,
    isErrorUser,
    errorUser,
    clearCacheUser,
  };
}
