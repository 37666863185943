import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {getWData} from "../../../table_extends/getWData";
import {IModalAddGlobal} from "./modal_add_contract";

export const ModalBill: FunctionComponent<IModalAddGlobal> = (props) => {

    return <>
        {props.open && <div className={`ModalBill`}>
            ModalBill
        </div>}
    </>
}


export function closeModalBill() {
    if (!getWData().cellOpen?.isModalBill) return;
    console.log('closeModalBill');

    if (getWData().cellOpen?.isModalBill) {
        getWData().cellOpen!.isModalBill = false;
    }
    getWData().setRefresh(Math.random());
}
