import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../canvas_table/components/TableRecord';
import { allowViewBudgetPlanCell } from './allowEditCell';
import { getWData } from './getWData';

export function checkConfirmBudgetPlanHeader(record: TableRecord) {
  return (
    record.cns_row_type == 'project' &&
    getWData().rows.find((e) => e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW) &&
    tokenActions.budgetPlanApprove == AccessItem.ALLOW
  );
}

export function countConfirmBudgetPlanHeader(record: TableRecord) {
  return (
    (record.cns_row_type == 'project' &&
      getWData().rows.filter((e) => e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) ||
    []
  );
}

export function checkConfirmBudgetPlan(record: TableRecord) {
  return (
    (record.cns_row_type == 'section' || record.cns_row_type == 'work_type' || record.cns_row_type == 'nomenclature') &&
    allowViewBudgetPlanCell('cns_budget_plan_sum_wat', record) &&
    record.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW &&
    tokenActions.budgetPlanApprove == AccessItem.ALLOW
  );
}

export function checkViewBudgetPlanAction(record: TableRecord) {
  if (
    record.cns_row_type == 'project' &&
    getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW)
  )
    return true;

  return (
    (record.cns_row_type == 'section' || record.cns_row_type == 'work_type' || record.cns_row_type == 'nomenclature') &&
    allowViewBudgetPlanCell('cns_budget_plan_sum_wat', record) &&
    record.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW &&
    tokenActions.budgetPlan == AccessItem.EDIT
  );
}
