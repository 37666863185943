import {ColumnsType} from 'antd/es/table';
import {PriceRegions, PriceRowInline, PriceSectionRecord} from './PricesListHelper';
import {Input} from "antd";


const pricesListColumns: (props: {
    rows: PriceRowInline[];
    regions: PriceRegions[];
    onView: (id: string) => void;
    onEdit: (section_id: string, type_id: string, nomenclature_id: string, region_id: string, value: string) => void;
    onEditEnd: (section_id: string, type_id: string, nomenclature_id: string, region_id: string, value: string) => void;
    expandedRowKeys: string[],
    setExpandedRowKeys: (keys: string[]) => void;
    t: (key: string) => string;
    processSave: {id: string, region_id: string}[]
}) => ColumnsType<PriceRowInline> = (props) => {
    return [
        {
            title: 'Наименование',
            dataIndex: 'title',
            // fixed: 'left',
            render: (text, record) => {
                const isExpanded = props.expandedRowKeys.includes(record.id!);

                if (record.type == 'section') {
                    return <div className={`prices-table__title section`}>
                        {!isExpanded ? <img src={'/canvas_table/icon_plus.svg'} alt="плюс"/> :
                            <img src={'/canvas_table/icon_remove.svg'} alt="минус"/>}
                        &nbsp;
                        {text}
                    </div>
                }

                if (record.type == 'work_type') {
                    return <div className={`prices-table__title work_type`}>
                        {!isExpanded ? <img src={'/canvas_table/icon_plus.svg'} alt="плюс"/> :
                            <img src={'/canvas_table/icon_remove.svg'} alt="минус"/>}
                        &nbsp;
                        {text}
                    </div>
                }

                return <div className={`prices-table__title nomenclature`}>{text}</div>;
            },
        },
        ...props.regions.map((region) => ({
            width: 200,
            title: region.title,
            render: (text: string, record: PriceRowInline) => {
                if (record.type == 'nomenclature') {
                    return <Input
                        readOnly={props.processSave.find(p => p.id == record.id && p.region_id == region.id) != undefined}
                        className={record.type}
                        value={record.regions.find(e => e.id == region.id)?.price ?? ''}
                        onChange={(e) => {
                            props.onEdit(record.section_id, record.type_id, record.id, region.id, e.target.value);
                        }}
                        onBlur={val => {
                            props.onEditEnd(record.section_id, record.type_id, record.id, region.id, val.target.value);
                        }}
                    />
                }

                return <span className={record.type}>
                    {record.regions.find(e => e.id == region.id)?.price ?? ''}
                </span>;
            },
        })),
    ];
};

export default pricesListColumns;

