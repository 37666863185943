import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Input, MenuProps, message, Modal, notification, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment/moment';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HelperValue, ProjectDetail } from '../../ApiEpo';
import { getEmptyPassportListItem } from '../../Models/PassportListItem';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { useLocationList } from '../../actions/useLocationList';
import { usePassportList } from '../../actions/usePassportList';
import { useWorkSectionList } from '../../actions/useWorkSectionList';
import { api, apiAuth } from '../../shared/api_client';
import { DateFormat, getDate2ISO } from '../../shared/dateFormat';
import { localeMemberPosition, membersKyes } from '../../shared/localeMemberPosition';
import { MyDatePicker } from '../../shared/stopEvent';
import {
    validateEndDate,
    validateFormMaxMinNumber,
    validateFormNumber,
    validateStartDate,
} from '../canvas_table/components/utils/validateFormNumber';
import enUS from 'antd/es/calendar/locale/en_US';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../i18n/localeKeys';

const PassportListModal: FunctionComponent<{
    isOpen: boolean;
    close: () => void;
    id: number | string;
    onlyView: boolean;
    onSuccess: () => void;
}> = (props) => {
    const { t } = useTranslation();
    const { locations, locationsLoading } = useLocationList();
    const { workSections, workSectionsLoading } = useWorkSectionList();
    const [idSections, setIdSections] = useState(Math.random());
    const [newProject, setNewProject] = useState<ProjectDetail>({
        ...getEmptyPassportListItem(),
    });
    const [form] = Form.useForm();
    const [positionsForm, setPositionsForm] = useState<string[]>([]);
    const [searchLocation, setSearchLocation] = useState('');
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { passports } = usePassportList({ status: 'active' });
    const passportArchive = usePassportList({ status: 'archive' });

    useEffect(() => {
        if (props.id === 0) {
            return;
        }
        if (props.id == -1) {
            setNewProject({
                ...getEmptyPassportListItem(),
            });
            form.setFieldsValue({
                ...getEmptyPassportListItem(),
                start_date:
                    typeof newProject.start_date == 'string' && newProject.start_date
                        ? moment(newProject.start_date, 'YYYY-MM-DD')
                        : moment(),
                end_date:
                    typeof newProject.end_date == 'string' && newProject.end_date
                        ? moment(newProject.end_date, 'YYYY-MM-DD')
                        : moment(),
            });
        } else {
            api.projects.projectsDetail(props.id.toString()).then((e) => {
                if (e.data) {
                    setNewProject(e.data);
                    form.setFieldsValue({
                        ...e.data,
                        start_date:
                            typeof e.data.start_date == 'string' && e.data.start_date
                                ? moment(e.data.start_date, 'YYYY-MM-DD')
                                : moment(),
                        end_date:
                            typeof e.data.end_date == 'string' && e.data.end_date
                                ? moment(e.data.end_date, 'YYYY-MM-DD')
                                : moment(),
                    });
                }
            });
        }
    }, [props.id]);

    function resetForm() {
        form.resetFields();

        setIdSections(Math.random());
        queryClient.invalidateQueries({ queryKey: ['work-sections'] });
        setSearchLocation('');
        setSearchUser('');

        props.close();
    }

    const handleOk = async () => {
        if (props.onlyView) {
            props.close();
            return;
        }

        try {
            const values = await form.validateFields();
            if (
                passports?.data?.find((e) => e.title == values.title && newProject.title != values.title) ||
                passportArchive?.passports?.data?.find((e) => e.title == values.title)
            ) {
                message.error(t(localeKeys.passportListScreen.modal.projectExists));
                return;
            }

            setLoading(true);
            values.start_date = getDate2ISO(values.start_date?.toDate());
            values.end_date = getDate2ISO(values.end_date?.toDate());
            values.members = values.members?.map((e: any) =>
                e.full_name instanceof Object
                    ? {
                        user_id: e.full_name.value.replace(e.full_name.label, '').replaceAll(' ', ''),
                        full_name: e.full_name.label,
                        position: e.position,
                    }
                    : e,
            );

            if (!newProject.guideline_budget_confirmed) {
                values.total_area = Number(values.total_area);
            } else {
                await api.projects.projectUpdateTotalArea(props.id.toString(), {
                    total_area: Number(values.total_area),
                });
            }

            values.hard_surface_ratio = Number.isNaN(values.hard_surface_ratio) ? 0 : Number.parseFloat(values.hard_surface_ratio);

            if (values.members) {
                values.members = values.members.filter((e: any) => !e.id?.includes('add_member_'));
            }

            if (props.id == -1) {
                const res = await api.projects.projectsCreate(values);

                if (res.data.id) {
                    message.success(t(localeKeys.passportListScreen.modal.createdProject));
                    setTimeout(() => navigate(`/passport/${res.data.id}`), 1000);
                }
            } else {
                await api.projects.projectsUpdate(props.id.toString(), values);
            }

            resetForm();
            props.onSuccess();
        } catch (e: any) {
            console.log(e);
            if (!e.errorFields) {
                notification.error({
                    message: t(localeKeys.shared.errorExists),
                    description: e?.error?.message ?? e?.error ?? JSON.stringify(e),
                });
            }
        }
        setLoading(false);
    };

    const handleCancel = () => {
        resetForm();
    };

    const onClick = ({ key, add }: { key: { key: string }; add: (defaultValue: any) => void }) => {
        add({
            full_name: '',
            position: key.key,
            id: `add_member_${Math.random()}`,
        });
    };

    const items: MenuProps['items'] = membersKyes.map((e) => ({
        key: e,
        label: localeMemberPosition(e, t),
    }));

    const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);

    const getMaxData = (value: string) => {
        const data = form.getFieldValue(value);
        setEndDate(moment(data).clone().subtract(1, 'day') ?? undefined);
    };
    const getMinData = (value: string) => {
        const data = form.getFieldValue(value);
        setStartDate(moment(data).clone().add(1, 'day') ?? undefined);
    };
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<HelperValue[]>([]);
    const fetchRef = useRef(0);
    const [searchUser, setSearchUser] = useState('');

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            apiAuth.helpers.usersList({ search: value, page: 1, per_page: 15, only_active: 1 }).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions.data.data ?? []);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 800);
    }, [searchUser, props.id]);

    useEffect(() => {
        console.log('searchUser', searchUser);
        if (searchUser) {
            debounceFetcher(searchUser);
        }
    }, [searchUser]);

    (window as any).modalPassport = {
        newProject,
        setNewProject,
        form,
        positionsForm,
        setPositionsForm,
        searchLocation,
        setSearchLocation,
        onClick,
        items,
        workSections,
        setOptions,
        options,
        fetching,
        setFetching,
        searchUser,
        setSearchUser,
        locations,
    };

    return (
        <Modal
            width={750}
            title={props.id !== -1 ? (props.onlyView ? t(localeKeys.passportListScreen.modal.viewPassport) : t(localeKeys.passportListScreen.modal.editPassport)) : t(localeKeys.passportListScreen.modal.addPassport)}
            open={props.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={props.id != -1 ? (props.onlyView ? t(localeKeys.passportListScreen.modal.close) : t(localeKeys.passportListScreen.modal.save)) : t(localeKeys.passportListScreen.modal.create)}
            okButtonProps={{
                'data-test': 'CREATE_PROJECT_BTN',
            }}
            confirmLoading={loading}
            wrapClassName="create_project_modal"
            afterClose={() => {
                setNewProject({
                    ...getEmptyPassportListItem(),
                });
                form.resetFields();
            }}
        >
            <Form
                <ProjectDetail>
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                initialValues={{
                    ...newProject,
                    start_date:
                        typeof newProject.start_date == 'string' && newProject.start_date
                            ? moment(newProject.start_date, 'YYYY-MM-DD')
                            : moment(),
                    end_date:
                        typeof newProject.end_date == 'string' && newProject.end_date
                            ? moment(newProject.end_date, 'YYYY-MM-DD')
                            : moment(),
                }}
                onFinish={handleOk}
                disabled={loading || props.onlyView}
            >
                <h4> {t(localeKeys.passportListScreen.modal.subtitle)}</h4>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.code)}
                    name="title"
                    rules={[{ required: true, message: t(localeKeys.passportListScreen.modal.fieldRequired) }]}
                    data-test="title"
                >
                    <Input disabled={newProject.guideline_budget_confirmed || loading || props.onlyView} />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.region)}
                    name="region_id"
                    rules={[{ required: true, message: t(localeKeys.passportListScreen.modal.fieldRequired) }]}
                    data-test="region_id"
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder={locationsLoading ? t(localeKeys.shared.loading) : ''}
                        optionFilterProp={'label'}
                        options={[
                            ...((locations?.data ?? [])
                                .map((item) => ({
                                    label: item.title,
                                    value: item.id,
                                })) ?? []),
                        ]
                            .filter((e) => e.value)
                        }
                        // onSelect={onSelect}
                        onSearch={(text) => {
                            setSearchLocation(text);
                        }}
                        disabled={newProject.guideline_budget_confirmed || loading || props.onlyView}
                    />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.dateStart)}
                    name="start_date"
                    rules={[{ validator: () => validateStartDate(form) }]}
                    data-test="start_date"
                >
                    <MyDatePicker maxDate={endDate} locale={enUS} inputReadOnly={true} format={DateFormat}
                                  onFocus={() => getMaxData('end_date')} onChange={() => getMaxData('end_date')} />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.dateEnd)}
                    name="end_date"
                    rules={[{ validator: () => validateEndDate(form) }]}
                    data-test="end_date"
                >
                    <MyDatePicker minDate={startDate} locale={enUS} inputReadOnly={true} format={DateFormat}
                                  onFocus={() => getMinData('start_date')} onChange={() => getMinData('start_date')} />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.totalArea)}
                    name="total_area"
                    required
                    data-test="total_area"
                    rules={[{ validator: () => validateFormNumber(form, 'total_area') }]}
                >
                    <Input
                        disabled={
                            (newProject.guideline_budget_confirmed && tokenActions.allowUpdArea != AccessItem.ALLOW) ||
                            loading ||
                            props.onlyView
                        }
                        style={{ maxWidth: 139 }}
                    />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.passportListScreen.modal.hardSurfaceRatio)}
                    name="hard_surface_ratio"
                    required
                    data-test="hard_surface_ratio"
                    rules={[
                        {
                            validator: () => validateFormMaxMinNumber(form, 'hard_surface_ratio', {
                                min: 0,
                                max: 100,
                                placeholderErrorMax: t(localeKeys.passportListScreen.modal.coefficientErrorMax),
                                placeholderErrorMin: t(localeKeys.passportListScreen.modal.coefficientErrorMin),
                            }),
                        },
                    ]}
                >
                    <Input
                        disabled={loading || props.onlyView}
                        style={{ maxWidth: 139 }}
                    />
                </Form.Item>
                <h4>{t(localeKeys.passportListScreen.modal.membersSubtitle)}</h4>
                <Form.List name="members">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <div key={field.key} className={'member_row'} data-test="member">
                                    {/*{JSON.stringify(field)}*/}
                                    {/*{JSON.stringify(form.getFieldValue('members')[field.name])}*/}
                                    <Form.Item
                                        label={localeMemberPosition(
                                            form.getFieldValue('members')[field.name]?.position,
                                            t,
                                        )}
                                        name={[field.name, 'full_name']}
                                        wrapperCol={{ span: 12 }}
                                        data-test="member_name"
                                    >
                                        <Select
                                            data-test="member_name_inner"
                                            labelInValue
                                            filterOption={true}
                                            onSearch={(search) => {
                                                setSearchUser(search);
                                            }}
                                            notFoundContent={fetching ? <Spin size="small" /> : null}
                                            options={options.map((item) => ({
                                                ...item,
                                                label: item.text,
                                                value: `${item.text} ${item.value}`,
                                            }))}
                                            showSearch={true}
                                            onFocus={() =>
                                                setSearchUser(form.getFieldValue('members')[field.name]?.full_name)
                                            }
                                            onSelect={() => {
                                                console.log('onSelect');
                                                setOptions([]);
                                                setSearchUser('');
                                            }}
                                        />
                                    </Form.Item>
                                    {!props.onlyView && (
                                        <CloseOutlined
                                            data-test="member_remove"
                                            className={'icon_remove'}
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    )}
                                </div>
                            ))}

                            {!props.onlyView && (
                                <div>
                                    <Dropdown
                                        key={`dropdown_add_member`}
                                        menu={{ items, onClick: (key) => onClick({ key, add }) }}
                                        arrow
                                        destroyPopupOnHide={true}
                                    >
                                        <Button type="link" data-test="member_add">
                                            <PlusOutlined /> {t(localeKeys.passportListScreen.modal.addMember)}
                                        </Button>
                                    </Dropdown>
                                </div>
                            )}
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};

export default PassportListModal;
