import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getTableColumnKey } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';

export function checkOpenModalApproveBudget(col: number, row: number, currentRow: TableRecord) {
  if (tokenActions.budgetPlanApprove == AccessItem.NONE) return false;

  const columnKey = getTableColumnKey(col, getWData().body);
  return (
    getPassportCache().status == 'active' &&
    columnKey === 'cns_budget_plan_sum_wat2' &&
    currentRow &&
    ((currentRow.cns_row_type == 'section' && currentRow.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW) ||
      (currentRow.cns_row_type == 'work_type' && currentRow.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW) ||
      (currentRow.cns_row_type == 'nomenclature' && currentRow.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW) ||
      (currentRow.cns_row_type == 'project' &&
        getWData().rows2.filter((e) => e.cns_status === RecordStatus.BUDGET_PLAN_UNDER_REVIEW).length > 0))
  );
}
