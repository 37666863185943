import { HeaderBodyLayoutDefine } from 'cheetah-grid/list-grid/layout-map/api';
import { api } from '../../../shared/api_client';
import { numberFormatter } from '../../../shared/dateFormat';
import { sizes } from '../../../shared/sizes';
import { findNextEditCell } from '../../table_extends/allowEditCell';
import { refreshRowAllowSendBudgetUp, refreshStatusRecords } from '../../table_extends/approveRow';
import {
  calcLocalValueRecord,
  refreshFar,
  updateRowGroup,
  updateRowResult,
  updateRowSection,
} from '../../table_extends/calcLocalValueRecord';
import { getResultRows } from '../../table_extends/getResultRows';
import { getTableColumnKey } from '../../table_extends/getTableColumnKey';
import { getWData } from '../../table_extends/getWData';
import { getPassportCache } from '../../table_extends/loadData';
import { syncData } from '../../table_extends/syncData';
import { getTableBody, TableMode } from '../../table_extends/table_body/table_body';
import { tableHeaders } from '../../table_extends/table_header/table_headers';
import { TableRecord } from '../components/TableRecord';
import { openEditor } from './openEditor';
import { DefaultOptionType } from 'rc-select/lib/Select';
import {checkUpdBpPrice, checkUpdBpSize} from "../../table_extends/checkUpdBp";
import {notification} from "antd";
import {updateDates} from "../components/utils/updateDates";

export async function closeInput(
  col: number,
  row: number,
  value?: string | any,
  directionNextCell?: 'up' | 'down' | 'left' | 'right',
  append?: { forceSave?: boolean; singleDate?: boolean },
) {
  console.log(`closeInput ${value} ${directionNextCell} ${getTableColumnKey(col, getTableBody(TableMode.VIEW))}`);
  if (getWData().cellOpen?.onCancelEdit) {
    getWData().cellOpen!.onCancelEdit!(value ?? '');
  }

  getWData().cellOpen!.openEdit = false;
  getWData().textInput = undefined;

  const record = getWData().resultRows[row - tableHeaders.length];
  if (value !== undefined && record.cns_id) {
    const keyColumn = getTableColumnKey(col, getTableBody(TableMode.VIEW));
    const id = record.cns_id;
    const row_type = record.cns_row_type;
    let rows = getWData().rows;

    // Проверка что дата не поменялась или что не нажали кнопку "Ок"
    if (['cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date'].includes(keyColumn) && !append?.forceSave) {
      getWData().setRefresh(Math.random());
      return;
    }

    const isTitleEmpty = value == '' && col == 1 && !record.cns_title;

    switch (row_type) {
      case 'section':
        rows = getWData().rows;
        break;
      case 'work_type':
        rows = getWData().rows2;
        break;
      case 'nomenclature':
        rows = getWData().rows3;
        break;
    }

    const index = rows.findIndex((e) => e.cns_id === id);

    const oldRecordValues: TableRecord = { ...rows[index] };

    if (keyColumn == 'cns_budget_plan_size' || keyColumn == 'cns_budget_plan_price') {
      console.log('1111')
      if (keyColumn == 'cns_budget_plan_size') {
        if (!checkUpdBpSize( getWData().cellOpen?.record.cns_nomenclature_id ?? '', Number.parseFloat(value ?? ''))) {
          notification.error({message: 'Объем должен быть не меньше суммарного, зафиксированного в контрактах'})
          getWData().setRefresh(Math.random());
          return;
        }
      }

      if (keyColumn == 'cns_budget_plan_price') {
        if (!checkUpdBpPrice( getWData().cellOpen?.record.cns_nomenclature_id ?? '', Number.parseFloat(value ?? ''))) {
          notification.error({message: 'Цена должна быть не меньше средней, зафиксированной в контрактах'})
          getWData().setRefresh(Math.random());
          return;
        }
      }

      let val2 = numberFormatter(value);
      val2 = val2.replaceAll(',', '.').replaceAll(' ', '');
      (rows[index][keyColumn as keyof TableRecord] as any) = val2;
    } else if (keyColumn == 'cns_contractors') {
      try {
        (rows[index][keyColumn as keyof TableRecord] as any) = JSON.parse(value);
      } catch (error) {
        (rows[index][keyColumn as keyof TableRecord] as any) = value;
      }
    }  else if (keyColumn == 'cns_responsibles') {
      try {
        (rows[index][keyColumn as keyof TableRecord] as any) = JSON.parse(value).map((e: any) => ({id: e.value,  full_name: e.label}));
      } catch (error) {
        (rows[index][keyColumn as keyof TableRecord] as any) = value;
      }
    } else  {
      (rows[index][keyColumn as keyof TableRecord] as any) = value;
    }

    let calcUpdates = calcLocalValueRecord(record, col, row);
    for (let keyColumn in calcUpdates) {
      updateRowResult(
        keyColumn as any,
        rows[index][keyColumn as keyof TableRecord] as any,
        Number(calcUpdates[keyColumn]),
      );
      (rows[index][keyColumn as keyof TableRecord] as any) = calcUpdates[keyColumn];
    }
    calcUpdates = calcLocalValueRecord(record, col, row);
    for (let keyColumn in calcUpdates) {
      updateRowResult(
        keyColumn as any,
        rows[index][keyColumn as keyof TableRecord] as any,
        Number(calcUpdates[keyColumn]),
      );
      (rows[index][keyColumn as keyof TableRecord] as any) = calcUpdates[keyColumn];
    }
    updateRowGroup(
      record,
      'cns_budget_plan_sum_wat',
      Number(oldRecordValues['cns_budget_plan_sum_wat']),
      Number(rows[index]['cns_budget_plan_sum_wat']),
    );
    updateRowSection(
      record,
      'cns_budget_plan_sum_wat',
      Number(oldRecordValues['cns_budget_plan_sum_wat']),
      Number(rows[index]['cns_budget_plan_sum_wat']),
    );
    if (['cns_budget_plan_size', 'cns_budget_plan_price'].includes(keyColumn)) {
      updateRowResult(
        keyColumn,
        // @ts-ignore
        Number(oldRecordValues[keyColumn]),
        Number(value),
      );
    }

    refreshStatusRecords();
    refreshFar();

    if (row_type == 'nomenclature') {
      // Когда поменялись значения бюджет плана обновить возможность отправить на согласование
      refreshRowAllowSendBudgetUp(record);
    }

    getWData().resultRows = getResultRows();

    getWData().grid!.records = getWData().resultRows;
    getWData().grid!.invalidate();

    if (getWData().cellOpen?.onCancelEdit === undefined) {
      if (
        ['cns_ed_izm', 'cns_budget_mark_sum_wat', 'cns_budget_plan_size', 'cns_budget_plan_price'].includes(keyColumn)
      ) {
        syncData.updateCellValue(record, {
          guideline_amount: undefined,
          plan_price:
            record.cns_row_type == 'nomenclature' && record.cns_budget_plan_price
              ? Number(record.cns_budget_plan_price)
              : undefined,
          plan_volume:
            record.cns_row_type == 'nomenclature' && record.cns_budget_plan_size
              ? Number(record.cns_budget_plan_size)
              : undefined,
          unit: record.cns_row_type == 'nomenclature' && record.cns_ed_izm ? record.cns_ed_izm : undefined,
        });
      }

      if (['cns_plane_date_start', 'cns_plane_date_end', 'cns_contact_date', 'cns_contact_date_start', 'cns_contact_date_end'].includes(keyColumn)) {
        syncData.updateDate(record, keyColumn, { single: append?.singleDate });
      }

      if (
        !isTitleEmpty &&
        keyColumn == 'cns_title' &&
        (record.cns_nomenclature_id ?? record.cns_group_id ?? record.cns_section_id)
      ) {
        syncData.renameRow(
          record.cns_nomenclature_id ?? record.cns_group_id ?? record.cns_section_id ?? '',
          value,
          record.cns_level,
        );
      }

      if (keyColumn == 'cns_contractors' && typeof value === 'string' ) {
        let newVal = [];

        try {
          newVal = JSON.parse(value)?.map((e: DefaultOptionType) => e.value);
        } catch (e) {
          console.log(e);
        }

        await api.passport.projectsSectionsTypesNomenclaturesContractorsCreate(
          getPassportCache().id ?? '',
          record.cns_section_id,
          record.cns_group_id,
          record.cns_nomenclature_id ?? '',
          { contractor_ids: newVal.filter((e: any) => e), send_events: true },
        );
      }

      if (keyColumn == 'cns_responsibles' && typeof value === 'string' ) {
        let newVal = [];

        try {
          newVal = JSON.parse(value)?.map((e: DefaultOptionType) => e.value);
        } catch (e) {
          console.log(e);
        }

        await api.passport.projectsSectionsTypesNomenclaturesContractResponsibleCreate(
          getPassportCache().id ?? '',
          record.cns_section_id,
          record.cns_group_id,
          record.cns_nomenclature_id ?? '',
          { user_ids: newVal.filter((e: any) => e), send_events: true },
        );
      }
    }

    // обновить даты начала и окончания графика локально
    if (['cns_plane_date_start', 'cns_plane_date_end'].includes(keyColumn) && value && append?.forceSave) {
      updateDates( record, keyColumn, value, append?.singleDate );
    }
  }

  if (record.cns_row_type == 'project' && value && append?.forceSave) {
    const keyColumn = getTableColumnKey(col, getTableBody(TableMode.VIEW));
    if (['cns_plane_date_start', 'cns_plane_date_end'].includes(keyColumn)) {
      syncData.updateDate({...record, [keyColumn]: value}, keyColumn, { single: append?.singleDate });
      // обновить даты начала и окончания графика локально
      updateDates( record, keyColumn, value, append?.singleDate );
    }
  }

  getWData().grid.invalidateCell(col, row);

  if (directionNextCell) {
    const body = (getWData().grid.layout as HeaderBodyLayoutDefine<TableRecord>).body;
    const nextCell = findNextEditCell(col, row, getWData().resultRows, body, directionNextCell);

    if (directionNextCell == 'down' && nextCell.row !== -1) {
      const curScroll = getWData().grid!.scrollTop + getWData().grid.getElement().clientHeight;
      const nextCellBottom =
        getWData().grid!.getCellRect(1, nextCell.row)?.bottom + getWData().grid.getCellRect(1, 1).height * 2;
      console.log(curScroll, nextCellBottom);
      if (curScroll < nextCellBottom) {
        getWData().grid!.scrollTop = nextCellBottom - getWData().grid.getElement().clientHeight;
      }
    }

    if (directionNextCell == 'up' && nextCell.row !== -1) {
      const curScroll = getWData().grid!.scrollTop;
      const nextCellBottom =
        getWData().grid!.getCellRect(1, nextCell.row)?.top - getWData().grid.getCellRect(1, 1).height;
      console.log(curScroll, nextCellBottom);
      if (curScroll > nextCellBottom) {
        getWData().grid!.scrollTop = nextCellBottom;
      }
    }

    if (nextCell.row == -1 || nextCell.col == -1) {
      getWData().setRefresh(Math.random());
      return;
    }

    if (nextCell.col !== -1 && nextCell.row !== -1) {
      openEditor(nextCell.col, nextCell.row + tableHeaders.length, getWData().resultRows[nextCell.row]);
    }
  }

  if (window.innerWidth < sizes.mobile) {
    console.log('scroll to 0 0');
    window.scrollTo(0, 0);
  }

  getWData().setRefresh(Math.random());
}
