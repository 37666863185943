import {useQuery, useQueryClient} from 'react-query';
import { api } from '../shared/api_client';
import {getLoadDataInfo, getPassportCache} from "../screens/table_extends/loadData";

export function useContractsStatuses() {
  const queryClient = useQueryClient()

  const {
    data: contractsStatuses,
    isLoading: isLoadingContractsStatuses,
    error: errorContractsStatuses,
  } = useQuery({
    queryKey: [`contracts-statuses`],
    queryFn: async () => {
      const id = getLoadDataInfo()?.id ?? getPassportCache().id ?? '';

      if (!id) {
        return [];
      }

      const res = await api.helpers.contractStatusesList({
        project_id: id,
      });

      return res.data.data ?? []
    },
  });

  function clearCache() {
    queryClient.invalidateQueries([`contracts-statuses`]);
  }

  return {
    contractsStatuses: contractsStatuses ?? [],
    isLoadingContractsStatuses,
    errorContractsStatuses,
    clearCacheContractsStatuses: clearCache,
  };
}
